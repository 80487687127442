import _ from 'lodash';

// Extract timestamp from flake id
export const extractTimestamp = (flake: string): number => {
  // can't use bitwise shift on 64 bit integers
  const binaryStr = parseInt(flake, 10).toString(2);
  return parseInt(binaryStr.substring(0, binaryStr.length - 22), 2);
};

const escapeMap = {
  '>': '&gt;',
  '<': '&lt;',
  "'": '&apos;',
  '"': '&quot;',
  '&': '&amp;',
};

export const unescape = (str: string) => {
  _.each(escapeMap, (val, key) => {
    str = str.replace(new RegExp(val, 'g'), key);
  });

  return str;
};

export const currency = (num: number) => num.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

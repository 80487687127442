import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface Props {
  canAdd: boolean;
  canCopy: boolean;
  canEdit: boolean;
  canDelete: boolean;
  canRestore: boolean;
  itemName: string;
  itemId: string;
  namespace: string;
  navigate: any;
  customButtons?: any;
  rowData?: any;
  onDelete: (itemName: string, itemId: string) => void;
  onRestore: (itemName: string, itemId: string) => void;
}

class ListRowToolbar extends React.Component<Props> {
  static defaultProps = {
    canAdd: false,
    canEdit: false,
    canCopy: false,
    canDelete: false,
    canRestore: false,
    namespace: 'db',
    rowData: null,
    onDelete: () => null,
    onRestore: () => null,
  };

  handleRestoreItem = () => {
    const { itemName, itemId } = this.props;
    this.props.onRestore(itemName, itemId);
  };

  handleRemoveItem = () => {
    const { itemName, itemId } = this.props;
    this.props.onDelete(itemName, itemId);
  };

  nav(route: string) {
    this.props.navigate(route);
  }

  renderAddButton() {
    const { canCopy, namespace, itemName, itemId } = this.props;

    if (!canCopy) {
      return null;
    }

    return (
      <Button
        variant="success"
        title="Copy"
        onClick={(event) => {
          const url = `/${namespace}/${itemName}/${encodeURIComponent(itemId)}/copy`;
          if (event.ctrlKey) {
            window.open(url);
          } else {
            this.nav(url);
          }
        }}
        className="list-copy-button btn-xs"
      >
        <i className="far fa-copy" />
      </Button>
    );
  }

  renderEditButton() {
    const { canEdit, namespace, itemName, itemId } = this.props;

    if (!canEdit) {
      return null;
    }

    return (
      <Button
        variant="primary"
        title="Edit"
        onClick={(event) => {
          const url = `/${namespace}/${itemName}/${encodeURIComponent(itemId)}/edit`;
          if (event.ctrlKey) {
            window.open(url);
          } else {
            this.nav(url);
          }
        }}
        className="list-edit-button btn-xs"
      >
        <i className="fas fa-edit" />
      </Button>
    );
  }

  renderDeleteButton() {
    const { canDelete } = this.props;

    if (!canDelete) {
      return null;
    }

    return (
      <Button onClick={this.handleRemoveItem} variant="danger" title="Delete" className="list-delete-button btn-xs">
        <i className="fas fa-times" />
      </Button>
    );
  }

  renderRestoreButton() {
    const { canRestore } = this.props;

    if (!canRestore) {
      return null;
    }

    return (
      <Button className="list-delete-button btn-xs" title="Restore" variant="info" onClick={this.handleRestoreItem}>
        <i className="fas fa-trash-restore" />
      </Button>
    );
  }

  renderCustomButtons() {
    const { itemId, customButtons, rowData } = this.props;

    if (!customButtons || !customButtons.length) {
      return null;
    }

    return customButtons.map((func: any) => func(itemId, rowData));
  }

  render() {
    return (
      <ButtonGroup className="list-row-toolbar" id={`list-toolbar-${this.props.itemId}`}>
        {this.renderEditButton()}
        {this.renderAddButton()}
        {this.renderRestoreButton()}
        {this.renderDeleteButton()}
        {this.renderCustomButtons()}
      </ButtonGroup>
    );
  }
}

const Wrapper = (props: any) => <ListRowToolbar {...props} navigate={useNavigate()} />;
export default Wrapper;

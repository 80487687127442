interface Props {
  onRemove: (name: string) => void;
  label: string;
  name: string;
}

const Chip = ({ onRemove, name, label }: Props) => {
  return (
    <div className="chip">
      {label}
      <div className="chip-clear" onClick={() => onRemove(name)}>
        <i className="fas fa-times" />
      </div>
    </div>
  );
};

export default Chip;

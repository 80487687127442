import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';

export interface ListAtomType {
  filterText: string;
  resultCount: number;
}

export const ListAtomFamily = atomFamily(() =>
  atom({
    filterText: '',
    resultCount: 0,
  } as ListAtomType)
);

import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
interface Props {
  url: string;
  width: number;
}

const Avatar = (props: Props) => {
  const [useAvatar, setUseAvatar] = useState(true);

  const { url, width } = props;

  return (
    <div className="avatar-container">
      <div className="avatar-image">
        {useAvatar && url ? (
          <Image
            roundedCircle
            src={url}
            onError={() => {
              setUseAvatar(false);
            }}
            alt="Avatar"
            width={width}
          />
        ) : (
          <i className="fas fa-user-circle" />
        )}
      </div>
    </div>
  );
};

export default Avatar;

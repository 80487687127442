import _ from 'lodash';
import { useState } from 'react';
import LoginPanel from '../login/LoginPanel';
import ErrorPage from '../ErrorPage';
import LoadingPage from '../LoadingPage';
import { Invoice } from './payment.types';
import PaymentConfirmation from './PaymentConfirmation';
import WorldNetPayContainer from './WorldNetPayContainer';
import { useQuery } from '@tanstack/react-query';
import CustomerService from '../customers/CustomerService';

const sumFloats = (x: number, y: number) => parseFloat((x + y).toFixed(10));

const getTotalAmount = (invoices: Invoice[] = []) => {
  let totalAmount = 0;
  for (const invoice of invoices) {
    const { amount } = invoice;
    if (Number(amount) < 0) {
      continue;
    }
    totalAmount = sumFloats(totalAmount, Number(amount));
  }

  const totalAmountStr = totalAmount.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return { totalAmount: Number(totalAmount.toFixed(2)), totalAmountStr };
};

const PaymentPage = () => {
  const [showPaymentConfirmation, setShowPaymentConfirmation] = useState(true);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const token = Object.fromEntries(urlSearchParams.entries()).token;

  const { isLoading, error, data } = useQuery({
    queryKey: ['customers/outstanding/token', token],
    queryFn: () => CustomerService.getOutstandingInvoices(token),
  });

  const { tokenInvoices = [], tokenData } = data || {};

  const { totalAmount } = getTotalAmount(tokenInvoices);

  const handlePaymentConfirmation = () => {
    setShowPaymentConfirmation(false);
  };

  const checkError = () => {
    if (!tokenInvoices || !tokenInvoices.length) {
      return 'Invoice(s) not found or already paid.';
    }

    if (error) {
      return _.get(error, 'response.data.error') || 'Something went wrong loading customer data';
    }
  };

  const renderBody = () => {
    if (!token) {
      return <ErrorPage>Invalid Token</ErrorPage>;
    }

    if (isLoading) {
      return <LoadingPage />;
    }

    if (checkError()) {
      return <ErrorPage>{checkError()}</ErrorPage>;
    }

    if (showPaymentConfirmation) {
      return (
        <PaymentConfirmation onConfirm={handlePaymentConfirmation} invoices={tokenInvoices} totalAmount={totalAmount} />
      );
    }

    if (tokenData) {
      return (
        <WorldNetPayContainer
          accountId={Number(tokenData.accountId)}
          amount={totalAmount}
          email={tokenData.email}
          sessionId={tokenData.sessionId}
          clientNum={Number(tokenData.clientNum) || 999}
          invoices={tokenInvoices}
        />
      );
    }
  };

  return (
    <LoginPanel xs={12} sm={12} md={10} lg={10} className="payment-panel">
      {renderBody()}
    </LoginPanel>
  );
};

export default PaymentPage;

import classNames from 'classnames';
import { Col, Row, Card } from 'react-bootstrap';
import ContactForm from './ContactForm';
import { companyAtom } from '../../stores/root.store';
import config from '../../config';
import { useAtom } from 'jotai';
import { Branch } from '../../stores/user.store';

const Contact = () => {
  const [company] = useAtom(companyAtom);

  const renderBranches = () => {
    const branches = company.branches || [];

    return branches.map((branch: Branch) => {
      if (!branch.active) {
        return;
      }

      const addressQuery = [branch.address, branch.city, branch.state, branch.zip].filter((x) => !!x).join('+');

      return (
        <Card key={branch.id} className="mb-2">
          <Card.Body>
            <div className="contact-body">
              {!!addressQuery && (
                <div>
                  <a
                    rel="noopener noreferrer"
                    href={`https://www.google.com/maps/search/${addressQuery.replace(/\s/g, '+')}`}
                    target="_blank"
                  >
                    <img
                      style={{ float: 'right' }}
                      width="200"
                      alt="logo"
                      src={`https://maps.googleapis.com/maps/api/staticmap?center=${addressQuery}&zoom=11&size=200x200&maptype=roadmap&markers=anchor:center&key=${config.google.mapsKey}`}
                    />
                  </a>
                </div>
              )}
              <h4>{branch.name}</h4>
              <div>{branch.address}</div>
              <div>
                {branch.city}
                {branch.city && branch.state && ','}
                {branch.state} {branch.zip} {branch.country}
              </div>
              {branch.phone && <div>Phone: {branch.phone}</div>}
              {branch.fax && <div>Fax: {branch.fax}</div>}
              <div>
                <a href={branch.email}>{branch.email}</a>
              </div>
            </div>
          </Card.Body>
        </Card>
      );
    });
  };

  const className = classNames({
    contact: true,
    'pad-sides': true,
  });

  return (
    <div className={className}>
      <Row>
        <Col md={6} lg={{ span: 5, offset: 1 }}>
          <h4>Contact Us</h4>
          <Card className="form-wrap">
            <Card.Body>
              <ContactForm />
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={5} className="contact-info">
          <h4>Contact Information</h4>
          {renderBranches()}
        </Col>
      </Row>
    </div>
  );
};

export default Contact;

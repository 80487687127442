import { atom } from 'jotai';

export interface Config {
  version: string;
  domain: string;
  env: string;
  hostname: string;
  recaptcha: {
    publicKey: string;
  };
  worldnet: {
    validationUrl: string;
    receiptPageUrl: string;
    receipt: {
      path: string;
    };
  };
  css: Record<string, any>;
}

const defaultState: Config = {
  version: '',
  domain: '',
  env: '',
  hostname: '',
  recaptcha: { publicKey: '' },
  worldnet: {
    validationUrl: '',
    receiptPageUrl: '',
    receipt: {
      path: '',
    },
  },
  css: {},
};

const configAtom = atom<Config>(defaultState);

export default configAtom;

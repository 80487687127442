import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import BlazeForm from 'blaze-form';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { AxiosError } from 'axios';
import LoginPanel from './LoginPanel';
import Api from '../../services/ApiService';
import { LayoutItem } from 'blaze-validator';

const layout = [
  { label: 'New Password', name: 'password', type: 'password', required: true },
  { label: 'Repeat Password', name: 'repeat', type: 'password', required: true },
] as LayoutItem[];

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getToken = () => {
    if (!location.search) {
      return false;
    }

    const hash = new URLSearchParams(location.search);
    if (!hash.has('token')) {
      return false;
    }

    return hash.get('token');
  };

  const isTokenValid = () => {
    const token = getToken();

    if (!token) {
      return false;
    }

    // Check token further
    return true;
  };

  const handleSubmit = (formData: any) => {
    const { password, repeat } = formData;

    if (password !== repeat) {
      toast("Passwords don't match");
      return;
    }

    formData.token = getToken();

    Api.post('reset-password', formData)
      .then(() => {
        navigate('/login');
        toast('Password reset successfully');
      })
      .catch((err: AxiosError) => {
        const errorMsg = _.get(err, 'response.data.error');
        toast(errorMsg || 'Reset Password Failed');
      });
  };

  const handleCancel = () => {
    navigate('/login');
  };

  if (!isTokenValid()) {
    return (
      <LoginPanel>
        <h3>Invalid or expired token</h3>
      </LoginPanel>
    );
  }

  return (
    <LoginPanel>
      <h3>Enter a new password</h3>
      <BlazeForm layout={layout} onSubmit={handleSubmit} hideCancel hideSubmit submitAll>
        <div className="login-controls">
          <Button variant="primary" type="submit" block id="login-btn" data-blaze-submit>
            Change Password
          </Button>
          <Button variant="default" block onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </BlazeForm>
    </LoginPanel>
  );
};

export default ResetPassword;

import { CartItem } from '../components/parts/part.types';
import Api from './ApiService';

interface PartStockCheckParams {
  partNumber: string;
  lineCode: string;
  quantity?: number;
  accountNumber?: string;
  branchId?: string | number;
}

const PartService = {
  stockCheck: async ({
    partNumber,
    lineCode,
    quantity = 1,
    accountNumber,
    branchId,
  }: PartStockCheckParams): Promise<CartItem> => {
    const { data } = await Api.post('parts/stockcheck', {
      parts: [{ partno: partNumber, linecode: lineCode, quan: quantity }],
      accountNumber,
      branchId,
    });

    return { specialComments: [], qtybreak: [], partext: [], special: '', images: [], ...data };
  },
};

export default PartService;

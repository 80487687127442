import DbEdit from '../db/DbEdit';
import { companyAtom } from '../../stores/root.store';
import { useAtom } from 'jotai';

const CurrentCompany = () => {
  const [company] = useAtom(companyAtom);
  return <DbEdit itemName="company" itemId={company.id} hideCancel />;
};

export default CurrentCompany;

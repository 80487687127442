import _ from 'lodash';
import { Helmet } from 'react-helmet';
import { configAtom } from '../stores/root.store';
import { useAtom } from 'jotai';

const Header = () => {
  const [config] = useAtom(configAtom);

  const defaultPrimary = '#2196f3';
  let style = {
    primary: defaultPrimary,
    primaryDark: defaultPrimary,
    fgColor: '#ffffff',
    fgColorDark: '#ffffff',
    isDark: false,
    raw: '',
  };

  try {
    style = _.defaults(config.css, style);
  } catch (err) {
    console.warn(err);
  }

  return (
    <Helmet>
      <style>
        {`
        :root {
          --primary-color: ${style.primary};
          --primary-darker: ${style.primaryDark};
          --fg-color: ${style.fgColor};
          --fg-color-dark: ${style.fgColorDark};
        }
        ${style.raw || ''}
      `}
      </style>
    </Helmet>
  );
};

export default Header;

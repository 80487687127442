import _ from 'lodash';
import * as Sentry from '@sentry/browser';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Routes from './Routes';
import Providers from './Providers';

import './styles';

const queryClient = new QueryClient();

// Sentry Integration
if (import.meta.env.MODE === 'production' && !import.meta.env.VITE_TESTING) {
  Sentry.init({
    dsn: 'https://051cee676d88424e94631ed04e21941b@sentry.io/69662',
    beforeSend(event) {
      const exceptionMessage: string = _.get(event, 'exception.values[0].value', '');

      // don't send these to sentry
      const ignore = ['Network Error', 'Request failed with status code 401'];

      if (ignore.includes(exceptionMessage)) {
        return null;
      }

      return event;
    },
  });
}

// Google Analytics Integration
if (import.meta.env.MODE === 'production' && !import.meta.env.VITE_TESTING) {
  ReactGA.initialize('G-VXB7QDS95L'); // TODO get google analytics setup
}

createRoot(document.getElementById('root')!).render(
  <QueryClientProvider client={queryClient}>
    <Providers>
      <Routes />
    </Providers>
  </QueryClientProvider>
);

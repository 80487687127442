export const graphColors = [
  '#03A9F4',
  '#ffeb3b',
  '#81C784',
  '#9C27B0',
  '#e91e63',
  '#3f51b5',
  '#009688',
  '#cddc39',
  '#795548',
  '#ff9800',
  '#f44336',
];

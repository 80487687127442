import { useState } from 'react';
import { Button, Col, Row, Container, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { modal } from '../modal/ModalEmitter';
import Api from '../../services/ApiService';
import Form from '../Form';
import config from '../../config';
import { useAtom } from 'jotai';
import userAtom from '../../stores/user.store';

const MFAView = () => {
  const [user, setUserState] = useAtom(userAtom);

  const mfaSecret = user.mfaSecret;
  const mfaRequired = false;

  const [hasMfa, setHasMfa] = useState(!!mfaSecret);
  const [qrcodeDataUrl, setQrcodeDataUrl] = useState('');
  const [secret, setSecret] = useState('');

  const enableMFA = () => {
    Api.post('auth/mfa/enable').then(({ data }) => {
      setHasMfa(true);
      setQrcodeDataUrl(data.qrcodeDataUrl);
      setSecret(data.secret);
    });
  };

  const disableMFA = () => {
    modal.confirm({
      title: 'Disable Multi-Factor Authentication',
      body: 'Removing multi-factor authentication can make your account less secure.',
      headerClass: 'primary',
      onOk: () => {
        Api.post('auth/mfa/disable').then(() => {
          setUserState({ ...user, mfaSecret: '' });
          setHasMfa(false);
          setQrcodeDataUrl('');
        });
      },
    });
  };

  const verifyMFA = ({ token }: { token: string }) => {
    Api.post('auth/mfa/verify', { secret, token })
      .then(() => {
        setHasMfa(true);
        setQrcodeDataUrl('');
        setUserState({ ...user, mfaSecret: secret });
      })
      .catch((err) => {
        toast.error('Error', err.message);
      });
  };

  const renderQrCode = () => {
    const layout = [
      {
        name: 'token',
        label: 'Verification Code',
      },
    ];

    return (
      <Container fluid>
        <Row>
          <Col md={5} sm={6} className="text-center">
            <div className="form-paragraph">
              After installing the <strong>Google Authenticator</strong> app scan the QR code below and enter the
              verification code.
            </div>
            <div>
              <img
                src={config.publicUrl('img/google-auth-logo.png')}
                alt="Google Authenticator"
                style={{ height: '35px', margin: '10px' }}
              />

              <a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={config.publicUrl('img/google-play-badge.png')}
                  alt="Google Play"
                  style={{ height: '35px', margin: '10px' }}
                />
              </a>
              <a
                href="https://itunes.apple.com/us/app/google-authenticator/id388497605"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={config.publicUrl('img/itunes-badge.svg')}
                  alt="iTunes"
                  style={{ height: '35px', margin: '10px' }}
                />
              </a>
            </div>
            <div className="form-paragraph">
              Secret Key: <small>{secret}</small>
            </div>
            <img src={qrcodeDataUrl} alt="QRCode" id="qr-code" />
          </Col>
          <Col md={2} sm={6} xs={12}>
            <Form hideCancel layout={layout} onSubmit={verifyMFA} />
          </Col>
        </Row>
      </Container>
    );
  };

  const renderEnableMfa = () => {
    return (
      <div>
        <div className="form-paragraph">
          Each time you sign in to your account, you will need your password and a verification code.
        </div>
        <Button onClick={enableMFA} variant="success" size="lg">
          Enable MFA
        </Button>
      </div>
    );
  };

  const renderDisableMfa = () => {
    return (
      <div>
        <div className="form-paragraph">
          Multi-Factor Authentication is setup and enabled. To disable MFA, click below.
        </div>
        <Button onClick={disableMFA} variant="danger" size="lg">
          Disable MFA
        </Button>
      </div>
    );
  };

  if (qrcodeDataUrl) {
    return renderQrCode();
  }

  return (
    <div>
      {mfaRequired && (
        <Alert variant="warning">
          <strong>Multi-factor authentication is required for your account</strong>
        </Alert>
      )}
      {hasMfa ? renderDisableMfa() : renderEnableMfa()}
    </div>
  );
};

export default MFAView;

import config from '../config';

const FileService = {
  downloadPath(fileName: string, namespace = 'working') {
    return `${config.serverUrl}/api/download/${namespace}/${fileName}`;
  },

  downloadDataUri(uri: string, name: string) {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },
};

export default FileService;

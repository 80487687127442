import DbService from '../../services/DbService';
import DbList from '../db/DbList';

const Orders = () => {
  const filter = [{ label: 'Part Search', name: 'parts', helpText: 'Search by part number or keyword' }];

  const handleFilter = ({ parts }: Record<string, any>) => {
    return DbService.getAll('order', { parts }).then((response) => response.data);
  };

  return (
    <DbList itemName="order" filter={filter} onFilter={handleFilter}>
      <div className="pad-sides muted pad-top">This page only displays orders placed from this website.</div>
    </DbList>
  );
};

export default Orders;

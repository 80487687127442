import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import Form from '../Form';
import Api from '../../services/ApiService';
import userAtom from '../../stores/user.store';

const layout = [
  { name: 'password', type: 'password', label: 'Password', helpText: 'Enter a new password.', required: true },
  {
    name: 'password2',
    type: 'password',
    label: 'Repeat Password',
    helpText: 'Repeat the new password above.',
    required: true,
  },
];

const ChangePassword = () => {
  const [user] = useAtom(userAtom);
  const navigate = useNavigate();

  const handleSubmit = ({ password, password2 }: { password: string; password2: string }) => {
    if (password !== password2) {
      toast('Passwords do not match');
      return;
    }

    Api.put(`users/${user.id}`, { password })
      .then(() => {
        toast('Password change successful');
        navigate(-1);
      })
      .catch((err) => {
        toast(err.message);
      });
  };

  return <Form layout={layout} onSubmit={handleSubmit} hideCancel submitAll />;
};

export default ChangePassword;

import classnames from 'classnames';

interface Props extends React.HTMLProps<HTMLDivElement> {
  label: string;
  children: any;
  inline?: boolean;
  style?: any;
  className?: string;
}

const DisplayValue = ({ label, children, inline = false, style, className = '', ...rest }: Props) => {
  const classes = classnames({
    [className]: !!className,
    'display-value': true,
    inline,
  });

  return (
    <div className={classes} style={style} {...rest}>
      <label>{label}</label>
      <div className="value">{children}</div>
    </div>
  );
};

export default DisplayValue;

import { Card } from 'react-bootstrap';

interface Props {
  title: string;
  children: any;
}

const Widget = ({ title, children }: Props) => {
  return (
    <Card className="widget" style={{ margin: '3px' }}>
      <Card.Header>{title}</Card.Header>
      <Card.Body>{children}</Card.Body>
    </Card>
  );
};

export default Widget;

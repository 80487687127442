import { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import configAtom from '../../stores/config.store';
import formPost from './FormPost';
import WorldNetService from './WorldNetService';
import CustomIframe from './CustomIframe';
import { WorldNetTokenQueryParams } from './payment.types';
import { useAtom } from 'jotai';

/**
 * Test card 4444333322221111  cvv 123
 */

interface WorldNetTokenResponse {
  terminalId: string;
  merchantRef: string;
  response: WorldNetTokenQueryParams;
}

interface Props {
  action?: 'register' | 'update';
  accountId: number;
  clientNum: number;
  customerNum: string;
  email?: string;
  onResponse: (params: WorldNetTokenResponse) => void;
}

const WorldNetTokenContainer = ({
  action = 'register',
  accountId,
  clientNum,
  customerNum = '',
  email = '',
  onResponse,
}: Props) => {
  const [config] = useAtom(configAtom);
  const [hash, setHash] = useState('');
  const [terminalId, setTerminalId] = useState('');
  const [pageUrl, setPageUrl] = useState('');
  const [merchantRef, setMerchantRef] = useState('');

  const dateRef = useRef(new Date());
  const dateTime = dayjs(dateRef.current).format('DD-MM-YYYY:HH:mm:ss:SSS');

  // hack to handle message event data
  const dataRef = useRef<{ terminalId: string; merchantRef: string }>();

  useEffect(() => {
    const messageHandler = (event: { data: { source: string; payload: any } }) => {
      if (event.data && event.data.source === 'WorldNetTokenResponse') {
        onResponse({
          terminalId: dataRef.current?.terminalId || '',
          merchantRef: dataRef.current?.merchantRef || '',
          response: event.data.payload,
        });
      }
    };

    window.addEventListener('message', messageHandler);

    WorldNetService.getTokenHash({
      action,
      accountId,
      customerNum,
      clientNum,
      dateTime,
    }).then((response) => {
      setHash(response.hash);
      setTerminalId(response.terminalId);
      setPageUrl(response.pageUrl);
      setMerchantRef(response.merchantRef);

      dataRef.current = {
        merchantRef: response.merchantRef,
        terminalId: response.terminalId,
      };
    });

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, []);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hash && pageUrl && terminalId && merchantRef) {
      formPost({
        path: `${pageUrl}/merchant/securecardpage`,
        params: { action, terminalId, merchantRef, dateTime, hash, email },
        uppercaseKeys: true,
        containerRef,
      });
    }
  }, [hash, pageUrl, terminalId, merchantRef]);

  return (
    <>
      {config.env === 'development' && <div style={{ position: 'absolute' }}>DEV: Test Card: 4444333322221111</div>}
      <CustomIframe style={{ border: 0, background: 'transparent', width: '100%', height: '100%' }}>
        <div ref={containerRef} />
      </CustomIframe>
    </>
  );
};

export default WorldNetTokenContainer;

import { useNavigate } from 'react-router-dom';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useAtom } from 'jotai';
import Menu from './Menu';
import AuthService from '../services/AuthService';
import Avatar from './user/Avatar';
import { usePartsStore } from './parts/part.store';
import { useCustomerPermissions } from './customers/CustomerHooks';
import ChangeCustomer from './ChangeCustomer';
import { modal } from './modal/ModalEmitter';
import MainSearch from './MainSearch';
import userAtom from '../stores/user.store';
import companyAtom from '../stores/company.store';

const MainNavbar = () => {
  const [user] = useAtom(userAtom);
  const [company] = useAtom(companyAtom);
  const { cart } = usePartsStore();
  const navigate = useNavigate();

  const hasPermission = useCustomerPermissions();

  const handleChangeCustomer = () => {
    const body = <ChangeCustomer customers={user.customers || []} active={user.customer?.id || 0} />;

    modal.open({
      title: 'Change Customer',
      body,
    });
  };

  const renderSwitchCustomerItem = () => {
    if (!user.isCustomer || !user.customers || user.customers.length < 2) {
      return null;
    }

    return (
      <NavDropdown.Item eventKey={3.0} onClick={handleChangeCustomer} id="switch-account">
        Switch Account
      </NavDropdown.Item>
    );
  };

  const renderBrand = () => {
    const logo = <span className="logo-placeholder">{company.name.charAt(0)}</span>;
    const goHome = () => navigate('/');

    return (
      <Navbar.Brand onClick={goHome} style={{ cursor: 'pointer' }}>
        {logo}
        <div className="branch-name">{company.name}</div>
      </Navbar.Brand>
    );
  };

  const renderCartStatus = () => {
    if (!hasPermission('part.order')) {
      return null;
    }

    if (!cart) {
      return null;
    }

    return (
      <Nav.Link id="cart-nav" className="nav-icon top-level-menu" onClick={() => navigate('/cart')}>
        <div className="nav-cart navbar-item-title">
          <i className="fas fa-shopping-cart fa-2x" />
          <div className="count">{cart.length}</div>
        </div>
      </Nav.Link>
    );
  };

  const renderLoggedInUser = () => {
    if (!user || user.id === undefined) {
      return <Nav.Link onClick={() => navigate('/login')}>Login</Nav.Link>;
    }

    const avatar = (
      <div className="user-badge">
        <Avatar url={user.avatar} width={35} />
        <span className="username d-none d-lg-inline">
          {user.firstName} {user.lastName}
        </span>
      </div>
    );

    return (
      <NavDropdown id="user-menu-dropdown" title={avatar} className="no-caret" alignRight>
        {renderSwitchCustomerItem()}
        <NavDropdown.Item eventKey={3.1} onClick={() => navigate('/account')} id="user-menu-account">
          Account
        </NavDropdown.Item>
        <NavDropdown.Item eventKey={3.2} onClick={() => AuthService.logout()} id="user-menu-logout">
          Logout
        </NavDropdown.Item>
      </NavDropdown>
    );
  };

  return (
    <Navbar bg="dark" variant="dark" className={company.hideMainSearch ? 'main-search-hidden' : ''}>
      <div className="navbar-left">
        <Nav className="navbar-header">
          <Menu />
          {renderBrand()}
        </Nav>
        {!company.hideMainSearch && <Nav className="navbar-form">{hasPermission('part.search') && <MainSearch />}</Nav>}
      </div>
      <Nav className="nav-toolbar" activeKey="">
        {renderCartStatus()}
        {renderLoggedInUser()}
      </Nav>
    </Navbar>
  );
};

export default MainNavbar;

interface Props {
  path: string;
  params: Record<string, any>;
  method?: 'post' | 'get';
  uppercaseKeys?: boolean;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
}

const formPost = ({ path, params, method = 'post', uppercaseKeys = false, containerRef }: Props) => {
  if (!containerRef.current) {
    return;
  }

  const form = document.createElement('form');
  form.method = method;
  form.action = path;

  for (let [key, val] of Object.entries(params)) {
    if (uppercaseKeys) {
      key = key.toUpperCase();
    }
    if (!val) {
      continue;
    }
    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = key;
    hiddenField.value = val as any;
    form.appendChild(hiddenField);
  }

  containerRef.current.appendChild(form);
  form.submit();
};

export default formPost;

import React from 'react';
import '../styles/pagenavbar.scss';

interface Props {
  children?: React.ReactNode;
  header: React.ReactNode | string;
}

class PageNavbar extends React.Component<Props> {
  renderHeader() {
    const { header } = this.props;

    if (!header) {
      return null;
    }

    return <div className="title">{header}</div>;
  }

  render() {
    return (
      <div className="page-navbar">
        {this.renderHeader()}
        {this.props.children}
      </div>
    );
  }
}

export default PageNavbar;

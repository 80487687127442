import _ from 'lodash';
import { Button } from 'react-bootstrap';
import BlazeForm from 'blaze-form';
import Api from '../../services/ApiService';
import { Cart } from './part.types';
import { useQuery } from '@tanstack/react-query';

interface Props {
  onSubmit: (cart: any) => void;
  onCancel: () => void;
}

const CartLoadForm = ({ onSubmit, onCancel }: Props) => {
  const {
    isLoading,
    error,
    data: carts = [],
  } = useQuery<Cart[]>({
    queryKey: ['carts'],
    queryFn: async () => {
      return (await Api.get('carts')).data;
    },
  });

  if (isLoading) {
    return null;
  }

  if (error) {
    return <div>Error loading carts</div>;
  }

  if (!carts.length) {
    return (
      <div className="empty-saved">
        <div>There are no saved carts</div>
        <Button onClick={onCancel}>Close</Button>
      </div>
    );
  }

  const handleSubmit = ({ cartId }: { cartId: number }) => {
    if (!cartId) {
      return onCancel();
    }

    cartId = Number(cartId);

    for (const cart of carts) {
      if (cart.id === cartId) {
        if (_.isString(cart.data)) {
          cart.data = JSON.parse(cart.data);
        }
        return onSubmit(cart);
      }
    }

    return onCancel();
  };

  const options = carts.map((cart) => {
    return { label: cart.name, value: cart.id };
  });

  const layout = [{ label: 'Cart', name: 'cartId', control: 'select', required: true, options }];

  return <BlazeForm layout={layout} onSubmit={handleSubmit} onCancel={onCancel} submitAll />;
};

export default CartLoadForm;

import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import Mousetrap from 'mousetrap';
import { Form, InputGroup } from 'react-bootstrap';
import { useDebounce } from 'use-debounce';
import BarcodeButton from './BarcodeButton';

const MainSearch = () => {
  const searchRef = useRef<HTMLInputElement>(null);
  const [text, setText] = useState('');
  const [focused, setFocused] = useState(false);
  const [value] = useDebounce(text, 500);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const setFocus = () => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
    return false;
  };

  useEffect(() => {
    Mousetrap.bind(['/'], setFocus);

    return () => {
      Mousetrap.unbind(['/']);
    };
  }, []);

  const handleNavigate = () => {
    if (searchParams.has('num')) {
      // make sure num is not present because it will mess up the search
      searchParams.delete('num');
    }

    navigate({
      pathname: '/parts',
      search: createSearchParams({
        ...Object.fromEntries(searchParams),
        q: value,
      }).toString(),
    });
  };

  useEffect(() => {
    // avoid navigate on mount, check if focused or value is set
    if (focused || value) {
      handleNavigate();
    }
  }, [value]);

  const handleScan = (scannedValue: string) => setText(scannedValue);
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setText(e.target.value);

  const wrapperClass = classNames('main-search', {
    'search-focused': focused,
  });

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleNavigate();
    }
  };

  return (
    <div className={wrapperClass}>
      <div className="search-icon">
        <i className="fas fa-search" />
      </div>
      <div className="search-input">
        <InputGroup>
          <Form.Control
            ref={searchRef}
            id="main-search"
            placeholder="Search"
            type="text"
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            // value={value}
          />
          <InputGroup.Append>
            <BarcodeButton onScan={handleScan} />
          </InputGroup.Append>
        </InputGroup>
      </div>
    </div>
  );
};

export default MainSearch;

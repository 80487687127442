import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ButtonGroup, Button } from 'react-bootstrap';
import LoadingPage from './LoadingPage';
import Api from '../services/ApiService';
import ListView from './list/ListView';
import { modal } from './modal/ModalEmitter';
import { usePartsStore } from './parts/part.store';
import PartCardModal from './parts/PartCardModal';
import ListViewToolbar from './list/ListViewToolbar';
import PartItemIcon from './parts/PartItemIcon';
import ErrorPage from './ErrorPage';
import LazyLoad, { forceCheck } from 'react-lazyload';
import { ICellRendererParams, RowNode } from '@ag-grid-community/core';
import { PartPromotion } from './parts/part.types';

const columnDefs = [
  {
    pinned: 'left',
    headerName: '',
    field: 'action',
    suppressSizeToFit: true,
    suppressMenu: true,
    sortable: false,
    cellClass: 'ag-col-center',
    width: 90,
    cellRenderer: (cellProps: any) => {
      return (
        <ButtonGroup className="list-row-toolbar">
          <Button
            size="sm"
            onClick={(event: any) => {
              // row click handler does this
              // handleRowClicked(cellProps);
              return;
            }}
            title="Add To Cart"
          >
            <i className="fas fa-cart-plus" />
          </Button>
        </ButtonGroup>
      );
    },
  },
  {
    headerName: 'Part Description',
    field: 'partDesc',
    cellRenderer: (cellProps: ICellRendererParams) => {
      const { partNum, partDesc, upc, images } = cellProps.data;

      const partText = (
        <div className="part-text">
          <div>{partDesc}</div>
          {!!upc && <div className="upc">UPC: {upc}</div>}
        </div>
      );

      if (images) {
        return (
          <>
            <div className="part-image-wrapper">
              <LazyLoad scrollContainer=".ag-body-viewport" once debounce>
                <PartItemIcon
                  style={{ maxWidth: '70px', maxHeight: '70px' }}
                  images={images}
                  partNumber={partNum}
                  description={partDesc}
                  openGallery={false}
                />
              </LazyLoad>
            </div>

            {partText}
          </>
        );
      }

      return partText;
    },
  },
  { headerName: 'Part Number', field: 'partNum' },
  { headerName: 'Category', field: 'category', sort: 'asc' },
  { headerName: 'Price', field: 'price', type: 'currency' },
  { headerName: 'End Date', field: 'endDate' },
];

const Promotions = () => {
  const [promotions, setPromotions] = useState<PartPromotion[]>();

  const { resetActivePart } = usePartsStore();

  useEffect(() => {
    Api.get('parts/specials')
      .then((res) => {
        setPromotions(
          res.data.map((row: PartPromotion) => {
            row.onHand = Number(row.onHand || 0);
            row.partNum = row.partNum.substring(row.partNum.indexOf(':') + 1);
            return row;
          })
        );
      })
      .catch((err) => {
        console.error(err);
        setPromotions([]);
      });
  }, []);

  const handleRowClicked = (rowNode: RowNode) => {
    const { partNum } = rowNode.data;

    const handleAddToCart = () => {
      modal.close();
      toast('Part Added To Cart');
    };

    modal.open({
      title: `Part Detail (${partNum})`,
      headerClass: 'bg-primary',
      hideFooter: true,
      onClose: () => resetActivePart(),
      onCancel: () => resetActivePart(),
      body: <PartCardModal partNumber={partNum} lineCode={''} images={[]} onAddToCart={handleAddToCart} />,
    });
  };

  const handleUpdated = () => {
    setTimeout(forceCheck, 100);
  };

  if (!promotions) {
    return <LoadingPage />;
  }

  const renderList = () => {
    if (promotions && !promotions.length) {
      return (
        <ErrorPage>
          <>
            No Promotions
            <br />
            Check back later.
          </>
        </ErrorPage>
      );
    }

    return (
      <ListView
        canAdd={false}
        canDelete={false}
        canEdit={false}
        gridOptions={{ rowHeight: 75 }}
        columnDefs={columnDefs}
        onUpdated={handleUpdated}
        rowData={promotions}
        onRowClicked={handleRowClicked}
      >
        <ListViewToolbar canExport header="Promotions" />
      </ListView>
    );
  };

  return <div id="promotions">{renderList()}</div>;
};

export default Promotions;

import React, { FormEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';

import '../styles/materialsearch.scss';

interface Props {
  onBlur?: () => void;
  onChange?: (event: any) => void;
  onFocus?: () => void;
  onSubmit?: (event: FormEvent) => void;
  loading?: boolean;
  maxResults?: number;
  placeholder?: string;
  resultCount?: number;
  showResultCount?: boolean;
  style?: object;
  value?: string;
}

const MaterialSearch = ({
  onBlur = () => null,
  onChange = () => null,
  onFocus = () => null,
  onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
  },
  loading = false,
  maxResults = 10000,
  placeholder = 'Search',
  resultCount = 0,
  showResultCount = true,
  style = {},
  value = '',
}: Props) => {
  const [searchText, setSearchText] = useState(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setSearchText(val);
    onChange(val);
  };

  const buildResultText = () => {
    if (resultCount === 1) {
      return `${resultCount} result`;
    }

    if (resultCount === maxResults) {
      // TODO: Temporary until there is a better fix for large lists
      return `${resultCount}+ results`;
    }

    return `${resultCount} results`;
  };

  return (
    <div className="material-search-wrapper" style={style}>
      <div className="search-icon">
        {loading ? <ClipLoader size={15} color="#2196F3" /> : <i className="fas fa-search" />}
      </div>
      <div className="search-input">
        <form onSubmit={onSubmit}>
          <Form.Control
            placeholder={placeholder}
            type="Text"
            value={searchText}
            onChange={handleChange}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        </form>
      </div>
      {showResultCount && <div className="search-results">{buildResultText()}</div>}
    </div>
  );
};

export default MaterialSearch;

import { useState, useEffect } from 'react';
import { companyAtom } from '../../stores/root.store';
import { Button } from 'react-bootstrap';
import { useRecaptcha } from '../form/form.hooks';
import BlazeForm from 'blaze-form';
import Api from '../../services/ApiService';
import { toast } from 'react-toastify';
import { useAtom } from 'jotai';
import { LayoutItem } from 'blaze-validator';

interface Props {
  singleColumn?: boolean;
}

const getLayout = (branches: any[], singleColumn = false): LayoutItem[] => [
  {
    name: 'email',
    type: 'email',
    label: 'Your Email',
    required: true,
    col: singleColumn ? { sm: 12, md: 12, lg: 12 } : { sm: 6, md: 6, lg: 6 },
  },
  {
    name: 'name',
    label: 'Your Name',
    required: true,
    col: singleColumn ? { sm: 12, md: 12, lg: 12 } : { sm: 6, md: 6, lg: 6 },
  },
  { name: 'company', label: 'Your Company', required: true },
  {
    name: 'BranchId',
    label: 'Select Branch',
    options: branches.map((b: any) => {
      return { label: b.name, value: b.id };
    }),
    control: 'select',
    required: true,
    col: singleColumn ? { sm: 12, md: 12, lg: 12 } : { sm: 6, md: 6, lg: 6 },
  },
  {
    name: 'customerNum',
    col: singleColumn ? { sm: 12, md: 12, lg: 12 } : { sm: 6, md: 6, lg: 6 },
    label: 'Your Customer Number',
    helpText: "Leave blank if you don't know your customer number",
  },
  {
    name: 'message',
    control: 'textarea',
    label: 'Your Message',
    helpText: 'Need an account? Have a specific question? Enter it here.',
  },
];

const ContactForm = ({ singleColumn = false }: Props) => {
  const [company] = useAtom(companyAtom);

  const [layout, setLayout] = useState<LayoutItem[]>(getLayout(company.branches, singleColumn));
  const [sending, setSending] = useState(false);
  const [captcha, Recaptcha] = useRecaptcha();

  useEffect(() => {
    setLayout(getLayout(company.branches, singleColumn));
  }, [company.branches]);

  const handleSubmit = (data: any) => {
    setSending(true);
    return Api.post('contact', { ...data, captcha }).then(() => {
      toast('Your message has been sent to us.');
      setSending(false);
    });
  };

  return (
    <BlazeForm submitLabel="Send" layout={layout} onSubmit={handleSubmit} hideToolbar resetOnSubmit submitAll>
      <>
        {Recaptcha}
        <div className="controls">
          <Button variant="primary" type="button" data-blaze-submit disabled={!captcha || sending}>
            {sending ? 'Sending...' : 'Send'}
          </Button>
        </div>
      </>
    </BlazeForm>
  );
};

export default ContactForm;

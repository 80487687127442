import _ from 'lodash';
import { useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { companyAtom, userAtom } from '../../stores/root.store';
import { usePartsStore } from './part.store';
import Api from '../../services/ApiService';
import { currency } from '../../util';
import ReactSelect from 'react-select';
import PartService from '../../services/PartService';
import { modal } from '../modal/ModalEmitter';
import { CartItem } from './part.types';

const CartSummary = () => {
  const {
    cart,
    comment,
    deliveryMethod,
    getItemSubtotal,
    getOrderTotal,
    getShippingTier,
    getTotalItems,
    getUnitPrice,
    poNumber,
    resetCart,
    setCart,
    setState,
    shippingAccount,
    shippingAccountBranchNum,
    submittingOrder,
  } = usePartsStore();

  const [company] = useAtom(companyAtom);
  const [user] = useAtom(userAtom);

  const navigate = useNavigate();

  const userHasShippingAccounts = () => {
    return !!(user.customer?.shippingAccounts && user.customer.shippingAccounts.length);
  };

  useEffect(() => {
    if (!userHasShippingAccounts() || shippingAccount) {
      return;
    }

    if (user.customer) {
      // set default shipping account to first in the list
      const firstAccount = user.customer.shippingAccounts[0];
      setState({ shippingAccount: firstAccount.number, shippingAccountBranchNum: firstAccount.branchId });
    }
  }, []);

  const orderRequest = (parts: CartItem[]) => {
    // in the case user does not change the shipping method
    const defaultDeliveryMethod = _.get(company, 'shippingMethods[0].code');

    return Api.post('parts/order', {
      parts,
      comment,
      ponumber: poNumber,
      delmethod: deliveryMethod || defaultDeliveryMethod,
      shippingAccount: isPickUp() ? '' : shippingAccount,
      shippingAccountBranchNum: isPickUp() ? '' : shippingAccountBranchNum,
    })
      .then(({ data }) => {
        resetCart();
        toast.info('Order Submitted');
        navigate(`/orders/${data.id}`);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setState({ submittingOrder: false });
      });
  };

  const submitOrder = async () => {
    if (submittingOrder) {
      return;
    }

    if (user.customer && user.customer.poRequired && !poNumber) {
      toast.error('PO Number is required');
      return;
    }

    if (userHasShippingAccounts() && !shippingAccount) {
      toast.error('Shipping Account is required');
      return;
    }

    setState({ submittingOrder: true });

    const parts = cart.map((part: CartItem) => {
      return { ...part, unitPrice: getUnitPrice(part, part.quan) };
    });

    const priceChanges: { part: CartItem; oldUnitPrice: number; newUnitPrice: number }[] = [];
    const newParts: CartItem[] = [];

    for (let part of cart) {
      const data = await PartService.stockCheck({ partNumber: part.partno, lineCode: part.linecode, quantity: 1 });
      const oldUnitPrice = getUnitPrice(part, part.quan);
      const newUnitPrice = getUnitPrice(data, part.quan);

      newParts.push({
        ...data,
        unitPrice: getUnitPrice(data, part.quan),
        quan: part.quan,
      });

      if (oldUnitPrice !== newUnitPrice) {
        // price has changed since adding item to cart,
        priceChanges.push({ part, oldUnitPrice, newUnitPrice });
      }
    }

    if (priceChanges.length) {
      setCart(newParts);
      // launch modal for user confirmation\
      modal.confirm({
        title: 'Price Change Confirmation',
        headerClass: 'warning',
        modalProps: {
          size: 'md',
          className: 'center',
        },
        body: (
          <>
            <div>The price of some items have changes since they were added to cart.</div>
            {priceChanges.map(({ part, oldUnitPrice, newUnitPrice }) => {
              return (
                <div className="mt-2">
                  <b>
                    {part.partno} {part.desc}
                  </b>{' '}
                  changed from <b>${oldUnitPrice}</b> each to <b>${newUnitPrice}</b> each
                </div>
              );
            })}
          </>
        ),
        onOk: () => orderRequest(newParts),
      });
    } else {
      return orderRequest(parts);
    }
  };

  const isPickUp = () => company.config.orders?.pickupCode === deliveryMethod;

  const renderShipping = () => {
    const shippingTier = getShippingTier();

    if (!shippingTier) {
      return null;
    }

    return (
      <>
        <li>
          <span>Shipping</span>
          <span id="cart-handling">${currency(shippingTier.shipping)}</span>
        </li>
      </>
    );
  };

  const renderHandling = () => {
    const shippingTier = getShippingTier();

    if (!shippingTier) {
      return null;
    }

    return (
      <>
        <li>
          <span>Handling</span>
          <span id="cart-handling">${currency(shippingTier.handling)}</span>
        </li>
      </>
    );
  };

  const renderItemSubTotal = () => {
    const shippingTier = getShippingTier();

    if (!shippingTier) {
      return;
    }

    return (
      <li>
        <span>Item Subtotal</span>
        <span id="cart-subtotal-amount">${getItemSubtotal({ localeString: true })}</span>
      </li>
    );
  };

  const renderShippingAccountSelect = () => {
    if (!userHasShippingAccounts() || isPickUp()) {
      return;
    }

    const shippingAccountOptions = [
      ...(user.customer?.shippingAccounts || []).map((shippingAccount) => {
        return {
          label: (
            <div>
              <div style={{ fontWeight: 'bold' }}>
                {shippingAccount.name} ({shippingAccount.number})
              </div>
              <div style={{ fontSize: '10px' }}>{shippingAccount.address1}</div>
              <div style={{ fontSize: '10px' }}>{shippingAccount.address2}</div>
              <div style={{ fontSize: '10px' }}>
                {shippingAccount.city} {shippingAccount.state} {shippingAccount.zip}
              </div>
            </div>
          ),
          value: shippingAccount.number,
        };
      }),
    ];

    return (
      <Form.Group>
        <Form.Label>Shipping To Location</Form.Label>
        <ReactSelect
          required
          onChange={({ value }: any) => {
            let shippingAccountBranchNum = '';
            for (const account of user.customer?.shippingAccounts || []) {
              if (account.number === value) {
                shippingAccountBranchNum = account.branchId;
              }
            }
            setState({ shippingAccount: value, shippingAccountBranchNum });
          }}
          value={shippingAccountOptions.find((option) => option.value === shippingAccount)}
          options={shippingAccountOptions}
        />
      </Form.Group>
    );
  };

  return (
    <div className="part-summary">
      <Form.Group>
        <Form.Label>Delivery Method</Form.Label>
        <Form.Control as="select" onChange={(e: any) => setState({ deliveryMethod: e.target.value })}>
          {company.shippingMethods.map((method) => {
            return (
              <option key={method.code} value={method.code}>
                {method.label}
              </option>
            );
          })}
        </Form.Control>
      </Form.Group>
      {renderShippingAccountSelect()}
      <Form.Group>
        <Form.Label>PO#</Form.Label>
        <Form.Control
          type="text"
          name="poNumber"
          value={poNumber}
          required={!!user.customer?.poRequired}
          onChange={(e: any) => setState({ poNumber: e.target.value })}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Order Comments</Form.Label>
        <Form.Control
          as="textarea"
          name="comment"
          value={comment}
          onChange={(e: any) => setState({ comment: e.target.value })}
        />
      </Form.Group>
      <ul className="leaders">
        <li>
          <span>Items</span>
          <span id="cart-total-count">{getTotalItems()}</span>
        </li>
        {renderItemSubTotal()}
        {renderShipping()}
        {renderHandling()}
        <li className="text-bold">
          <span>Total</span>
          <span id="cart-total-amount">${getOrderTotal({ localeString: true })}</span>
        </li>
      </ul>
      <div style={{ textAlign: 'center' }}>
        <strong>Price excludes shipping and tax.</strong>
      </div>
      <div className="text-center">
        <Button className="submit-order" onClick={submitOrder} disabled={submittingOrder}>
          Submit Order
        </Button>
      </div>
    </div>
  );
};

export default CartSummary;

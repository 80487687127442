import { useState } from 'react';
import { slide as BurgerMenu, State } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import ChangeCustomer from './ChangeCustomer';
import { companyAtom } from '../stores/root.store';
import { getUserPages } from '../services/NavService';
import AuthService from '../services/AuthService';
import { modal } from './modal/ModalEmitter';
import { useAtom } from 'jotai';
import userAtom, { Customer } from '../stores/user.store';

const Menu = () => {
  const [user] = useAtom(userAtom);
  const [company] = useAtom(companyAtom);
  const [isOpen, setIsOpen] = useState(false);

  const canChangeCustomer = user.isCustomer && user.customers && user.customers.length >= 2;

  const handleStateChange = (state: State) => {
    if (state.isOpen !== isOpen) {
      setIsOpen(state.isOpen);
    }
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const changeCustomer = () => {
    if (!canChangeCustomer || !user.customer?.id) {
      return null;
    }

    const body = <ChangeCustomer customers={user.customers as Customer[]} active={user.customer.id} />;

    modal.open({
      title: 'Change Customer',
      body,
    });
  };

  const renderUserBadge = () => {
    return (
      <div className="user-info">
        <table className="user-badge">
          <tbody>
            <tr>
              <td rowSpan={2} style={{ textAlign: 'center' }} className="user-avatar">
                <img alt="" title={`${user.firstName} ${user.lastName}`} src={user.avatar} />
              </td>
              <td colSpan={2}>
                <div className="user-name">
                  {user.firstName} {user.lastName}
                </div>
              </td>
            </tr>
            <tr>
              {canChangeCustomer && (
                <td>
                  <div className="user-customer" onClick={changeCustomer}>
                    Change Customer
                  </div>
                </td>
              )}
              <td style={{ textAlign: 'center' }}>
                <div className="user-logout" onClick={() => AuthService.logout()}>
                  Logout
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const renderMenuItems = () => {
    if (!user) {
      return null;
    }

    const pages = getUserPages(user, company);

    if (!pages) {
      return null;
    }

    return pages.map((page, i) => (
      <Link key={i} className="menu-item" to={page.path} onClick={closeMenu}>
        <i className={`${page.icon} mr-2`} />
        {page.label}
      </Link>
    ));
  };

  return (
    <div style={{ width: '52px' }}>
      <BurgerMenu
        customBurgerIcon={
          <div className="burger-menu-button">
            <i className="fas fa-bars" />
          </div>
        }
        customCrossIcon={
          <span>
            <i className="fas fa-times fa-2x" />
          </span>
        }
        pageWrapId="page"
        outerContainerId="nav-page"
        isOpen={isOpen}
        onStateChange={handleStateChange}
      >
        {renderUserBadge()}
        {renderMenuItems()}
      </BurgerMenu>
    </div>
  );
};

export default Menu;

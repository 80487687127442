import { ReactNode, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import DetectRTC from 'detectrtc';
import Api from './services/ApiService';
import LoadingPage from './components/LoadingPage';
import ModalConductor from './components/modal/ModalConductor';
import { companyAtom, userAtom, configAtom } from './stores/root.store';
import AuthService from './services/AuthService';
import Header from './components/Header';
import { useAtom } from 'jotai';

interface Props {
  children?: ReactNode;
}

const RTCPromise = new Promise<void>((resolve) => {
  // prevent running while testing to avoid warning spam
  if (!/HeadlessChrome/.test(window.navigator.userAgent)) {
    // detect video/audio capabilities
    DetectRTC.load(() => resolve());
  } else {
    resolve();
  }
});

const App = (props: Props) => {
  const [config, setConfigState] = useAtom(configAtom);
  const [, setUserState] = useAtom(userAtom);
  const [, setCompanyState] = useAtom(companyAtom);

  // use config.version to know if info is loaded to prevent duplicate effect
  const [ready, setReady] = useState(!!config.version);

  useEffect(() => {
    if (ready) {
      return;
    }

    const infoPromise = Api.get('info').then(({ data }) => {
      if (data.hostname) {
        // server detects the wrong hostname
        setTimeout(() => (window.location.href = `${data.hostname}${window.location.pathname}`));
      }

      if (data.token) {
        AuthService.setToken(data.token);
      }

      setConfigState(data.config);
      if (data.company) {
        setCompanyState(data.company);
      }

      if (data.user) {
        setUserState(data.user);

        if (data.user.isCustomer && !data.user.customer) {
          console.warn('User does not have linked customer account');
        }
      }
    });

    Promise.all([RTCPromise, infoPromise]).then(() => {
      setReady(true);
    });
  }, []);

  if (!ready) {
    return <LoadingPage />;
  }

  return (
    <div id="app">
      <Header />
      {props.children}
      <ToastContainer
        bodyClassName="md-toaster-body"
        className="md-toaster-container"
        closeButton={false}
        hideProgressBar
        position="bottom-center"
        toastClassName="md-toaster"
      />
      <ModalConductor />
    </div>
  );
};

export default App;

import React, { useState, useEffect } from 'react';
import RelativePortal from 'react-relative-portal';
import classNames from 'classnames';
import Search from '../search/Search';
import Api from '../../services/ApiService';
import { PartSuggestionShort, Part } from './part.types';

interface Props {
  onSelected: (value: Part) => void;
  onClear?: () => void;
  value?: string;
}

const PartSearch = ({ value = '', onSelected, onClear }: Props) => {
  const [focus, setFocus] = useState(false);
  const [suggestions, setSuggestions] = useState<PartSuggestionShort[]>([]);

  const getSuggestionValue = (suggestion: PartSuggestionShort) => suggestion.n;

  const loadSuggestions = (query: string) => {
    if (!query) {
      return;
    }

    Api.get('search/parts', { params: { q: query } }).then((response) => {
      setSuggestions(response.data);
    });
  };

  useEffect(() => {
    loadSuggestions(value);
  }, [value]);

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (
    _: React.FormEvent<HTMLInputElement>,
    { suggestion }: { suggestion: PartSuggestionShort }
  ) => {
    if (suggestion) {
      onSelected({ number: suggestion.n, lineCode: suggestion.lc, images: suggestion.images });
    }
    setFocus(false);
  };

  const onFocus = () => {
    setFocus(true);
  };

  const onBlur = () => {
    setFocus(false);
  };

  const renderSuggestionsContainer = ({ containerProps, children }: any) => {
    if (!children) {
      return;
    }

    return (
      <RelativePortal component="div" left={-1} top={1} right={-1} fullWidth className="part-search-portal">
        <div {...containerProps} className="suggestion-wrapper">
          {children}
        </div>
      </RelativePortal>
    );
  };

  const renderSuggestion = (data: PartSuggestionShort) => {
    if (!data) {
      return null;
    }

    const { n, d, c, alias } = data;

    return (
      <div className="suggestion">
        <div className="suggestion-title">
          <div>
            <strong>{d}</strong>
          </div>
          <div>{n}</div>
          {alias && (
            <div title="Alias" className="suggestion-alias">
              {alias}
            </div>
          )}
          {c && (
            <span title="Category" className="suggestion-category">
              {c}
            </span>
          )}
        </div>
      </div>
    );
  };

  const shouldRenderSuggestions = (value: string) => !!value;

  const className = classNames({
    'part-search': true,
    focused: focus,
  });

  return (
    <div className={className}>
      <Search
        getSuggestionValue={getSuggestionValue}
        loadSuggestions={loadSuggestions}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        renderSuggestion={renderSuggestion}
        shouldRenderSuggestions={shouldRenderSuggestions}
        suggestions={suggestions}
        renderSuggestionsContainer={renderSuggestionsContainer}
        onFocus={onFocus}
        onBlur={onBlur}
        onClear={onClear}
        value={value}
      />
    </div>
  );
};

export default PartSearch;

import _ from 'lodash';
import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import classNames from 'classnames';
import RelativePortal from 'react-relative-portal';
import theme from './autosuggest.module.css';

interface Suggestion {
  suggestionValue: string;
}

interface Props {
  clearOnSelect: boolean;
  controlId: string;
  debounce: number;
  getSuggestionValue: (suggestion: any) => string;
  highlightFirstSuggestion: boolean;
  loadSuggestions: (value: string) => void;
  onBlur: (value: string, suggestion: any) => void;
  onClear: () => void;
  onFocus: (event: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: (value: string) => void;
  onSuggestionsClearRequested: () => void;
  onSuggestionSelected: (event: React.FormEvent<HTMLInputElement>, suggestion: any, bool?: boolean) => void;
  placeholder: string;
  renderSuggestion: (args: any) => any;
  renderSuggestionsContainer: (args: any) => any;
  selectOnBlur: boolean;
  shouldRenderSuggestions: (value: string) => boolean;
  suggestions: any[];
  value: string;
}

const defaultProps: Props = {
  clearOnSelect: false,
  controlId: '',
  debounce: 100,
  getSuggestionValue: (suggestion: any) => '',
  highlightFirstSuggestion: true,
  loadSuggestions: (value: string) => undefined,
  onBlur: () => undefined,
  onClear: () => undefined,
  onFocus: () => undefined,
  onSubmit: () => undefined,
  onSuggestionSelected: (event: React.FormEvent<HTMLInputElement>, suggestion: any, bool?: boolean) => undefined,
  onSuggestionsClearRequested: () => undefined,
  placeholder: 'Search',
  renderSuggestion: (args: any) => undefined,
  // eslint-disable-next-line
  renderSuggestionsContainer: ({ containerProps, children }) => {
    return (
      <RelativePortal component="div" left={0} top={0}>
        <div {...containerProps} className="suggestion-wrapper">
          {children}
        </div>
      </RelativePortal>
    );
  },
  selectOnBlur: true,
  shouldRenderSuggestions: (value) => String(value).trim().length > 0,
  suggestions: [],
  value: '',
};

const Search = (props: Props) => {
  const { selectOnBlur, clearOnSelect } = props;
  const [value, setValue] = useState(props.value);
  const debouncedLoadSuggestions = _.debounce(props.loadSuggestions, props.debounce);

  const clearValue = () => {
    setValue('');
    props.onClear();
  };

  const onChange = (_: Event, { newValue }: { newValue: string }) => {
    setValue(newValue);
  };

  const onFocus = (event: React.FormEvent<HTMLInputElement>) => {
    // event.target?.select(); // this was here, not sure what it's for, select is invalid for target
    props.onFocus(event);
  };

  const onBlur = (
    event: React.FormEvent<HTMLInputElement>,
    { highlightedSuggestion }: { highlightedSuggestion: Suggestion }
  ) => {
    // Short-circuit if this is a synthetic event
    // TODO: This will cause a warning in the console. instanceof SyntheticEvent
    // would be nice or not having this at all
    if (!event.isTrusted) {
      return;
    }

    let currentValue: string = value;

    // Mostly for tab induced selection of focused suggestion
    if (selectOnBlur && highlightedSuggestion) {
      currentValue = props.getSuggestionValue(highlightedSuggestion);
      setValue(props.getSuggestionValue(highlightedSuggestion));
      props.onSuggestionSelected(event, { suggestion: highlightedSuggestion }, true);
    } else if (currentValue === '') {
      setValue(currentValue);
      props.onSuggestionSelected(event, '', true);
    }

    // We want to choose whatever value is in the box in the affirmative
    // but at the same time we need to clear it.
    if (clearOnSelect && currentValue !== '') {
      clearValue();
    }

    props.onBlur(currentValue, highlightedSuggestion);
  };

  const onSuggestionsFetchRequested = ({ value }: { value: string }) => {
    debouncedLoadSuggestions(value);
  };

  const onSuggestionSelected = (e: React.FormEvent<HTMLInputElement>, data: Suggestion) => {
    if (clearOnSelect) {
      setValue('');
    } else {
      setValue(data.suggestionValue);
    }
    props.onSuggestionSelected(e, data);
  };

  const { controlId, placeholder, suggestions } = props;

  const controlStyle = classNames({
    'form-control': true,
  });

  const renderInputComponent = (inputProps: any) => {
    return (
      <div>
        <div className="material-search-wrapper">
          <div className="search-icon">
            <i className="fas fa-search" />
          </div>
          <div className="search-input">
            <input {...inputProps} />
          </div>
        </div>
      </div>
    );
  };

  const inputProps: any = {
    className: controlStyle,
    id: controlId,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    value: value || '',
  };

  return (
    <>
      {value !== '' && <i className="fas fa-times clear-icon" onClick={clearValue} />}
      <Autosuggest
        id={controlId}
        key={controlId}
        suggestions={suggestions}
        theme={{ ...theme, input: 'form-control' }}
        inputProps={inputProps}
        highlightFirstSuggestion={props.highlightFirstSuggestion}
        focusInputOnSuggestionClick={false}
        getSuggestionValue={props.getSuggestionValue}
        onSuggestionsClearRequested={props.onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        renderSuggestion={props.renderSuggestion}
        renderInputComponent={renderInputComponent}
        renderSuggestionsContainer={props.renderSuggestionsContainer}
        shouldRenderSuggestions={props.shouldRenderSuggestions}
      />
    </>
  );
};

Search.defaultProps = defaultProps;
export default Search;

import { useEffect, useState } from 'react';
import LoginPanel from './LoginPanel';
import { Link } from 'react-router-dom';
import Api from '../../services/ApiService';
import { companyAtom } from '../../stores/root.store';
import ContactForm from '../contact/ContactForm';
import RegisterForm from './RegisterForm';
import ErrorPage from '../ErrorPage';
import { useAtom } from 'jotai';

const getToken = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('token');
};

const Register = () => {
  const [company] = useAtom(companyAtom);
  const [invalidToken, setInvalidToken] = useState(false);
  const [token, setToken] = useState(getToken());
  const [email, setEmail] = useState('');
  const [, setInviteType] = useState('');
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (token) {
      Api.get(`token/${token}`).then(({ data }) => {
        setInvalidToken(!data.token);
        setReady(true);
        if (data.token) {
          setEmail(data.token.email);
          setInviteType(data.token.type);
        }
      });
    }
  }, [token]);

  // if company is not set, force use to pick a company site
  if (!company.id) {
    return (
      <LoginPanel>
        <ErrorPage message="Not on a company domain" />
      </LoginPanel>
    );
  }

  // no token set, show the contact form
  if (!token) {
    return (
      <LoginPanel className="contact">
        <h3>Fill out this form for access or contact your dealer directly.</h3>
        <ContactForm />
        <div className="extra-actions">
          Already have an account? <Link to="/login">Login</Link>
        </div>
      </LoginPanel>
    );
  }

  if (token && !ready) {
    // loading screen?
    return null;
  }

  // if token is set and invalid
  if (invalidToken) {
    return (
      <LoginPanel>
        <h3>
          Your link is invalid or expired.{' '}
          <a
            onClick={() => {
              setToken('');
              setInvalidToken(false);
            }}
          >
            Click here
          </a>{' '}
          to request another link from the dealer.
        </h3>
      </LoginPanel>
    );
  }

  // happy path
  return (
    <LoginPanel>
      <h3>Create Your Account</h3>
      <RegisterForm email={email} token={token} />
      <div className="extra-actions">
        Already have an account? <Link to="/login">Login</Link>
      </div>
    </LoginPanel>
  );
};

export default Register;

import { Component } from 'react';
import classNames from 'classnames';
import { Button, Modal, ModalProps } from 'react-bootstrap';

import '../../styles/modalwrapper.scss';

const defaultModalProps: ModalProps = {
  animation: false,
  size: 'lg',
  'aria-labelledby': 'contained-modal-title-sm',
  dialogClassName: '',
  className: 'default animatedFast fadeOut',
  onHide: () => null, // make ts happy
};

interface Props {
  children: JSX.Element;
  onCancel?: () => void;
  onClose?: () => void;
  onOk?: (payload?: any) => void;
  closeButton: boolean;
  confirm: boolean;
  confirmLabels: string[];
  defaultLabel: string;
  handlePrint: () => void;
  headerClass: string;
  headerLink: string;
  hideFooter: boolean;
  showPrint: boolean;
  naked: boolean;
  show: boolean;
  title: string | JSX.Element;
  isTop: boolean;
  modalProps: ModalProps;
}

class ModalWrapper extends Component<Props> {
  static defaultProps = {
    closeButton: true,
    confirm: false,
    confirmLabels: ['Ok', 'Cancel'],
    defaultLabel: 'Close',
    handlePrint: () => window.print(),
    headerClass: 'bg-default',
    headerLink: '',
    hideFooter: false,
    showPrint: false,
    naked: false,
    isTop: true,
    show: true,
    modalProps: {},
  };

  renderHeader() {
    const { closeButton, showPrint, handlePrint, headerLink, title, headerClass } = this.props;

    return (
      <Modal.Header closeButton={closeButton} className={`modal-header ${headerClass}`}>
        {showPrint && (
          <div className="print" onClick={handlePrint}>
            <i className="fas fa-print" />
          </div>
        )}
        {headerLink && (
          <a className="print" href={headerLink} rel="noopener noreferrer" target="_blank" title="Open In New Window">
            <i className="fas fa-external-link-alt" />
          </a>
        )}
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
    );
  }

  renderFooter() {
    const { hideFooter, confirm, onCancel, onClose, onOk, confirmLabels, defaultLabel } = this.props;

    if (hideFooter) {
      return null;
    }

    return (
      <Modal.Footer>
        {confirm ? (
          <div>
            <Button className="mr-1" onClick={onCancel}>
              {confirmLabels[1]}
            </Button>
            <Button onClick={onOk} variant="primary">
              {confirmLabels[0]}
            </Button>
          </div>
        ) : (
          <Button onClick={onClose}>{defaultLabel}</Button>
        )}
      </Modal.Footer>
    );
  }

  render() {
    const { isTop, show, naked, onClose, children, modalProps } = this.props;

    const { className = '', ...restModalProps } = modalProps;

    const computedClassName = classNames({
      top: isTop,
      under: !isTop,
      zoomIn: show,
      naked,
      [className]: true,
      'modal-wrapper': true,
    });

    return (
      <Modal
        {...defaultModalProps}
        {...restModalProps}
        backdrop={isTop}
        className={computedClassName}
        onHide={onClose}
        show={show}
      >
        {this.renderHeader()}
        <Modal.Body>{children}</Modal.Body>
        {this.renderFooter()}
      </Modal>
    );
  }
}

export default ModalWrapper;

interface Props {
  style?: object;
  message?: string;
  children?: JSX.Element[] | JSX.Element | string;
}

const ErrorPage = ({ style = {}, message = 'Something Went Wrong', children }: Props) => {
  const renderBody = () => {
    if (children) {
      return <>{children}</>;
    }

    if (message) {
      return <div>{message}</div>;
    }

    return null;
  };

  return (
    <div className="loading-wrapper error-wrapper" style={style}>
      <div className="loading-child">{renderBody()}</div>
    </div>
  );
};

export default ErrorPage;

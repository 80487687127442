import Api from '../../services/ApiService';
import {
  WorldNetAddCardParams,
  WorldNetServicePaymentHashParams,
  WorldNetServicePaymentHashResponse,
  WorldNetServiceTokenHashParams,
  WorldNetServiceTokenHashResponse,
} from './payment.types';

const getPaymentHash = async (
  params: WorldNetServicePaymentHashParams
): Promise<WorldNetServicePaymentHashResponse> => {
  const response = await Api.get('/payment/worldnet/hash', {
    params,
  });

  return response.data;
};

const getTokenHash = async (params: WorldNetServiceTokenHashParams): Promise<WorldNetServiceTokenHashResponse> => {
  const response = await Api.get('/payment/worldnet/token-hash', {
    params,
  });

  return response.data;
};

const addCard = async (params: WorldNetAddCardParams): Promise<Record<string, any>> => {
  const response = await Api.post('/payment/worldnet/card', params);

  return response.data;
};

export default {
  addCard,
  getTokenHash,
  getPaymentHash,
};

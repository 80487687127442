import 'chart.js/auto';
import { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import Api from '../../services/ApiService';
import { graphColors } from './widget-util';

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
};

const ActiveCustomers = () => {
  const [data, setData] = useState<[] | null>();

  useEffect(() => {
    Api.get(`/customer-report/active-users`).then((response) => {
      setData(response.data);
    });
  }, []);

  if (!data) {
    return null;
  }

  const labels = data.map((x: any) => x.branch.name);

  const activeCount: number[] = [];
  data.forEach((x: any) => {
    activeCount.push(x.userCount);
  });

  return (
    <Chart
      type="pie"
      options={options}
      data={{
        labels,
        datasets: [
          {
            data: activeCount,
            backgroundColor: graphColors,
          },
        ],
      }}
    />
  );
};

export default ActiveCustomers;

import { useEffect } from 'react';

import MainNavbar from './MainNavbar';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/AuthService';
import PageContainer from './PageContainer';
import { useAtom } from 'jotai';
import userAtom from '../stores/user.store';

interface Props {
  children?: JSX.Element;
}

const NavPage = ({ children }: Props) => {
  const [user] = useAtom(userAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || user.id === undefined || user.id === -1) {
      // we are assuming all NavPage's require auth
      AuthService.removeToken();
      navigate('/login');
    }
  }, [user]);

  return (
    <div id="nav-page">
      <MainNavbar />
      <PageContainer>{children}</PageContainer>
    </div>
  );
};

export default NavPage;

import { Component } from 'react';
import ModalWrapper from './ModalWrapper';
import { EE } from './ModalEmitter';
import { IModal } from '.';

interface IState {
  modals: IModal[];
}

const modalDefaults = {
  title: '',
  body: null,
  confirm: false,
  onOk: (args?: any) => null,
  onCancel: (args?: any) => null,
  onClose: (args?: any) => null,
  modalProps: {},
};

class ModalConductor extends Component<{}, IState> {
  static defaultProps = {
    modal: {},
  };

  constructor(props: object) {
    super(props);

    this.state = {
      modals: [],
    };
  }

  componentDidMount() {
    EE.on('modal/open', (args) => {
      this.show({ ...args });
    });

    EE.on('modal/close', () => {
      this.close();
    });
  }

  componentWillUnmount() {
    EE.removeListener('modal/open');
    EE.removeListener('modal/close');
  }

  getTopModal() {
    const { modals } = this.state;
    return modals[modals.length - 1];
  }

  show(args: IModal) {
    const { modals } = this.state;
    modals.push({ ...modalDefaults, id: Math.random().toString(36), ...args });
    this.setState({ modals });
  }

  ok = (payload: any) => {
    const topModal = this.getTopModal();
    if (topModal.onOk) {
      topModal.onOk(payload);
    }
    this.close();
  };

  cancel = () => {
    const topModal = this.getTopModal();
    if (topModal.onCancel) {
      topModal.onCancel();
    }
    this.close();
  };

  close = () => {
    const { modals } = this.state;

    const modal = modals.pop();

    if (modal?.onClose) {
      modal.onClose();
    }

    this.setState({ modals });
  };

  renderModalBody(modal: IModal) {
    return <div>{modal.body}</div>;
  }

  render() {
    const modals: JSX.Element[] = [];

    this.state.modals.forEach((modal: IModal, index: number) => {
      const { onOk, onClose, onCancel, ...rest } = modal;

      const isTop = this.state.modals.length === index + 1;

      modals.push(
        <ModalWrapper
          key={index}
          onCancel={this.cancel}
          onClose={this.close}
          onOk={this.ok}
          isTop={isTop}
          show={isTop}
          {...rest}
        >
          {this.renderModalBody(modal)}
        </ModalWrapper>
      );
    });

    return <div id="modal-conductor">{modals}</div>;
  }
}

export default ModalConductor;

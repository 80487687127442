import { useState, useEffect } from 'react';
import Api from '../../services/ApiService';
import ListViewToolbar from '../list/ListViewToolbar';
import ListView from '../list/ListView';
import { Button, ButtonGroup } from 'react-bootstrap';
import { modal } from '../modal/ModalEmitter';
import VehicleHistory from './VehicleHistory';
import VehicleUpdate from './VehicleUpdate';
import LoadingPage from '../LoadingPage';
import ErrorPage from '../ErrorPage';

const Vehicles = () => {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(true);

  const getVehicles = () => {
    Api.get('vehicles').then(({ data }: any) => {
      setVehicles(data);
      setLoading(false);
    });
  };

  const columnDefs = [
    {
      pinned: 'left',
      headerName: '',
      field: 'action',
      suppressSizeToFit: true,
      suppressMenu: true,
      sortable: false,
      cellClass: 'ag-col-center',
      width: 90,
      cellRenderer: (cellProps: any) => {
        const { vin, make, model, year, mileage, stockNumber } = cellProps.data;

        const onHistory = () => {
          modal.open({
            title: `VIN #: ${vin}`,
            body: <VehicleHistory vin={vin} />,
          });
        };

        const onSubmit = () => {
          modal.close();
          getVehicles();
        };

        const onUpdate = () => {
          modal.open({
            title: 'Vehicle Update',
            body: (
              <VehicleUpdate
                vin={vin}
                make={make}
                model={model}
                year={year}
                unitNumber={stockNumber}
                mileage={mileage}
                onSubmit={onSubmit}
                onCancel={modal.close}
              />
            ),
            hideFooter: true,
          });
        };

        return (
          <ButtonGroup className="list-row-toolbar">
            <Button size="sm" onClick={onHistory} title="Show History" className="list-vehicle-show-history">
              <i className="fas fa-history" />
            </Button>
            <Button size="sm" onClick={onUpdate} title="Update Mileage" className="list-vehicle-update-mileage">
              <i className="fas fa-edit" />
            </Button>
          </ButtonGroup>
        );
      },
    },
    { headerName: 'VIN #', field: 'vin' },
    { headerName: 'Unit # / Stock #', field: 'stockNumber' },
    {
      headerName: 'Year',
      field: 'year',
    },
    { headerName: 'Make', field: 'make' },
    { headerName: 'Model', field: 'model' },
    {
      headerName: 'Mileage',
      field: 'mileage',
      valueFormatter: (params: any) => {
        return parseInt(params.value || '0', 10).toLocaleString();
      },
      // cellStyle: { textAlign: 'right' },
    },
  ];

  useEffect(() => {
    setLoading(true);
    getVehicles();
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  if (!vehicles.length) {
    return <ErrorPage>No Results Found</ErrorPage>;
  }

  return (
    <ListView canAdd={false} canDelete={false} canEdit={false} columnDefs={columnDefs} rowData={vehicles}>
      <ListViewToolbar canExport header="Vehicles / Service History" />
    </ListView>
  );
};

export default Vehicles;

import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Api from '../../services/ApiService';

const ReindexPartsButton = () => {
  const [processing, setProcessing] = useState(false);

  return (
    <div className="admin-controls">
      <Button
        variant="warning"
        disabled={processing}
        onClick={() => {
          setProcessing(true);
          Api.post('/search/parts/index-images')
            .then(() => {
              toast.success('Part Images Reindexed');
            })
            .finally(() => {
              setProcessing(false);
            });
        }}
      >
        {processing ? 'Processing' : 'Reindex Images'}
      </Button>
    </div>
  );
};

export default ReindexPartsButton;

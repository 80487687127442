import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import ReactMarkdown from 'react-markdown';
import { useAtom } from 'jotai';
import PartSearch from './PartSearch';
import { Part, PartStockCheck } from './part.types';
import { usePartsStore } from './part.store';
import PartService from '../../services/PartService';
import { companyAtom, userAtom } from '../../stores/root.store';
import PartItemIcon from './PartItemIcon';
import BarcodeButton from '../BarcodeButton';
import { PartAltBranch } from './part.types';

interface Props {
  hideSearch?: boolean;
  onAddToCart?: () => void;
}

const PartCard = ({ hideSearch = false, onAddToCart = () => undefined }: Props) => {
  const [company] = useAtom(companyAtom);
  const [user] = useAtom(userAtom);

  const [searchText, setSearchText] = useState('');
  const [inputKey, setInputKey] = useState('');

  const selectedPart = useRef<{ partNumber: string; lineCode: string }>();

  const { activePart, cart, setActivePart, setCart, resetActivePart, addToCart, getSpecialComments, getUnitPrice } =
    usePartsStore();

  const { comment = '', quan = 0, edit } = activePart || resetActivePart();

  useEffect(() => {
    if (quan > 999999) {
      setActivePart({ quan: 999999 });
    }
  }, [quan]);

  const onSelected = (part: Part) => {
    PartService.stockCheck({ partNumber: part.number, lineCode: part.lineCode, quantity: 1 })
      .then((data: PartStockCheck) => {
        setActivePart(
          {
            images: part.images,
            ...data,
            quan: 1,
            comment: '',
          },
          false
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleAddToCart = () => {
    for (const item of cart) {
      // if the item already exists, increment part
      if (item.partno === activePart.partno && item.linecode === activePart.linecode) {
        if (edit) {
          item.quan = quan;
        } else {
          item.quan += quan;
        }

        item.comment = comment;
        setCart(cart);
        resetActivePart();
        onAddToCart();
        return;
      }
    }

    addToCart({ ...activePart, quan, comment });
    resetActivePart();
    onAddToCart();
  };

  const onCancel = () => {
    resetActivePart();
  };

  const getBranchByNum = (num: string) => {
    // TODO optimize this
    const { branches } = company;

    if (!branches || !branches.length) {
      return null;
    }

    for (const branch of branches) {
      if (branch.num === parseInt(num, 10) && branch.active) {
        return branch;
      }
    }

    return null;
  };

  const getStockCountLabel = (qty: number | string) => {
    qty = Number(qty);

    const lowStockNote = _.get(company, 'config.parts.lowStock.message');
    const lowStockCount = _.get(company, 'config.parts.lowStock.count', 0);

    if (qty > lowStockCount || !lowStockNote) {
      return qty;
    }

    return <span style={{ fontSize: '85%' }}>{lowStockNote}</span>;
  };

  const getLowStockClass = (qty: number | string) => {
    qty = Number(qty);

    const lowStockCount = _.get(company, 'config.parts.lowStock.count', 0);

    return qty / 1 <= lowStockCount ? 'stock-amount zero' : 'stock-amount';
  };

  const renderAltBranches = () => {
    const { branch } = user;

    const options = (activePart.altbranch || [])
      .map((altBranch: PartAltBranch) => {
        const branchName = getBranchByNum(altBranch.albr);

        if (!branchName || !altBranch) {
          return null;
        }

        return {
          label: (
            <div className="option">
              <div className="branch-name">{branchName.name}</div>
              <div className={getLowStockClass(altBranch.altqty)}>{getStockCountLabel(altBranch.altqty)}</div>
            </div>
          ),
          value: altBranch.albr,
        };
      })
      .filter(Boolean);

    if (branch && branch.num) {
      options.unshift({
        label: (
          <div className="option">
            <div className="branch-name">{branch.name}</div>
            <div className={getLowStockClass(activePart.qtyavail)}>{getStockCountLabel(activePart.qtyavail)}</div>
          </div>
        ),
        value: String(branch.num),
      });
    }

    // @ts-ignore force render to update default value
    return <Select key={Date.now()} options={options} defaultValue={options[0]} className="select-branch" />;
  };

  const renderExtraParts = () => {
    // alt part examples from PIM3B
    if (!activePart.partext || !activePart.partext.length || activePart.edit) {
      return null;
    }

    const options = activePart.partext.map((ext: PartStockCheck, i: number) => (
      <option key={ext.partno} value={i}>
        {ext.desc} ({ext.partno}) ${ext.cost}
      </option>
    ));

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!selectedPart.current) {
        selectedPart.current = { partNumber: activePart.partno, lineCode: activePart.linecode };
      }

      if (!e.target.value) {
        // original part
        const { partNumber, lineCode } = selectedPart.current;
        PartService.stockCheck({ partNumber, lineCode, quantity: 1 }).then((data: PartStockCheck) => {
          setActivePart(data, false);
        });
        return;
      }

      if (!activePart.partext || !activePart.partext.length) {
        return;
      }

      const { partno, linecode } = activePart.partext[e.target.value as unknown as number];
      PartService.stockCheck({ partNumber: partno, lineCode: linecode, quantity: 1 }).then((data: PartStockCheck) => {
        setActivePart(data, false);
      });
    };

    return (
      <Form.Group>
        <Form.Label>Alternate Parts ({activePart.partext.length})</Form.Label>
        <Form.Control as="select" onChange={handleChange}>
          <option />
          {options}
        </Form.Control>
      </Form.Group>
    );
  };

  const renderIcon = () => {
    if (activePart.images && activePart.images.length) {
      return <PartItemIcon partNumber={activePart.partno} description={activePart.desc} images={activePart.images} />;
    }

    return <i className="fas fa-image" />;
  };

  const renderMemo = () => {
    if (!activePart.memo) {
      return null;
    }

    return <div className="part-memo">{activePart.memo}</div>;
  };

  const renderMarkdown = () => {
    if (!activePart.portaltext) {
      return null;
    }

    return <ReactMarkdown className="markdown">{activePart.portaltext.replace(/\\n/g, '\n')}</ReactMarkdown>;
  };

  const renderQtyBreaks = () => {
    const { qtybreak } = activePart;

    if (!qtybreak || !qtybreak.length) {
      return <div />;
    }

    // largest first
    const qtys = qtybreak.map((line) => Number(line.qty)).sort((a, b) => b - a);

    let activeQtyBreak: number;
    for (const qty of qtys) {
      if (quan >= qty) {
        activeQtyBreak = qty;
        break;
      }
    }

    return qtybreak.map((line, i: number) => {
      const style = Number(line.qty) === activeQtyBreak ? { color: 'var(--green-5)' } : {};

      return (
        <div key={i} style={style}>
          Qty: {line.qty}+ Unit Price: ${line.price}
        </div>
      );
    });
  };

  const renderCardBody = () => {
    const hasPart = !!activePart.partno;

    if (!hasPart && !hideSearch) {
      return (
        <div className="empty-items">
          <div className="empty-msg">
            <i className="fas fa-arrow-up" />
            <p className="help">Search and select a part to get more information</p>
          </div>
        </div>
      );
    }

    return (
      <div className="part-card-body fadeIn">
        <div className="info">
          <div className="part-data">
            <div className="part-image">{renderIcon()}</div>
            <div className="part-text">
              <div className="part-description">{activePart.desc}</div>
              <div className="part-number">
                <strong>{activePart.partno}</strong>
              </div>
              {activePart.linedesc && <div className="part-linedesc">{activePart.linedesc}</div>}
              {activePart.website && (
                <div>
                  <a
                    href={activePart.website.startsWith('http') ? activePart.website : `https://${activePart.website}`}
                    rel="nofollow"
                    target="_blank"
                  >
                    {activePart.website}
                  </a>
                </div>
              )}
              {activePart.weight && <div className="part-weight">Weight: {activePart.weight}lb</div>}
              {activePart.width && <div>Width: {activePart.width}"</div>}
              {activePart.length && <div>Length: {activePart.length}"</div>}
              {activePart.height && <div>Height: {activePart.height}"</div>}
              {activePart.casequantity && <div>Case Quan: {activePart.casequantity}</div>}
              {activePart.countryoforigin && <div>Origin: {activePart.countryoforigin}</div>}
              {activePart.tariff && <div>Tariff: {activePart.tariff}</div>}

              {activePart.upceach && <div className="part-upc">UPC: {activePart.upceach}</div>}
              {activePart.upccase && <div className="part-upccase">UPC Case: {activePart.upccase}</div>}
            </div>
          </div>

          <div className="alt-parts">{renderExtraParts()}</div>
        </div>
        <div className="pricing">
          <div className="branches">{renderAltBranches()}</div>
          <div className="prices">
            <ul className="leaders">
              {/* <li>
                <span>Stock</span>
                <span className={`stock ${Number(activePart.qtyavail) === 0 ? 'out' : 'in'}`}>
                  {activePart.qtyavail}
                </span>
              </li> */}
              <li>
                <span>Unit Price</span> <span>${activePart.cost}</span>
              </li>
              {!!parseInt(activePart.core, 10) && (
                <li>
                  <span>Core Price</span> <span>${activePart.core}</span>
                </li>
              )}
            </ul>
          </div>
          {activePart.special && (
            <div className="special">
              <div>SPECIAL</div>
              <div>{getSpecialComments()}</div>
              <div>{renderQtyBreaks()}</div>
            </div>
          )}
          {renderMemo()}
        </div>
        <div className="controls">
          {user.isCustomer && (
            <div className="control-form">
              <label>Comment</label>
              <textarea value={comment} name="comment" onChange={(e) => setActivePart({ comment: e.target.value })} />
            </div>
          )}

          <div className="control-form qty">
            <label>Qty</label>
            <input
              min="1"
              max="999999"
              type="number"
              value={quan}
              onChange={(e) => setActivePart({ quan: parseInt(e.target.value, 10) })}
            />
            <div className="factor">
              <i className="fas fa-times" style={{ fontSize: '10px' }} />{' '}
              {(getUnitPrice(activePart, quan) + Number(activePart.core)).toFixed(2)}
            </div>
            <div className="total">
              ${((getUnitPrice(activePart, quan || 0) + Number(activePart.core)) * quan || 0).toFixed(2)}
            </div>
          </div>
          {user.isCustomer && (
            <div className="control-form add">
              {edit && (
                <>
                  <Button block variant="default" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button block variant="success" disabled={quan <= 0} onClick={handleAddToCart}>
                    Save
                  </Button>
                </>
              )}

              {!edit && (
                <Button block variant="success" disabled={quan <= 0} onClick={handleAddToCart} id="add-to-cart-btn">
                  Add to Cart
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const onScan = (value: string) => {
    setSearchText(value);

    // force rerender of input to reflect new value
    setInputKey(value);
  };

  return (
    <div className="part-card">
      {!hideSearch && (
        <div className="part-search-wrapper">
          <PartSearch value={searchText} onSelected={onSelected} onClear={onCancel} key={inputKey} />
          <BarcodeButton onScan={onScan} />
        </div>
      )}
      {renderCardBody()}
      {renderMarkdown()}
    </div>
  );
};

export default PartCard;

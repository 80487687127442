import { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { useAtom } from 'jotai';
import Form from './Form';
import DbService from '../services/DbService';
import LoadingPage from './LoadingPage';
import Api from '../services/ApiService';
import { toast } from 'react-toastify';
import userAtom from '../stores/user.store';
import PageNavbar from './PageNavbar';

const getLayout = ({ companies, isAdmin, companyId }: any = {}) => {
  const layout: any = [{ name: 'email', label: 'Email Address', type: 'email', required: true }];

  if (companies && companies.length) {
    layout.push({
      control: 'select',
      label: 'Company',
      name: 'CompanyId',
      options: companies,
      required: true,
      type: 'integer',
      data: companyId,
    });
  }

  layout.push({
    control: 'checkbox',
    name: 'isAdmin',
    label: 'Dealer Admin',
    type: 'boolean',
    options: [true, false],
    data: !!isAdmin,
  });

  return layout;
};

const InviteDealer = () => {
  const form = useRef(null);
  const [user] = useAtom(userAtom);
  const [layout, setLayout] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [companyId, setCompanyId] = useState('');
  const [companies, setCompanies] = useState<{ label: string; value: string | number }[]>([]);
  const [key, setKey] = useState(0);

  useEffect(() => {
    if (!user.isAdmin) {
      setLayout(getLayout());
      return;
    }

    DbService.getAll('company').then(({ data }) => {
      const companyOptions = data.map((obj: Record<string, any>) => {
        return { label: obj.name, value: obj.id };
      });

      setCompanies(companyOptions);
      setLayout(getLayout({ companies: companyOptions }));
    });
  }, [user]);

  const handleSubmit = (data: { CompanyId?: string; isAdmin: boolean; email: string }) => {
    Api.post('dealers/invite', data).then(() => {
      setLayout(getLayout({ companies, isAdmin, companyId }));
      setKey(Date.now());
      toast(`Email invitation sent to ${data.email}`);
    });
  };

  const handleChange = ({ name, value }: { name: string; value: string | boolean }) => {
    if (name === 'isAdmin') {
      /* @ts-ignore */
      setIsAdmin(value);
    } else if (name === 'CompanyId') {
      /* @ts-ignore */
      setCompanyId(value);
    }
  };

  if (!layout.length) {
    return <LoadingPage />;
  }

  const className = classNames({
    'invite-dealer': true,
    'p-3': true,
  });

  return (
    <>
      <PageNavbar header="Invite Dealer" />
      <div className={className}>
        <Row>
          <Col md={6} lg={5}>
            <div className="form-wrap shadow">
              <Form
                ref={form}
                key={key}
                layout={layout}
                onSubmit={handleSubmit}
                onChange={handleChange}
                hideCancel
                submitAll
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default InviteDealer;

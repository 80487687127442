import _ from 'lodash';
import { Branch } from './user.store';
import { atom } from 'jotai';

interface Permissions {
  customer: {
    backOrders: boolean;
    vehicles: boolean;
    promotions: boolean;
    part: {
      search: boolean;
    };
  };
  [key: string]: any;
}

export interface ShippingMethod {
  label: string;
  code: string;
}

export interface Company {
  id: number;
  name: string;
  branches: Branch[];
  permissions: Permissions;
  config: {
    payment: {
      accountId: number;
    };
    parts: {
      list: {
        hideDivision: boolean;
      };
      lowStock: {
        message: string;
        count: number;
      };
    };
    orders: {
      shippingTiers: boolean;
      pickupCode: string;
    };
  };
  hideMainSearch: boolean;
  dashboardMd: string;
  logo: string;
  num: number;
  shippingMethods: ShippingMethod[];
  locale: {
    currencyCode: string;
  };
}

const defaultState: Company = {
  id: 0,
  name: 'DSI Solutions',
  branches: [],
  permissions: {
    customer: {
      backOrders: true,
      vehicles: true,
      promotions: true,
      part: {
        search: true,
      },
    },
  },
  config: {
    payment: {
      accountId: 0,
    },
    parts: {
      list: {
        hideDivision: false,
      },
      lowStock: {
        message: '',
        count: 0,
      },
    },
    orders: {
      shippingTiers: false,
      pickupCode: '',
    },
  },
  hideMainSearch: false,
  dashboardMd: '',
  logo: '',
  num: 0,
  shippingMethods: [],
  locale: {
    currencyCode: 'USD',
  },
};

const primitiveCompanyAtom = atom<Company>(defaultState);

const companyAtom = atom(
  (get) => {
    const value = get(primitiveCompanyAtom);
    return {
      ...value,
      config: _.defaultsDeep(value.config || {}, defaultState.config),
      hasPermission(key: string) {
        return _.get(value.permissions, key);
      },
      canAcceptPayments() {
        return this.hasPermission('customer.payment.account');
      },
      hasShippingTiers() {
        return value.config.orders.shippingTiers;
      },
    };
  },
  (get, set, newValue: Partial<Company>) => set(primitiveCompanyAtom, { ...get(primitiveCompanyAtom), ...newValue })
);

export default companyAtom;

import axios from 'axios';
import config from '../config';

axios.defaults.withCredentials = true;

const Api = axios.create({
  baseURL: config.apiUrl,
});

Api.interceptors.request.use((axiosConfig: any) => {
  // Using httpOnly cookies instead
  // axiosConfig.headers.Authorization = AuthService.getToken();
  return axiosConfig;
});

export default Api;

import React from 'react';
import BlazeForm, { FormManager } from 'blaze-form';
import BlazeInputReactSelect from 'blaze-input-react-select';
import LogoUpload from './LogoUpload';
import { modal } from './modal/ModalEmitter';

const manager = new FormManager();
manager.registerControl({ name: 'ReactSelect', component: BlazeInputReactSelect });

const Form = ({ forwardedRef, children, ...rest }: any) => {
  const onLaunch = () => {
    modal.open({ body: <LogoUpload />, title: 'Upload Logo' });
  };

  return (
    <BlazeForm ref={forwardedRef} {...rest} manager={manager} onLaunch={onLaunch}>
      {children}
    </BlazeForm>
  );
};

export default React.forwardRef((props: any, ref: any) => <Form forwardedRef={ref} {...props} />);

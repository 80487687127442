import { useEffect, useState } from 'react';
import BlazeForm from 'blaze-form';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useRecaptcha } from '../form/form.hooks';
import Api from '../../services/ApiService';
import { LayoutItem } from 'blaze-validator';

interface FormSubmitData {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

const getLayout = ({ email }: { email: string }) => [
  {
    name: 'email',
    type: 'email',
    label: 'Your Email',
    data: email,
    readOnly: !!email,
    required: true,
  },
  {
    name: 'password',
    type: 'password',
    label: 'Password',
    required: true,
  },
  {
    name: 'firstName',
    label: 'First Name',
    required: true,
  },
  {
    name: 'lastName',
    label: 'Last Name',
    required: true,
  },
] as LayoutItem[];

interface Props {
  email: string;
  token: string;
}

const RegisterForm = ({ email, token }: Props) => {
  const [captcha, Recaptcha] = useRecaptcha();
  const [key, setKey] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    setKey(Date.now());
  }, [email, token]);

  const handleSubmit = (data: FormSubmitData) => {
    return Api.post('register', { ...data, token, captcha }).then(() => {
      navigate('/');
    });
  };

  return (
    <BlazeForm key={key} onSubmit={handleSubmit} layout={getLayout({ email })} submitAll hideToolbar>
      <>
        {Recaptcha}
        <div className="login-controls">
          <Button variant="primary" type="submit" block id="login-btn" data-blaze-submit>
            Register
          </Button>
        </div>
      </>
    </BlazeForm>
  );
};

export default RegisterForm;

import { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import Api from '../../services/ApiService';

interface SalesSumData {
  orderCount: number;
  salesTotal: number;
  branchName: string;
}

const options = {
  type: 'line',
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  stacked: false,
  scales: {
    countAxis: {
      type: 'linear' as const,
      display: true,
      position: 'left' as const,
    },
    sumAxis: {
      type: 'linear' as const,
      display: true,
      position: 'right' as const,
      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        callback: (value: string | number) =>
          Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
      },
    },
  },
};

const OrderSalesByBranch = () => {
  const [data, setData] = useState<SalesSumData[] | null>();

  useEffect(() => {
    Api.get('/order-report/sales-sum', { params: { byBranch: true, days: 7 } }).then((response) => {
      setData(response.data);
    });
  }, []);

  if (!data) {
    return null;
  }

  const labels = data.map((x) => x.branchName);

  const sumSales: number[] = [];
  const numOrders: number[] = [];
  data.forEach((x) => {
    sumSales.push(x.salesTotal);
    numOrders.push(x.orderCount);
  });

  return (
    <Chart
      type="bar"
      options={options}
      data={{
        labels,
        datasets: [
          {
            type: 'line',
            label: 'Order Count',
            borderColor: 'rgb(255, 99, 132)',
            fill: false,
            borderWidth: 2,
            data: numOrders,
            yAxisID: 'countAxis',
          },
          {
            type: 'bar',
            label: 'Order Sum $',
            backgroundColor: 'rgb(53, 162, 235)',
            data: sumSales,
            yAxisID: 'sumAxis',
          },
        ],
      }}
    />
  );
};

export default OrderSalesByBranch;

import { useState, useEffect, useRef, useContext } from 'react';
import dayjs from 'dayjs';
import configAtom from '../../stores/config.store';
import { Invoice, WorldNetReceiptQueryParams } from './payment.types';
import formPost from './FormPost';
import WorldNetService from './WorldNetService';
import CustomIframe from './CustomIframe';
import { useAtom } from 'jotai';

/**
 * Test card 4444333322221111  cvv 123
 */
interface Props {
  accountId: number;
  amount: number;
  clientNum: number;
  currency?: 'USD' | 'EUR' | 'GBP';
  customerNum?: string;
  email: string;
  invoices: Invoice[];
  onReceipt?: (params: WorldNetReceiptQueryParams) => void;
  receiptPageUrl?: string;
  sessionId: string;
  validationUrl?: string;
}

const WorldNetPayContainer = ({
  accountId,
  amount,
  clientNum,
  currency = 'USD',
  customerNum = '',
  email,
  invoices,
  onReceipt,
  receiptPageUrl = '',
  sessionId,
  validationUrl = '',
}: Props) => {
  const [config] = useAtom(configAtom);
  const dateRef = useRef(new Date());
  const dateTime = dayjs(dateRef.current).format('DD-MM-YYYY:HH:mm:ss:SSS');
  const [hash, setHash] = useState('');
  const [pageUrl, setPageUrl] = useState('');
  const [terminalId, setTerminalId] = useState('');

  if (!validationUrl) {
    validationUrl = config.worldnet.validationUrl;
  }

  if (!receiptPageUrl) {
    receiptPageUrl = `${location.origin}${config.worldnet.receipt.path}`;
  }

  const searchParams = new URLSearchParams({
    clientNum: String(clientNum),
    sessionId,
    accountId: String(accountId),
    email,
    invoices: btoa(JSON.stringify(invoices)),
    customerNum,
  });

  validationUrl += `?${searchParams.toString()}`;

  // Order id is limited to 24 chars and cannot reuse an order id
  const orderId = (BigInt(sessionId).toString(36) + '-' + dateRef.current.getTime().toString(36)).toUpperCase();

  useEffect(() => {
    const messageHandler = (event: { data: any }) => {
      if (onReceipt) {
        onReceipt(event.data);
      }
    };

    window.addEventListener('message', messageHandler);

    WorldNetService.getPaymentHash({
      accountId,
      orderId,
      dateTime,
      amount,
      receiptPageUrl,
      validationUrl,
      clientNum,
    }).then((response) => {
      setHash(response.hash);
      setPageUrl(response.pageUrl);
      setTerminalId(response.terminalId);
    });

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, []);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hash && pageUrl && terminalId) {
      formPost({
        path: `${pageUrl}/merchant/paymentpage`,
        params: { terminalId, orderId, currency, amount, dateTime, hash, receiptPageUrl, validationUrl },
        uppercaseKeys: true,
        containerRef,
      });
    }
  }, [hash, pageUrl, terminalId]);

  return (
    <>
      {config.env === 'development' && <div style={{ position: 'absolute' }}>DEV: Test Card: 4444333322221111</div>}
      <CustomIframe style={{ border: 0, background: 'transparent', width: '100%', height: '100%' }}>
        <div ref={containerRef} />
      </CustomIframe>
    </>
  );
};

export default WorldNetPayContainer;

import _ from 'lodash';
import EventEmitter from 'eventemitter3';
import { IModal } from '.';

const emitter = new EventEmitter();

interface ModalActions {
  open: (args: IModal) => void;
  confirm: (args: IModal | string) => void;
  alert: (args: IModal | string) => void;
  info: (args: IModal | string) => void;
  close: () => void;
}

const actions: ModalActions = {
  open: (args: IModal) => {
    emitter.emit('modal/open', args);
  },

  confirm: (args: IModal | string) => {
    const defaults = {
      title: 'Confirm',
      render: 'confirm',
      headerClass: 'bg-default',
      confirm: true,
      modalProps: {
        size: 'sm',
        className: 'center',
      },
    };

    const options = typeof args === 'string' ? { body: args } : args || {};
    emitter.emit('modal/open', _.defaults(options, defaults));
  },

  alert: (args: IModal | string) => {
    const defaults = {
      title: 'Alert',
      render: 'alert',
      headerClass: 'bg-danger',
      modalProps: {
        size: 'sm',
        className: 'center',
      },
    };
    const options = typeof args === 'string' ? { body: args } : args || {};
    emitter.emit('modal/open', _.defaults(options, defaults));
  },

  info: (args: IModal | string) => {
    const defaults = {
      title: 'Info',
      render: 'info',
      headerClass: 'primary',
      modalProps: {
        size: 'lg',
        className: 'center',
      },
    };
    const options = typeof args === 'string' ? { body: args } : args || {};
    emitter.emit('modal/open', _.defaults(options, defaults));
  },

  close: () => {
    emitter.emit('modal/close');
  },
};

export const EE = emitter;
export const modal = actions;

import { useEffect, useState } from 'react';
import Api from '../../services/ApiService';
import ErrorPage from '../ErrorPage';
import LoadingPage from '../LoadingPage';
import ListView from '../list/ListView';
import ListViewToolbar from '../list/ListViewToolbar';
import { ButtonGroup } from 'react-bootstrap';
import AsyncButton from '../form/AsyncButton';
import FileService from '../../services/FileService';
import config from '../../config';
import { RowNode } from '@ag-grid-community/core';

const columnDefs = [
  {
    pinned: 'left',
    headerName: '',
    field: 'action',
    suppressSizeToFit: true,
    suppressMenu: true,
    sortable: false,
    cellClass: 'ag-col-center',
    width: 90,
    cellRenderer: (cellProps: RowNode) => {
      const { md5, year, month } = cellProps.data;
      const fileUrl = `${config.apiUrl}/customers/statement-download?md5=${encodeURIComponent(md5)}`;

      const onView = () => window.open(fileUrl);

      const onDownload = () => {
        FileService.downloadDataUri(
          `${fileUrl}&download=1&year=${year}&month=${month}`,
          `statement-${year}-${month}.pdf`
        );
      };

      return (
        <ButtonGroup className="list-row-toolbar">
          <AsyncButton timeout={5000} className="btn-xs" onClick={onView} title="View">
            <i className="fas fa-list" />
          </AsyncButton>
          <AsyncButton timeout={5000} className="btn-xs" onClick={onDownload} title="Download">
            <i className="fas fa-download" />
          </AsyncButton>
        </ButtonGroup>
      );
    },
  },
  {
    headerName: 'Month',
    field: 'month',
    sort: 'desc',
    sortIndex: 1,
    valueFormatter: (params: any) => {
      const date = new Date(0, params.value - 1); // Year set to 0, month is zero-based (0 = January)
      return date.toLocaleString('default', { month: 'long' }); // Format to full month name
    },
  },
  { headerName: 'Year', field: 'year', sort: 'desc', sortIndex: 0 },
  { headerName: '', field: 'md5', hide: true },
];

const CustomerStatementList = () => {
  const [rowData, setRowData] = useState<Record<string, any>[]>();
  const [error, setError] = useState('');

  useEffect(() => {
    Api.get('customers/statements')
      .then((res) => {
        const filteredData: Record<string, any>[] = Object.values(
          (res.data.statements || []).reduce((acc: any, current: any) => {
            // Create a unique key for each month-year combination
            const key = `${current.year}-${current.month}`;

            // Check if this month-year key already exists
            if (!acc[key] || acc[key].revision < current.revision) {
              // If it doesn't exist or if current has a higher revision, update the entry
              acc[key] = current;
            }

            return acc;
          }, {})
        );

        setRowData(filteredData);
      })
      .catch(() => {
        setError('Coming Soon');
      });
  }, []);

  if (error) {
    return <ErrorPage message={error} />;
  }

  if (!rowData) {
    return <LoadingPage />;
  }

  if (!rowData.length) {
    return <ErrorPage>No Statements Found</ErrorPage>;
  }

  return (
    <>
      <ListView canAdd={false} canDelete={false} canEdit={false} columnDefs={columnDefs} rowData={rowData}>
        <ListViewToolbar canExport header="Statements" />
      </ListView>
    </>
  );
};

export default CustomerStatementList;

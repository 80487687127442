import { Button, Form } from 'react-bootstrap';
import { CartItem } from './part.types';
import { usePartsStore } from './part.store';
import PartItemIcon from './PartItemIcon';
import DisplayValue from '../DisplayValue';
import React, { useState } from 'react';

interface Props {
  item: CartItem;
  onRemove: (item: CartItem) => void;
  onQuanUpdate: (item: CartItem, quan: number) => void;
  onCommentUpdate: (item: CartItem, comment: string) => void;
}

const PartCartItem = ({ item, onRemove, onQuanUpdate, onCommentUpdate }: Props) => {
  const { getUnitPrice } = usePartsStore();
  const [editComment, setEditComment] = useState(false);

  const renderIcon = () => {
    if (item.images && item.images.length) {
      return <PartItemIcon partNumber={item.partno} description={item.desc} images={item.images} />;
    }

    return <i className="fas fa-image" />;
  };

  const getCorePrice = () => {
    if (!parseInt(item.core, 10)) {
      return null;
    }

    return (
      <div>
        {item.quan > 1 && (
          <DisplayValue label="Core Unit Price">
            $
            {Number(item.core).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </DisplayValue>
        )}
        <DisplayValue label="Core Price">
          $
          {(Number(item.core) * item.quan).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </DisplayValue>
      </div>
    );
  };

  const handleEditComment = () => {
    setEditComment(true);
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onCommentUpdate(item, e.target.value);
  };

  const handleCommentKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setEditComment(false);
    }
  };

  const renderComment = () => {
    if (editComment) {
      return (
        <input
          autoFocus
          type="text"
          value={item.comment}
          onChange={handleCommentChange}
          onKeyDown={handleCommentKey}
          onBlur={() => setEditComment(false)}
        />
      );
    }

    const commentLabel = item.comment ? 'Comment' : 'Add Comment';

    return (
      <DisplayValue label={commentLabel} onClick={handleEditComment} style={{ cursor: 'pointer' }}>
        {item.comment}
      </DisplayValue>
    );
  };

  return (
    <div className="cart-item slideInUp">
      <div className="cart-item-controls">
        <div>
          <Button variant="danger" size="sm" onClick={() => onRemove(item)}>
            <i className="fas fa-times" />
          </Button>
        </div>
      </div>
      <div className="cart-item-image">{renderIcon()}</div>
      <div className="cart-item-desc">
        <DisplayValue label="Item #">{item.partno}</DisplayValue>
        <DisplayValue label="Description">{item.desc}</DisplayValue>
        <div className="display-value-inline">
          {item.weight && <DisplayValue label="Weight">{item.weight}lb</DisplayValue>}
          {item.upceach && <DisplayValue label="UPC">{item.upceach}</DisplayValue>}
        </div>
        {renderComment()}
        <div />
      </div>
      <div className="cart-item-amounts">
        <div className="cart-item-price">
          <div className="cart-item-qty">
            <Form.Group>
              <Form.Label>Qty</Form.Label>
              <Form.Control
                type="number"
                value={item.quan}
                onChange={(e: any) => onQuanUpdate(item, Number(e.target.value))}
              />
            </Form.Group>
          </div>
          <div className="part-item-price-wrapper">
            <div>
              {item.quan > 1 && (
                <DisplayValue label="Unit Price">
                  $
                  {getUnitPrice(item, item.quan).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </DisplayValue>
              )}
              <DisplayValue label="Price">
                $
                {(getUnitPrice(item, item.quan) * item.quan).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </DisplayValue>
            </div>
            <div>{getCorePrice()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartCartItem;

import { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

const borderCss = '1px solid #CCC';

/**
 * This will render as part of a callback route from WorldNet
 * It will display in an iframe after a CC transaction
 *
 * Example URL:
 * http://portal.localhost:3001/worldnet/receipt?DL_NUMBER=&DL_STATE=&STATE=&ORDERID=1GXWJVB3LRAPS-L79CECG6&AMOUNT=636.37&REGION=&TERMINALID=5028002&CITY=&RESPONSETEXT=APPROVAL&RESPONSECODE=A&APPROVALCODE=951629&CVVRESPONSE=M&DATETIME=2022-08-25T13:52:31&UNIQUEREF=HLPYLZ2UQE&EMAIL=&CARDTYPE=MASTERCARD&CARDNUMBER=515977******5606&HASH=xxx
 */
const WorldNetReceipt = () => {
  const [searchParams] = useSearchParams();
  const qsParams = Object.fromEntries(searchParams.entries());

  if (!qsParams.RESPONSECODE) {
    console.error('Missing RESPONSECODE in query string');
    console.error(qsParams);
    return <div />;
  }

  const success = qsParams.RESPONSECODE === 'A';
  const textStyle = success ? 'text-success' : 'text-danger';

  const amount = Number(qsParams.AMOUNT.replace(/[^0-9\.]+/g, ''));
  const surcharge = Number((qsParams.SURCHARGE_FEE || '0').replace(/[^0-9\.]+/g, ''));

  const getTotal = () => {
    return Number((amount + surcharge).toFixed(10)).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  const transactionMessage = success ? 'Transaction Approved' : 'Transaction Declined';
  const topBorderColor = success ? '#4caf50' : '#f44336';

  useEffect(() => {
    window.top?.postMessage(qsParams, '*');
  }, []);

  return (
    <>
      <div
        className="mt-5 container pt-3 mb-3"
        style={{
          maxWidth: '600px',
          borderBottom: borderCss,
          borderLeft: borderCss,
          borderRight: borderCss,
          borderRadius: '5px',
          borderTop: `5px solid ${topBorderColor}`,
        }}
      >
        <h1 className={`text-center ${textStyle} text-bold mb-3`}>{transactionMessage}!</h1>
        <Table>
          <tbody>
            <tr>
              <td>Order Confirmation #</td>
              <td className="text-right">{qsParams.ORDERID}</td>
            </tr>
            <tr>
              <td>Payment Method</td>
              <td className="text-right">
                {qsParams.CARDTYPE} {qsParams.CARDNUMBER}
              </td>
            </tr>
            <tr>
              <td>Process Date</td>
              <td className="text-right">{qsParams.DATETIME}</td>
            </tr>
            {surcharge > 0 && (
              <tr>
                <td>
                  Surcharge
                  <span className="ml-2" style={{ fontSize: '10px' }}>
                    {qsParams.SURCHARGE_PERCENT}%
                  </span>
                </td>
                <td className="text-right">
                  {Number(surcharge).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </td>
              </tr>
            )}
            <tr className="text-bold">
              <td>Total</td>
              <td className="text-right">{getTotal()}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      {success && <div className="text-center">A receipt will be emailed to you.</div>}
    </>
  );
};

export default WorldNetReceipt;

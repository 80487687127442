import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const WorldNetTokenResponse = () => {
  const [searchParams] = useSearchParams();
  const qsParams = Object.fromEntries(searchParams.entries());

  useEffect(() => {
    if (Object.keys(qsParams).length === 0) return;

    // pass query params to parent window
    window.top?.postMessage({ source: 'WorldNetTokenResponse', payload: qsParams }, '*');
  }, []);

  return <div />;
};

export default WorldNetTokenResponse;

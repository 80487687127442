import React, { useState } from 'react';
import classNames from 'classnames';
import BarcodeButton from '../BarcodeButton';
/* @ts-ignore my module declare file doesn't work for reasons unknown, TODO TS blaze-camera */
import BlazeCamera from '@dsisolutions/blaze-camera';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import PartService from '../../services/PartService';
import { toast } from 'react-toastify';
import { OrderFormLineItemData } from './order.types';

interface OrderFormLineItemProps extends OrderFormLineItemData {
  canRemove?: boolean;
  onChange: (x: Record<string, any>) => any;
  onRemove: () => void;
}

interface Image {
  data: string;
  type: string;
}

const QuickOrderFormLineItem = ({
  customerNum,
  branchId,
  quantity,
  partNum,
  image,
  exists,
  onChange,
  onRemove,
  canRemove,
}: OrderFormLineItemProps) => {
  const [cameraMode, setCameraMode] = useState(false);

  const handleCameraComplete = (images: Image[]) => {
    setCameraMode(false);

    if (!images || !images.length) {
      return;
    }

    onChange({ image: images[0].data });
  };

  const handleCameraError = (err: Error) => {
    toast.error('Something went wrong interfacing with the camera');
    console.error(err);
    setCameraMode(false);
  };

  if (cameraMode) {
    return <BlazeCamera onComplete={handleCameraComplete} onError={handleCameraError} />;
  }

  const handlePartChange = (value: string) => {
    const newValues: any = { partNum: value };
    if (exists !== undefined) {
      // reset exists if set
      newValues.exists = undefined;
    }

    onChange(newValues);
  };

  const handlePartBlur = (value: string) => {
    if (!value.trim()) {
      onChange({ exists: undefined, partData: null });
      return;
    }

    PartService.stockCheck({
      partNumber: value,
      lineCode: '',
      quantity: 1,
      accountNumber: customerNum,
      branchId,
    }).then((data) => {
      if (data.errcode !== 'success') {
        toast.error(data.errmsg);
        onChange({ exists: false, partData: null });
      } else {
        onChange({ exists: true, partData: data });
      }
    });
  };

  return (
    <Row className="mb-2">
      <Col xs={2} md={2}>
        <Form.Control
          placeholder="Qty"
          value={quantity}
          onChange={(e) => onChange({ quantity: Number(e.target.value) || '' })}
          type="number"
          style={{ textAlign: 'right' }}
        />
      </Col>
      <Col>
        <InputGroup>
          <Form.Control
            placeholder="Part Number"
            value={partNum}
            onChange={(e) => handlePartChange(e.target.value)}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => handlePartBlur(e.target.value)}
            className={classNames({
              'text-danger': exists === false,
              'text-success': exists === true,
            })}
          />
          <InputGroup.Append>
            <BarcodeButton onScan={(scannedValue) => onChange({ partNum: scannedValue })} />
          </InputGroup.Append>
        </InputGroup>
      </Col>
      <Col xs={2} md={2} className="d-none">
        {!!image && (
          <img
            onClick={() => setCameraMode(true)}
            src={image}
            style={{
              maxWidth: '60px',
              maxHeight: '60px',
              padding: '3px',
              cursor: 'pointer',
              border: '1px solid #DDD',
            }}
          />
        )}

        {!image && (
          <Button variant="default" onClick={() => setCameraMode(true)}>
            <i className="fa-solid fa-camera-retro" />
          </Button>
        )}
      </Col>
      <Col xs={2} md={2}>
        {canRemove && (
          <Button variant="danger" onClick={() => onRemove()}>
            <i className="fa-solid fa-close" />
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default QuickOrderFormLineItem;

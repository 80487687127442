import { useState, useEffect } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';

import '../styles/loading.scss';

const color = '#CCCCCC';

interface Props {
  style?: object;
}

const LoadingPage = ({ style = {} }: Props) => {
  const [deferred, setDeferred] = useState(true);
  const [problem, setProblem] = useState(false);

  useEffect(() => {
    const timers: number[] = [];
    timers.push(
      setTimeout(() => {
        setDeferred(false);
      }, 200)
    );

    timers.push(
      setTimeout(() => {
        setProblem(true);
      }, 30 * 1000)
    );

    return () => {
      timers.forEach((timer) => clearTimeout(timer));
    };
  }, []);

  return (
    <div className="loading-wrapper" style={style}>
      <div className="loading-child">
        {!deferred && <ScaleLoader color={color} />}
        {problem && <div>This is taking longer than normal. Try to refresh your browser.</div>}
      </div>
    </div>
  );
};

export default LoadingPage;

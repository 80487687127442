import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Form from '../Form';
import { toast } from 'react-toastify';
import DbService, { DbRoutes } from '../../services/DbService';
import LoadingPage from '../LoadingPage';
import PageNavbar from '../PageNavbar';
import userAtom from '../../stores/user.store';
import { Button, Col, Row } from 'react-bootstrap';
import { modal } from '../modal/ModalEmitter';
import CustomerResetPassword from '../user/CustomerResetPassword';
import ErrorPage from '../ErrorPage';
import { useAtom } from 'jotai';
import { BlazeFormField } from 'blaze-form';
import { AxiosError } from 'axios';

interface Props {
  itemName: string;
  itemId: string | number;
  hideCancel?: boolean;
}

const DbEdit = ({ itemName, itemId, hideCancel = false }: Props) => {
  const [user] = useAtom(userAtom);
  const [layout, setLayout] = useState<BlazeFormField[]>([]);
  const [ready, setReady] = useState(false);
  const [routes, setRoutes] = useState<DbRoutes>();
  const [data, setData] = useState<Record<string, any>>();
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    DbService.getById(itemName, itemId)
      .then((data) => {
        data.layout = data.layout.filter((field) => {
          if (!field.meta) {
            return true;
          }

          if ((field.meta.updateOnly && itemId === '_new') || (field.meta.createOnly && itemId !== '_new')) {
            return false;
          }

          if (field.meta.adminOnly && !user.isAdmin) {
            return false;
          }

          return true;
        });

        setData(data.data);
        setRoutes(data.routes);
        setLayout(data.layout);
        setReady(true);
      })
      .catch((err) => setError(err.message));
  }, []);

  const customActions = () => {
    if (itemName === 'customer' && itemId !== '_new' && data) {
      return (
        <Button
          variant="warning"
          onClick={() => {
            modal.open({
              title: 'Reset Password',
              headerClass: 'primary',
              hideFooter: true,
              body: <CustomerResetPassword userId={Number(data.user.id)} />,
            });
          }}
        >
          Reset Password
        </Button>
      );
    }

    return null;
  };

  const redirectUrl = itemName === 'company' && !user.isAdmin ? '/' : `/db/${itemName}`;

  const handleSubmit = (data: Record<string, any>) => {
    const getErrorMessage = (err: AxiosError) => {
      return _.get(err, 'response.data.error', 'Application Error');
    };

    if (!routes) {
      console.error('No routes found');
      return;
    }

    if (itemId === '_new') {
      DbService.create(routes.create, data)
        .then(() => {
          toast(`Created ${itemName}`);
          navigate(redirectUrl);
        })
        .catch((err) => {
          console.error(err);
          toast(getErrorMessage(err));
        });
    } else {
      DbService.updateById(routes.update, itemId, data)
        .then(() => {
          toast(`Updated ${itemName}`);
          navigate(redirectUrl);
        })
        .catch((err) => {
          console.error(err);
          toast(getErrorMessage(err));
        });
    }
  };

  const handleCancel = () => {
    navigate(redirectUrl);
  };

  const handleChange = () => {};

  const getHeader = () => {
    if (itemId === '_new') {
      return `New ${_.capitalize(itemName)}`;
    }

    return `Edit ${_.capitalize(itemName)}`;
  };

  if (error) {
    return <ErrorPage message={error} />;
  }

  if (!ready) {
    return <LoadingPage />;
  }

  return (
    <>
      <PageNavbar header={getHeader()} />
      <div className="pad-top pad-sides">
        <div className="form-wrap shadow">
          <Form
            layout={layout}
            showHelp
            onChange={handleChange}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            submitAll
            hideToolbar
          >
            <div className="p-3">
              <Row>
                <Col md={6}>
                  <Button data-blaze-submit variant="primary" className="form-edit-submit">
                    Submit
                  </Button>
                </Col>
                <Col md={6} className="text-right">
                  {customActions()}
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default DbEdit;

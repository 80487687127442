import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import Db, { DbRoutes } from '../../services/DbService';
import ListView from '../list/ListView';
import LoadingPage from '../LoadingPage';
import ListViewToolbar from '../list/ListViewToolbar';
import { modal } from '../modal/ModalEmitter';
import userAtom from '../../stores/user.store';
import Config from '../../config';
import { ColDef } from '@ag-grid-community/core';

interface Props {
  itemName: string;
  filter?: any[];
  onFilter?: (dataObject: Record<string, any>) => any;
  children?: JSX.Element | string;
}

const DbList = ({ itemName, filter: defaultFilter, onFilter, children }: Props) => {
  const [user] = useAtom(userAtom);
  const [routes, setRoutes] = useState<DbRoutes>();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const [rowData, setRowData] = useState<Record<string, any>[]>([]);
  const [ready, setReady] = useState(false);
  const [canAdd, setCanAdd] = useState(true);
  const [canEdit, setCanEdit] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [header, setHeader] = useState(itemName);
  const [filterParams, setFilterParams] = useState<Record<string, any>>({});
  const [filter, setFilter] = useState(defaultFilter || []);

  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, [itemName]);

  const getData = () => {
    setReady(false);
    Db.getAll(itemName)
      .then((res) => {
        setRowData(res.data);
        setRoutes(res.routes);
        setColumnDefs(processColumnDefs(res.columnDefs));
        setCanAdd(res.listProps.canAdd);
        setCanEdit(res.listProps.canEdit);
        setCanDelete(res.listProps.canDelete);
        setHeader(res.listProps.header || _.upperFirst(itemName));
        setReady(true);
      })
      .catch(console.error);
  };

  const processColumnDefs = (colDefs: ColDef[]) => {
    return colDefs
      .filter((col) => !(_.get(col, 'meta.adminOnly') && !user.isAdmin))
      .map((col) => _.omit(col, ['meta']));
  };

  const handleAddNew = () => {
    navigate(`/db/${itemName}/_new`);
  };

  const handleDelete = (itemName: string, itemId: string | number) => {
    if (!routes?.delete) {
      console.error(`No delete route for ${itemName}`);
      return;
    }

    modal.confirm({
      title: `Delete ${itemName}:${itemId}?`,
      onOk: () => {
        Db.deleteById(routes.delete, itemId)
          .then(() => getData())
          .catch(console.error);
      },
    });
  };

  const handleRestore = (itemName: string, itemId: string | number) => {
    Db.restoreById(itemName, itemId)
      .then(() => getData())
      .catch(console.error);
  };

  if (!ready) {
    return <LoadingPage />;
  }

  // if (!rowData.length) {
  //   return <ErrorPage>No Results Found</ErrorPage>;
  // }

  const handleExport =
    itemName === 'order'
      ? () => {
          const searchParams = new URLSearchParams({ export: '1', ...filterParams }).toString();
          window.location.href = `${Config.apiUrl}/orders?${searchParams}`;
        }
      : undefined;

  const handleFilter = async (data: Record<string, any>) => {
    const newFilter = [];
    for (const entry of filter) {
      newFilter.push({ ...entry, data: data[entry.name] });
    }
    setFilter(newFilter);
    setFilterParams(data);
    Db.getAll(itemName, data).then((res) => {
      setRowData(res.data);
    });
    // if (onFilter) {
    //   const filterParams = onFilter(searchText);
    // }
  };

  return (
    <ListView
      canAdd={canAdd}
      canDelete={canDelete}
      canEdit={canEdit}
      columnDefs={columnDefs}
      itemName={itemName}
      onDelete={handleDelete}
      onRestore={handleRestore}
      rowData={rowData}
    >
      <ListViewToolbar
        canExport
        filter={filter}
        onExport={handleExport}
        onFilter={handleFilter}
        header={header}
        onAddNew={canAdd ? handleAddNew : undefined}
      />
      {children}
    </ListView>
  );
};

export default DbList;

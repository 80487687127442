import { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import Api from '../../services/ApiService';

interface Props {
  onSubmit: (data: any) => void;
  onCancel: () => void;
}

const CartUploadForm = ({ onSubmit, onCancel }: Props) => {
  const onDrop = useCallback((files: any) => {
    const reader = new FileReader();

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = () => {
      // Do whatever you want with the file contents
      Api.post('parts/upload', { data: reader.result }).then(({ data }) => {
        onSubmit(data);
      });
    };

    reader.readAsBinaryString(files[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <h4>Upload a list of parts in comma separated values (CSV) format.</h4>
      <div>Example</div>
      <pre>
        "PartNumber1","Source1","Quantity1"
        <br />
        "PartNumber2","Source2","Quantity2"
        <br />
        "PartNumber2","","Quantity2"
        <br />
      </pre>

      <ul>
        <li>Source is also referred to as line code. Leave blank if unknown.</li>
        <li>You can export CSV from Excel or similar spreadsheet applications.</li>
        <li>Omit column headers.</li>
      </ul>

      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? <p>Drop the files here ...</p> : <Button>Upload File</Button>}
      </div>

      <div className="text-right">
        <Button onClick={onCancel} className="pull-right">
          Cancel
        </Button>
      </div>
    </>
  );
};

export default CartUploadForm;

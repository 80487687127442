import React, { useRef, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { ColumnApi, GridApi, GridOptions, ICellRendererParams, RowDataUpdatedEvent } from '@ag-grid-community/core';

import { ListAtomFamily } from './ListState';
import Api from '../../services/ApiService';
import ListRowToolbar from './ListRowToolbar';
import AuthService from '../../services/AuthService';
import { modal } from '../modal/ModalEmitter';
import userAtom from '../../stores/user.store';
import LoadingPage from '../LoadingPage';
import { companyAtom } from '../../stores/root.store';
import { currency } from '../../util';
import config from '../../config';
import '../../styles/listview.scss';

const store = {
  set: (key: string, value: any) => {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (err) {
      console.error(err);
    }
  },

  get: (key: string) => {
    try {
      return JSON.parse(sessionStorage.getItem(key) || '');
    } catch (err) {
      return null;
    }
  },
};

// typescript workaround
const lazy = React.lazy;
const Suspense = React.Suspense;

const AgGridReact = lazy(() => import('../AgGridContainer'));

interface Props {
  canAdd?: boolean;
  canCopy?: boolean;
  canDelete?: boolean;
  canEdit?: boolean;
  children?: any;
  columnDefs: object[];
  gridOptions?: object;
  idFieldName?: string;
  itemName?: string;
  onDelete?: (itemName: string, itemId: string | number) => void;
  onRestore?: (itemName: string, itemId: string | number) => void;
  onRowClicked?: (rowNode: any) => void;
  onUpdated?: (model: any) => void;
  resizeOnUpdate?: boolean;
  rowData: Record<string, any>[];
  sizeColumnsToFit?: boolean;
}

export const ListViewContext = React.createContext<{ agGrid: React.RefObject<{ api: GridApi }> }>({
  agGrid: { current: null },
});

interface AgGridRef {
  api: GridApi;
  columnApi: ColumnApi;
}

const ListView = ({
  canAdd = false,
  canCopy = false,
  canDelete = false,
  canEdit = false,
  children,
  columnDefs = [],
  gridOptions: gridOptionsProp = {},
  idFieldName = 'id',
  itemName = '',
  onDelete = () => null,
  onRestore = () => null,
  onRowClicked = () => null,
  onUpdated = (model) => null,
  sizeColumnsToFit = false,
  resizeOnUpdate = true,
  rowData = [],
}: Props) => {
  const agGrid = useRef<AgGridRef>();
  const gridRestored = useRef(false);
  const navigate = useNavigate();

  const [user] = useAtom(userAtom);
  const [company] = useAtom(companyAtom);
  const [gridReady, setGridReady] = useState(false);
  const [gridState, setGridState] = useAtom(ListAtomFamily(window.location.pathname));

  const { filterText } = gridState;
  const gridPropsKey = `list.props:${itemName}`;

  const defaultGridOptions = {
    rowHeight: 48,

    defaultColDef: {
      sortable: true,
      filter: true,
      resizable: true,
    },

    enableCellTextSelection: true,

    columnTypes: {
      deliveryMethodColumn: {
        cellRenderer: (data: ICellRendererParams) => {
          if (company.shippingMethods) {
            for (const method of company.shippingMethods) {
              if (method.code === data.value) {
                return method.label;
              }
            }
          }

          return data.value;
        },
      },
      pickTicketColumn: {
        cellRenderer: (data: ICellRendererParams) => data.value.toString().padStart(4, '0'),
      },
      timestampColumn: {
        cellRenderer: (data: ICellRendererParams) => (data.value ? dayjs(data.value).format('MMM, D YYYY h:mm a') : ''),
      },
      pennyColumn: {
        cellRenderer: (data: ICellRendererParams) => currency(data.value / 100),
      },
      currency: {
        cellRenderer: (data: ICellRendererParams) => currency(Number(data.value)),
      },
      dateColumn: {
        cellRenderer: (data: ICellRendererParams) => new Date(data.value).toLocaleDateString(),
      },
      flakeIdColumn: {
        cellRenderer: (data: ICellRendererParams) => data.value,
      },
    },
  };

  const gridOptions: GridOptions = { ...defaultGridOptions, ...gridOptionsProp };

  useEffect(() => {
    const beforePrint = () => {
      if (rowData.length > 500) {
        window.confirm('List too big! It will not print correctly.');
        return false;
      }

      if (agGrid.current) {
        agGrid.current.api.setDomLayout('print');
      }
    };

    const afterPrint = () => {
      if (agGrid.current) {
        agGrid.current.api.setDomLayout();
      }
    };

    window.addEventListener('beforeprint', beforePrint);
    window.addEventListener('afterprint', afterPrint);
    return () => {
      window.removeEventListener('beforeprint', beforePrint);
      window.removeEventListener('afterprint', afterPrint);
    };
  }, []);

  const restoreGridState = () => {
    if (!agGrid.current || !itemName) {
      // don't save before restore or with lists without an itemName
      return;
    }

    const { columnApi, api } = agGrid.current;
    const { colState, groupState, sortState, filterState } = store.get(gridPropsKey) || {};
    colState && columnApi.applyColumnState({ state: colState });
    groupState && columnApi.setColumnGroupState(groupState);
    filterState && api.setFilterModel(filterState);
    gridRestored.current = true;
  };

  const onGridReady = () => {
    setTimeout(() => restoreGridState(), 10);
    setTimeout(() => setGridReady(true), 100); // minimize jank on load
    resizeGrid();
  };

  const onGridSizeChanged = () => {
    resizeGrid();
  };

  const saveGridState = () => {
    if (!gridRestored.current || !agGrid.current || !itemName) {
      // don't save before restore or lists without an itemName
      return;
    }

    const { columnApi, api } = agGrid.current;

    store.set(gridPropsKey, {
      colState: columnApi.getColumnState(),
      groupState: columnApi.getColumnGroupState(),
      filterState: api.getFilterModel(),
      filterText,
    });
  };

  const handleUpdated = (model: RowDataUpdatedEvent) => {
    if (gridState.resultCount !== model.api.getModel().getRowCount()) {
      setGridState((state) => {
        return { ...state, resultCount: model.api.getModel().getRowCount() };
      });
    }

    saveGridState();
    if (resizeOnUpdate) {
      setTimeout(resizeGrid, 100);
    }
    return onUpdated(model);
  };

  const getSwitchUserButton = (userId: number | string) => (
    <Button
      key="switch-user"
      title="Switch To User"
      variant="warning"
      className="btn-xs"
      onClick={() => {
        modal.confirm({
          title: `Switch to user ${userId}?`,
          body: 'This will log you out from current user.',
          onOk: () => {
            Api.put(`users/${userId}/su`).then(({ data }) => {
              const { token, hostname } = data;
              AuthService.setToken(token);
              window.location.href = hostname ? hostname : '/';
            });
          },
        });
      }}
    >
      <i className="fas fa-user-friends" />
    </Button>
  );

  const getCustomButtons = () => {
    const buttons = [];

    if (itemName === 'customer') {
      buttons.push((itemId: string) => (
        <Button
          key="account-status"
          className="btn-xs"
          title="Refresh Account Status"
          onClick={() => Api.post(`customers/${itemId}/account`).then(() => toast('Customer account status updated'))}
        >
          <i className="fas fa-sync" />
        </Button>
      ));

      buttons.push((itemId: string, rowData: any) => {
        if (!rowData || !rowData.UserId) {
          return null;
        }

        return getSwitchUserButton(rowData.UserId);
      });
    } else if (itemName === 'dealer') {
      buttons.push((itemId: string, rowData: any) => {
        if (!rowData || !rowData.UserId) {
          return null;
        }

        return getSwitchUserButton(rowData.UserId);
      });
    } else if (itemName === 'user') {
      buttons.push((itemId: string) => getSwitchUserButton(itemId));
    } else if (itemName === 'company' && user.isAdmin) {
      buttons.push((itemId: string) => (
        <Button
          key="setup-company"
          className="btn-xs"
          title="Setup Company"
          variant="warning"
          onClick={() => Api.post(`companies/${itemId}/setup`).then(() => toast('Company Setup'))}
        >
          <i className="fas fa-sync" />
        </Button>
      ));

      buttons.push((_: string, rowData: any) => (
        <Button
          key="goto-company"
          className="btn-xs"
          title="Go To Company Portal"
          variant="info"
          onClick={() => {
            if (!!config.getCompanyUrl) {
              location.href = config.getCompanyUrl(rowData.subdomain);
            }
          }}
        >
          <i className="fa-solid fa-arrow-up-right-from-square" />
        </Button>
      ));
    } else if (itemName === 'order') {
      buttons.push((itemId: number | string) => (
        <Button
          key="order-details"
          className="btn-xs"
          title="Order Details"
          onClick={() => navigate(`/orders/${itemId}`)}
        >
          <i className="fas fa-search" />
        </Button>
      ));
    }

    return buttons;
  };

  const getColumnDefs = () => {
    const customButtons = getCustomButtons();

    if (canEdit || canDelete || customButtons.length) {
      // figure out what the width of columns should be based on the number of buttons
      const width = 15 + (customButtons.length + [canEdit, canCopy, canDelete].filter((op) => op).length) * 28;
      const newCols: object[] = [];

      newCols.push({
        width,
        pinned: 'left',
        headerName: '',
        field: 'action',
        suppressSizeToFit: true,
        suppressMenu: true,
        sortable: false,
        cellClass: 'ag-col-center',
        cellRenderer: (params: any) => {
          return (
            <ListRowToolbar
              itemId={String(params.data[idFieldName])}
              itemName={itemName}
              canAdd={canAdd}
              canDelete={canDelete}
              canCopy={canCopy}
              canEdit={canEdit}
              canRestore={user.isAdmin && params.data.deletedAt}
              onDelete={onDelete}
              onRestore={onRestore}
              customButtons={customButtons}
              rowData={params.data}
            />
          );
        },
      });

      return newCols.concat(columnDefs);
    }

    return columnDefs;
  };

  const resizeGrid = () => {
    if (!agGrid.current) {
      return;
    }

    agGrid.current.columnApi.autoSizeAllColumns();

    if (sizeColumnsToFit) {
      agGrid.current.api.sizeColumnsToFit();
    }
  };

  const wrapperClasses = classNames({
    'grid-wrapper': true,
    'ag-theme-balham': true,
    transparent: !gridReady,
    'fade-in': gridReady,
  });

  return (
    /* @ts-ignore */
    <ListViewContext.Provider value={{ agGrid }}>
      {children}
      <div className="list-container content-container">
        <div className={wrapperClasses}>
          <Suspense fallback={<LoadingPage />}>
            <AgGridReact
              columnDefs={getColumnDefs()}
              gridOptions={gridOptions}
              onGridReady={onGridReady}
              onGridSizeChanged={onGridSizeChanged}
              onModelUpdated={handleUpdated}
              onRowClicked={onRowClicked}
              overlayNoRowsTemplate="Empty"
              quickFilterText={filterText}
              ref={agGrid as any}
              rowData={rowData}
            />
          </Suspense>
        </div>
      </div>
    </ListViewContext.Provider>
  );
};

export default ListView;

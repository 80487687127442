import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

const validTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];

const LogoUpload = () => {
  const [logo, setLogo] = useState('');

  const onDrop = useCallback((files: any) => {
    // Just take last file in the list
    const file = files.shift();
    if (!validTypes.includes(file.type)) {
      toast('Upload failed: Invalid image type');
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const blob = String(reader.result);
      setLogo(blob);
    };

    reader.readAsDataURL(file);
  }, []);

  const renderPreview = () => {
    const source = logo;

    return (
      <div className="preview" title="Preview">
        {/* <img className="bg" src={source} alt="preview" /> */}
        <img className="fg" src={source} width="75px" alt="thumbnail" />
      </div>
    );
  };

  const renderEmpty = () => {
    if (isDragActive) {
      return <p>Drop here ...</p>;
    }

    return <p>Drag or click to Upload</p>;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="logo-upload" {...getRootProps()}>
      <input {...getInputProps()} />
      {logo ? renderPreview() : renderEmpty()}
    </div>
  );
};

export default LogoUpload;

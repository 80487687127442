import React, { useEffect, useState } from 'react';
import { useTable, useSortBy, Column, CellProps } from 'react-table';
import config from '../../config';
import Api from '../../services/ApiService';
import ErrorPage from '../ErrorPage';
import InvoiceDownloadButton from '../InvoiceDownloadButton';
import LoadingPage from '../LoadingPage';

interface RepairOperation {
  opNum: string;
  opDetail: string;
}

interface RepairOrder {
  status: 'Open' | 'Closed';
  repairOrder: string;
  mileage: string;
  dateClosed: string;
  operations: RepairOperation[];
}

const VehicleHistory = ({ vin }: { vin: string }) => {
  const [repairs, setRepairs] = useState<RepairOrder[]>();

  const repairData = React.useMemo(() => repairs, [repairs]);

  const handleViewRepairOrder = (roNum: string) => {
    window.open(`${config.apiUrl}/invoices/${roNum}?type=ro`);
  };

  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: 'Repair #',
        accessor: 'repairOrder', // accessor is the "key" in the data
        Cell: ({ row }: CellProps<any>) => (
          <>
            <span className="link mr-2" onClick={() => handleViewRepairOrder(row.values.repairOrder)}>
              {row.values.repairOrder}
            </span>
            <InvoiceDownloadButton type="ro" docId={row.values.repairOrder} />
          </>
        ),
      },

      {
        Header: 'Closed Date',
        accessor: 'dateClosed',
      },
      {
        Header: 'Mileage',
        accessor: 'mileage',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Operations',
        accessor: 'operations',
      },
    ],
    []
  );

  useEffect(() => {
    Api.get(`vehicles/${vin}/service`).then(({ data }) => setRepairs(data.repairOrders));
  }, [vin]);

  const tableInstance = useTable(
    { columns, data: repairData || [], initialState: { hiddenColumns: ['operations'] } },
    useSortBy
  );

  const renderOperations = (operations: RepairOperation[]) => {
    if (!operations || !operations.length) {
      return null;
    }

    return operations.map((operation: RepairOperation, j: number) => (
      <div key={j}>
        <div>
          {j + 1}: {operation.opDetail}
        </div>
      </div>
    ));
  };

  if (!repairs) {
    return <LoadingPage />;
  }

  if (!repairs.length) {
    return <ErrorPage>No Repair Orders Found</ErrorPage>;
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, visibleColumns } = tableInstance;

  return (
    <table {...getTableProps()} className="table table-bordered">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, key) => {
          prepareRow(row);
          return (
            <>
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (cell.column.id === 'operations') {
                    return;
                  }
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
              <tr key={key}>
                <td colSpan={visibleColumns.length}>{renderOperations(row.values.operations)}</td>
              </tr>
            </>
          );
        })}
      </tbody>
    </table>
  );
};

export default VehicleHistory;

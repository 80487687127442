import { useEffect } from 'react';
import Api from '../services/ApiService';

const Test = () => {
  useEffect(() => {
    Api.get('order-report/sales-sum', { params: { byBranch: true, days: 7 } });
  });
  return <div />;
};

export default Test;

import BlazeForm from 'blaze-form';
import Api from '../../services/ApiService';

interface Props {
  vin: string;
  make?: string;
  model?: string;
  year?: string;
  mileage?: number;
  unitNumber: string;
  onCancel: () => void;
  onSubmit: () => void;
}

const VehicleUpdate = ({ vin, make, model, year, mileage, unitNumber, onCancel, onSubmit }: Props) => {
  const handleSubmit = (data: { mileage: string; unitNumber: string }) => {
    Api.put(`vehicles/${vin}`, data).then(() => {
      // pop toaster or something, close modal?
      onSubmit();
    });
  };

  const layout = [
    {
      label: 'Update Mileage',
      name: 'mileage',
      required: true,
      data: mileage,
      col: { sm: 6, md: 6, lg: 6 },
    },
    {
      label: 'Update Unit Number',
      name: 'unitNumber',
      data: unitNumber,
      col: { sm: 6, md: 6, lg: 6 },
    },
  ];

  return (
    <>
      <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
        {year} {make} {model}
      </div>
      <div style={{ fontSize: '16px' }}>VIN: {vin}</div>
      <hr />
      <BlazeForm submitLabel="Save" layout={layout} onSubmit={handleSubmit} onCancel={onCancel} submitAll />
    </>
  );
};

export default VehicleUpdate;

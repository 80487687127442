import _ from 'lodash';
import React, { ReactNode, useContext } from 'react';
import { Button } from 'react-bootstrap';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import PageNavbar from '../PageNavbar';
import MaterialSearch from '../MaterialSearch';
import { ListViewContext } from '../list/ListView';
import { ListAtomFamily } from '../list/ListState';
import { toast } from 'react-toastify';
import { modal } from '../modal/ModalEmitter';
import BlazeForm from 'blaze-form';
import { GridApi, ProcessCellForExportParams } from '@ag-grid-community/core';
import { LayoutItem } from 'blaze-validator';

interface Props {
  canExport?: boolean;
  filter?: LayoutItem[];
  onExport?: () => void;
  header: ReactNode | string;
  onAddNew?: (event: React.MouseEvent) => void;
  onFilter?: (filterParams: object) => void;
  onSearch?: (value: string) => void;
  children?: JSX.Element;
}

interface RenderButtonProps {
  title: string;
  icon: string;
  onClick?: (event: React.MouseEvent) => void;
  variant?: string;
}

const renderButton = ({ title, icon, onClick, variant = 'default' }: RenderButtonProps) => {
  return (
    <div className="thing">
      <Button variant={variant} title={title} onClick={onClick}>
        <span className="d-none d-sm-block">
          <i className={icon} /> {title}
        </span>
        <span className="d-block d-sm-none">
          <i className={icon} />
        </span>
      </Button>
    </div>
  );
};

const ListViewToolbar = ({
  canExport = false,
  onExport,
  header = '',
  onAddNew,
  onFilter,
  onSearch,
  filter,
  children,
}: Props) => {
  const { agGrid } = useContext<{ agGrid: React.RefObject<{ api: GridApi }> }>(ListViewContext);
  const [gridState, setGridState] = useAtom(ListAtomFamily(window.location.pathname));

  const handleSearch = (value: string) => {
    setGridState((state) => {
      return { ...state, filterText: value };
    });
    if (onSearch) {
      onSearch(value);
    }
  };

  const renderAddButton = () => {
    return renderButton({
      title: 'Add New',
      icon: 'fas fa-plus',
      onClick: !!onAddNew ? onAddNew : undefined,
      variant: 'success',
    });
  };

  const processCellCallback = (params: ProcessCellForExportParams) => {
    const valueType = params.column.getColDef().type;
    const { value } = params;

    switch (valueType) {
      case 'timestampColumn':
        return value ? dayjs(value).format('YYYY-MM-DD hh:mm:ss') : '';
      case 'pennyColumn':
        return value / 100;
      case 'flakeIdColumn':
        // this hack is so it's not displayed in scientific notation by excel
        return `ID-${value}`;
    }

    return value;
  };

  const renderExportButton = () => {
    return renderButton({
      title: 'Export',
      icon: 'fas fa-download',
      onClick: () => {
        if (onExport) {
          return onExport();
        }

        if (!agGrid || !agGrid.current) {
          toast.error('Unable to export');
          return;
        }

        const columnKeys = (agGrid.current.api.getColumnDefs() || [])
          .map((columnDef: any) => columnDef.field)
          .filter((key: string) => key !== 'action');

        const filePrefix = _.isString(header) && header ? header.toLowerCase().replace(/\s/g, '-') : 'export';

        agGrid.current.api.exportDataAsCsv({
          fileName: `portal-${filePrefix}-${dayjs().format('YYYY-MM-DD')}.csv`,
          columnKeys,
          processCellCallback,
        });
      },
    });
  };

  const renderFilterButton = () => {
    return renderButton({
      title: 'Filter',
      icon: 'fas fa-filter',
      onClick: () => {
        modal.open({
          title: 'Filter',
          hideFooter: true,
          body: (
            <BlazeForm
              layout={filter}
              submitAll
              onSubmit={(data) => {
                if (onFilter) {
                  onFilter(data);
                }
                modal.close();
              }}
              onCancel={() => modal.close()}
            />
          ),
        });
      },
    });
  };

  return (
    <PageNavbar header={header}>
      <div className="thing stretch">
        <MaterialSearch value={gridState.filterText} onChange={handleSearch} resultCount={gridState.resultCount} />
      </div>
      {filter && renderFilterButton()}
      {canExport && renderExportButton()}
      {!!onAddNew && renderAddButton()}
    </PageNavbar>
  );
};

export default ListViewToolbar;

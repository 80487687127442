import { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import Api from '../../services/ApiService';

interface OrderRow {
  customerId: number;
  name: string;
  num: string;
  orderCount: number;
  salesTotal: number;
}

const options = {
  // type: 'line',
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  stacked: false,
  scales: {
    countAxis: {
      type: 'linear' as const,
      display: true,
      position: 'left' as const,
    },
    sumAxis: {
      type: 'linear' as const,
      display: true,
      position: 'right' as const,
      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        callback: (value: string | number) =>
          Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
      },
    },
    xAxis: {
      ticks: {
        autoSkip: false,
        maxRotation: 90,
        minRotation: 45,
        fontSize: 9,
      },
    },
  },
};

const CustomerOrdersSum = () => {
  const [data, setData] = useState<OrderRow[] | null>();

  useEffect(() => {
    Api.get('/customer-report/top-sales', { params: { limit: 25 } }).then((response) => {
      setData(response.data);
    });
  }, []);

  if (!data) {
    return null;
  }

  const labels = data.map((x) => x.name || x.num);

  const sumSales: number[] = [];
  const numOrders: number[] = [];
  data.forEach((x) => {
    sumSales.push(x.salesTotal);
    numOrders.push(x.orderCount);
  });

  return (
    <Chart
      datasetIdKey="customer-top-sales"
      type="bar"
      options={options}
      data={{
        labels,
        datasets: [
          {
            type: 'line',
            label: 'Order Count',
            borderColor: 'rgb(255, 99, 132)',
            fill: false,
            borderWidth: 2,
            data: numOrders,
            yAxisID: 'countAxis',
            xAxisID: 'xAxis',
          },
          {
            type: 'bar',
            label: 'Order Sum $',
            backgroundColor: 'rgb(53, 162, 235)',
            data: sumSales,
            yAxisID: 'sumAxis',
            xAxisID: 'xAxis',
          },
        ],
      }}
    />
  );
};

export default CustomerOrdersSum;

import Api from './ApiService';
import { BlazeFormField } from 'blaze-form';
import format from 'format-util';

export interface DbRoutes {
  create: string;
  update: string;
  delete: string;
  restore: string;
}

interface ListProps {
  canAdd: boolean;
  canEdit: boolean;
  canDelete: boolean;
  header?: string;
}

export interface DbGetAllResponse {
  columnDefs: object[];
  data: Record<string, any>[];
  layout: Record<string, any>[];
  routes: DbRoutes;
  listProps: ListProps;
}

export interface DbGetByIdResponse {
  data: Record<string, any>[];
  layout: BlazeFormField[];
  routes: DbRoutes;
}

const DbService = {
  getAll: async (modelName: string, params?: object): Promise<DbGetAllResponse> => {
    return (await Api.get(`db/${modelName}`, { params })).data;
  },

  getById: async (modelName: string, id: string | number): Promise<DbGetByIdResponse> => {
    return (await Api.get(`db/${modelName}/${id}`)).data;
  },

  updateById: async (route: string, id: string | number, data: object): Promise<{}> => {
    return (await Api.put(format(route, id), data)).data;
  },

  create: async (route: string, data: object): Promise<{}> => {
    return (await Api.post(route, data)).data;
  },

  deleteById: async (route: string, id: string | number): Promise<{}> => {
    return (await Api.delete(format(route, id))).data;
  },

  restoreById: async (itemName: string, id: string | number): Promise<{}> => {
    return (await Api.put(`db/${itemName}/${id}/restore`)).data;
  },
};

export default DbService;

import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

interface Props {
  className?: string;
  title?: string;
  children: JSX.Element;
  onClick: (event: React.MouseEvent<HTMLElement>) => any;
  timeout?: number;
}

const AsyncButton = ({ children, onClick, timeout = 0, ...props }: Props) => {
  const [processing, setProcessing] = useState(false);

  const doOnClick = (event: React.MouseEvent<HTMLElement>) => {
    const p = onClick(event);
    if (p && p.then) {
      p.then(() => setProcessing(false));
    } else {
      setProcessing(false);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setProcessing(true);
    if (timeout) {
      setTimeout(() => {
        setProcessing(false);
      }, timeout);
      doOnClick(event);
      return;
    }

    doOnClick(event);
  };

  return (
    <Button onClick={handleClick} {...props} disabled={processing}>
      {children}
    </Button>
  );
};

export default AsyncButton;

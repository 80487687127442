const { location } = window;

interface Config {
  apiUrl?: string;
  domain?: string;
  getCompanyUrl?: (hostname: string) => string;
  google: {
    mapsKey: string;
  };
  publicUrl: (assetUrl: string) => string;
  serverUrl: string;
}

const config: Config = {
  google: {
    mapsKey: 'AIzaSyCV47YON9b0ySx4AamSM9FBuDuUUzH9s0I',
  },
  serverUrl: '',
  publicUrl: () => '',
};

if (import.meta.env.MODE === 'production') {
  config.domain = 'customers.solutions';
  config.serverUrl = `${location.protocol}//${window.location.hostname}`;
} else {
  config.domain = 'portal.localhost';
  if (location.protocol === 'https:') {
    config.serverUrl = `${location.protocol}//${window.location.hostname}`;
  } else {
    config.serverUrl = `${location.protocol}//${window.location.hostname}:4001`;
  }
}

config.apiUrl = `${config.serverUrl}/api`;

// Ref asset from public directory
config.publicUrl = (assetUrl: string) => `${import.meta.env.PUBLIC_URL || ''}/${assetUrl}`;

config.getCompanyUrl = (subdomain) => {
  if (import.meta.env.MODE === 'production') {
    return `${location.protocol}//${subdomain}.${config.domain}`;
  } else {
    if (location.protocol === 'https:') {
      return `${location.protocol}//${subdomain}.${config.domain}`;
    } else {
      return `${location.protocol}//${subdomain}.${config.domain}:${window.location.port}`;
    }
  }
};

export default config;

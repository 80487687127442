import _ from 'lodash';
import userAtom from '../../stores/user.store';
import { companyAtom } from '../../stores/root.store';
import { useAtom } from 'jotai';

export const useCustomerPermissions = () => {
  const [user] = useAtom(userAtom);
  const [company] = useAtom(companyAtom);

  return (permission: string) => {
    if (!user.isCustomer || !user.customer) {
      return true;
    }

    const companyPermission = _.get(company.permissions.customer, permission);
    // if company permission is negative, this takes priority
    if (!companyPermission) {
      return false;
    }

    return !!_.get(user.customer.permissions, permission);
  };
};

import { useEffect, useState } from 'react';
import LoadingPage from './LoadingPage';
import ListViewToolbar from './list/ListViewToolbar';
import ListView from './list/ListView';
import Api from '../services/ApiService';
import ErrorPage from './ErrorPage';

const columnDefs = [
  { headerName: 'Order Date', field: 'orderDate', sort: 'asc', type: 'dateColumn' },
  { headerName: 'Status', field: 'status' },
  { headerName: 'PO', field: 'customerPo' },
  { headerName: 'Item', field: 'partNumber' },
  { headerName: 'Description', field: 'partDescription' },
  { headerName: 'Qty', field: 'quantity', type: 'numericColumn' },
  { headerName: 'Cost', field: 'price', type: 'currency' },
  { headerName: 'Total', field: 'extendedPrice', type: 'currency' },
];

const BackOrders = () => {
  const [rowData, setRowData] = useState<Record<string, any>[]>();
  const [error, setError] = useState('');

  useEffect(() => {
    Api.get('customers/backorders')
      .then((res) => {
        setRowData(
          res.data.data.map((row: Record<string, any>) => {
            row.status = !row.poNumber.startsWith('PRE') ? 'Ordered' : '';
            row.quantity = parseInt(row.quantity);
            return row;
          })
        );
      })
      .catch(() => {
        setError('Coming Soon');
      });
  }, []);

  const handleRowClicked = () => {};

  if (error) {
    return <ErrorPage message={error} />;
  }

  if (!rowData) {
    return <LoadingPage />;
  }

  if (!rowData.length) {
    return <ErrorPage>No Back Orders Found</ErrorPage>;
  }

  return (
    <>
      <ListView
        canAdd={false}
        canDelete={false}
        canEdit={false}
        columnDefs={columnDefs}
        rowData={rowData}
        onRowClicked={handleRowClicked}
      >
        <ListViewToolbar canExport header="Back Orders" />
      </ListView>
    </>
  );
};

export default BackOrders;

import Api from '../../services/ApiService';
import { Invoice, TokenData } from '../payment/payment.types';

export const getOutstandingInvoices = async (token: string) => {
  const response = await Api.get('customers/outstanding/token', { params: { token } });

  const { tokenInvoices, tokenData }: { tokenInvoices: Invoice[]; tokenData: TokenData } = response.data;

  return {
    tokenInvoices,
    tokenData,
  };
};

export default { getOutstandingInvoices };

import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useAtom } from 'jotai';
import PartCard from './PartCard';
import userAtom from '../../stores/user.store';
import PartService from '../../services/PartService';
import { usePartsStore } from './part.store';
import store from 'store';
import LoadingPage from '../LoadingPage';
import { PartImage } from './part.types';

const localStorageAccountNumKey = 'part.stockCheck.accountNum';

interface Props {
  partNumber: string;
  lineCode: string;
  images?: PartImage[];
  onAddToCart?: () => void;
  resetOnUnmount?: boolean;
}

const PartCardModal = ({
  partNumber,
  lineCode,
  images = [],
  onAddToCart = () => {},
  resetOnUnmount = false,
}: Props) => {
  const [accountNumber, setAccountNumber] = useState(store.get(localStorageAccountNumKey));
  const [user] = useAtom(userAtom);
  const [ready, setReady] = useState(false);

  const { activePart, setActivePart, resetActivePart } = usePartsStore();

  const stockCheck = () => {
    return PartService.stockCheck({
      partNumber,
      lineCode,
      quantity: activePart.quan,
      accountNumber,
    }).then((data) => {
      setActivePart({ images, ...data }, false);
    });
  };

  useEffect(() => {
    if (user.isCustomer || accountNumber) {
      stockCheck().then(() => setReady(true));
    } else {
      setReady(true);
    }

    return () => {
      if (resetOnUnmount) {
        resetActivePart();
      }
    };
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!accountNumber) {
      return;
    }

    store.set(localStorageAccountNumKey, accountNumber);
    stockCheck();
  };

  const renderAccountControl = () => {
    if (user.isCustomer) {
      return null;
    }

    return (
      <Form inline onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Account Number</Form.Label>
          <Form.Control onChange={(event: any) => setAccountNumber(event.target.value)} value={accountNumber || ''} />
        </Form.Group>
        <Button type="submit">Submit</Button>
      </Form>
    );
  };

  const renderPartCard = () => {
    if (!activePart.partno) {
      return null;
    }

    return <PartCard hideSearch onAddToCart={onAddToCart} />;
  };

  if (!ready) {
    return <LoadingPage />;
  }

  return (
    <>
      {renderAccountControl()}
      {renderPartCard()}
    </>
  );
};

export default PartCardModal;

import { Row, Col } from 'react-bootstrap';
import companyAtom from '../stores/company.store';
import userAtom from '../stores/user.store';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { useAtom } from 'jotai';
import Widget from './Widget';
import OrderDetailSum from './widgets/OrderDetailSum';

import ActiveCustomers from './widgets/ActiveCustomers';
import CustomerOrdersSum from './widgets/CustomerOrdersSum';
import OrderSalesByBranch from './widgets/OrderSalesByBranch';

const Widgets = () => {
  const [user] = useAtom(userAtom);
  const [company] = useAtom(companyAtom);

  if ((!user.isAdmin && !user.isDealer) || !company.id) {
    return null;
  }

  return (
    <Row>
      <Col md={6}>
        <Swiper
          navigation={true}
          modules={[Navigation, Pagination]}
          loop={true}
          pagination={{
            clickable: true,
          }}
        >
          <SwiperSlide>
            <Widget title="Order Totals (Last 12 Months)">
              <OrderDetailSum timeSpan="last12Months" />
            </Widget>
          </SwiperSlide>
          <SwiperSlide>
            <Widget title="Order Totals (Year-to-Date)">
              <OrderDetailSum timeSpan="ytdByMonth" />
            </Widget>
          </SwiperSlide>
        </Swiper>
      </Col>
      <Col md={6}>
        <Widget title="Active Users By Branch">
          <ActiveCustomers />
        </Widget>
      </Col>
      <Col md={12} lg={12} xl={6}>
        <Widget title="Top Customers By Sales (Last 12 Months)">
          <CustomerOrdersSum />
        </Widget>
      </Col>
      <Col md={12} lg={12} xl={6}>
        <Widget title="Orders by Branch (Last 7 Days)">
          <OrderSalesByBranch />
        </Widget>
      </Col>
    </Row>
  );
};

export default Widgets;

import DbList from './DbList';
import DbEdit from './DbEdit';
import { Navigate, useParams } from 'react-router-dom';
import userAtom from '../../stores/user.store';
import { useAtom } from 'jotai';

const Db = () => {
  const [user] = useAtom(userAtom);
  const { itemName = '', itemId } = useParams();

  if (user.isCustomer && itemName === 'company') {
    return <Navigate to="/" />;
  }

  if (itemId) {
    return <DbEdit itemName={itemName} itemId={itemId} />;
  }

  return <DbList itemName={itemName} />;
};

export default Db;

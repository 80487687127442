import { ReactNode } from 'react';
import classNames from 'classnames';
interface Props {
  className?: string;
  padSides?: boolean;
  padTop?: boolean;
  children: ReactNode;
}

const PageContainer = ({ padSides = false, padTop = false, className = '', children }: Props) => {
  const classNameStr = classNames({
    'pad-sides': padSides,
    'pad-top': padTop,
    [className]: true,
  });

  return (
    <div id="page" className={classNameStr}>
      <div className="page-container">{children}</div>
    </div>
  );
};

export default PageContainer;

import Api from './ApiService';

const PORTAL_TOKEN_NAME = 'portalToken';

// init session token if persistent token exists
if (localStorage.getItem(PORTAL_TOKEN_NAME) && !sessionStorage.getItem(PORTAL_TOKEN_NAME)) {
  sessionStorage.setItem(PORTAL_TOKEN_NAME, localStorage.getItem(PORTAL_TOKEN_NAME) || '');
}

class Auth {
  removeToken() {
    localStorage.removeItem(PORTAL_TOKEN_NAME);
    sessionStorage.removeItem(PORTAL_TOKEN_NAME);
  }

  getSessionToken() {
    return sessionStorage.getItem(PORTAL_TOKEN_NAME);
  }

  getToken() {
    return localStorage.portalToken || '';
  }

  setToken(token: string) {
    localStorage.setItem(PORTAL_TOKEN_NAME, token);
    sessionStorage.setItem(PORTAL_TOKEN_NAME, token);
  }

  logout() {
    this.removeToken();
    Api.post('logout').then(() => window.location.reload());
  }

  loggedIn() {
    return !!this.getToken();
  }
}

export default new Auth();

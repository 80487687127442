import _ from 'lodash';
import { useAtom, atom, useAtomValue } from 'jotai';
import store from 'store';
import userAtom, { User } from '../../stores/user.store';
import { Cart, CartItem, PartQtyBreak, PartStockCheck } from './part.types';
import { useEffect } from 'react';

interface PartStore {
  activeCart?: Cart;
  activePart: CartItem;
  cart: CartItem[];
  comment: string;
  deliveryMethod: string;
  poNumber: string;
  shippingAccount: string;
  shippingAccountBranchNum: string;
  submittingOrder?: boolean;
  user?: User;
}

const defaultState = {
  activeCart: undefined,
  cart: [],
  deliveryMethod: '',
  shippingAccount: '',
  shippingAccountBranchNum: '',
  comment: '',
  poNumber: '',
  activePart: {
    comment: '',
    core: '',
    cost: '',
    desc: '',
    edit: false,
    images: [],
    linecode: '',
    linedesc: '',
    linenum: '',
    partno: '',
    qtybreak: [],
    quan: 1,
    special: '',
    specialComments: [],
    list: '',
    minqty: '',
    qtyavail: '',
    maxqty: '',
    qtyreq: '',
  },
  submittingOrder: false,
};

const primitivePartStoreAtom = atom<PartStore>(defaultState);

const getLocalStorageKey = (user: User) => {
  return user?.customer?.id ? `parts.cart.${user.customer.id}` : '';
};

const partStoreAtom = atom(
  (get) => {
    return get(primitivePartStoreAtom);
  },
  (get, set, newValue: Partial<PartStore>) => {
    const user = get(userAtom);
    const key = getLocalStorageKey(user);
    if (key) {
      store.set(key, { ...get(primitivePartStoreAtom), ...newValue });
    }
    return set(primitivePartStoreAtom, { ...get(primitivePartStoreAtom), ...newValue });
  }
);

const useStore = () => {
  const user = useAtomValue(userAtom);
  const [state, setState] = useAtom(partStoreAtom);

  useEffect(() => {
    if (user && user.customer && user.customer.id) {
      // load the cached cart when the user atom is loaded with a customer id
      const key = getLocalStorageKey(user);
      const cached: Partial<PartStore> = store.get(key) || {};
      setState(cached);
    }
  }, [user]);

  const { activePart, cart, activeCart } = state;

  const getShippingTier = () => {
    const amount = getItemSubtotal();
    const tiers = user?.branch?.shippingTier?.data || [];

    if (!amount || !tiers || !tiers.length) {
      return null;
    }

    for (const line of tiers) {
      if (Number(amount) >= Number(line.min) && (Number(amount) <= Number(line.max) || !Number(line.max))) {
        return line;
      }
    }
  };

  const getShippingHandling = ({ localeString = false } = {}) => {
    const shippingTier = getShippingTier();

    const amount = shippingTier ? Number(shippingTier.shipping) + Number(shippingTier.handling) : 0;

    return localeString
      ? amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      : Number(amount);
  };

  const resetCart = () => {
    setState({
      cart: [],
      deliveryMethod: '',
      comment: '',
      poNumber: '',
      shippingAccount: '',
      shippingAccountBranchNum: '',
    });
  };

  const resetActivePart = () => {
    setState({ activePart: { ...defaultState.activePart } as CartItem });
    return defaultState.activePart;
  };

  const setActivePart = (data: Partial<CartItem>, edit: boolean = activePart.edit) => {
    setState({
      activePart: { ...activePart, ...data, edit },
    });
  };

  const setCart = (cart: CartItem[]) => {
    setState({ cart: [...cart] });
  };

  const addToCart = (item: CartItem) => {
    setState({ cart: [...cart, item] });
  };

  const unloadSavedCart = () => {
    setState({ activeCart: undefined });
  };

  const loadSavedCart = ({ name, id }: { name: string; id: number }) => {
    setState({ activeCart: { name, id, data: '' } });
  };

  const hasCartLoaded = () => !!activeCart;

  const getSpecialComments = (part = activePart) => {
    if (part.specialComments && part.specialComments.length) {
      return part.specialComments.map((s: string, i: number) => {
        if (!_.isString(s)) {
          return null;
        }

        s = s.replace('special ends', 'Special Ends'); // TODO lame

        return <div key={i}>{s}</div>;
      });
    }

    return null;
  };

  const getUnitPrice = (part: PartStockCheck, quan: number) => {
    const { qtybreak, cost } = part;
    let unitPrice = Number(cost);

    if (!qtybreak || !qtybreak.length || quan === 1) {
      return unitPrice;
    }

    const lines = qtybreak.map((line: PartQtyBreak) => {
      return { qty: Number(line.qty), price: Number(line.price) };
    });

    // determine the unit price based on desired quantity
    let highestQty = 0;
    for (const line of lines) {
      if (quan >= line.qty && line.qty > highestQty) {
        unitPrice = line.price;
        highestQty = line.qty;
      }
    }

    return unitPrice;
  };

  const getItemSubtotal = ({ localeString = false } = {}) => {
    if (!cart.length) {
      return 0;
    }

    const subtotal = cart
      .map((item) => item.quan * (getUnitPrice(item, item.quan) + Number(item.core)))
      .reduce((curr, val) => curr + val);

    return localeString
      ? subtotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      : Number(subtotal);
  };

  const getOrderTotal = ({ localeString = false }) => {
    const itemSubtotal = getItemSubtotal();
    const shippingHandling = getShippingHandling();
    const total = Number(itemSubtotal) + Number(shippingHandling);

    return localeString
      ? total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      : total;
  };

  const getTotalItems = () => {
    if (!cart.length) {
      return 0;
    }

    return cart
      .map((item) => item.quan || 1)
      .reduce((curr, val) => curr + val)
      .toLocaleString();
  };

  return {
    ...state,
    getItemSubtotal,
    getOrderTotal,
    getShippingTier,
    getSpecialComments,
    getShippingHandling,
    getTotalItems,
    getUnitPrice,
    setState,
    setActivePart,
    setCart,
    resetActivePart,
    resetCart,
    addToCart,
    hasCartLoaded,
    unloadSavedCart,
    loadSavedCart,
  };
};

export { useStore as usePartsStore };

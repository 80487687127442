import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import Api from '../../services/ApiService';
import { extractTimestamp, unescape, currency } from '../../util';
import DisplayValue from '../DisplayValue';
import config from '../../config';
import { useParams } from 'react-router-dom';

import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Order } from './order.types';

dayjs.extend(relativeTime);
dayjs.extend(duration);

const OrderDetail = () => {
  const [order, setOrder] = useState<Order>();
  const [invalidOrder, setInvalidOrder] = useState(false);
  const { orderId } = useParams();

  // orders before this change might show incorrect price per line item
  // this is used to give notice
  const [hasUnitPrice, setHasUnitPrice] = useState(true);

  const viewInvoice = () => {
    if (!order) return;
    const fileUrl = `${config.apiUrl}/invoices/${order.invoiceNum}?type=inv&branchId=${order.BranchId}&customerNum=${order.customerNum}`;
    window.open(fileUrl);
  };

  useEffect(() => {
    Api.get(`orders/${orderId}`)
      .then(({ data }) => {
        setOrder(data);
        setHasUnitPrice(true);
        // disable for now
        // setHasUnitPrice(data.parts && data.parts.length && data.parts[0].unitPrice);
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          setInvalidOrder(true);
        }
      });
  }, [orderId]);

  if (invalidOrder) {
    return <h3>Order not found</h3>;
  }

  if (!order) {
    return null;
  }

  const renderItemTable = () => {
    return (
      <>
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>Item #</th>
              <th>Description</th>
              <th>Qty</th>
              <th>Per Item</th>
              <th>Item Total</th>
              <th>Per Core</th>
              <th>Core Total</th>
            </tr>
          </thead>
          <tbody>
            {order.parts.map((part, i) => {
              return (
                <tr key={i}>
                  <td>{part.partno}</td>
                  <td>{unescape(part.desc)}</td>
                  <td>{part.quan}</td>
                  <td>
                    {currency(Number(part.unitPrice || part.cost))}
                    {!hasUnitPrice && '*'}
                  </td>
                  <td>
                    {currency(Number(part.unitPrice || part.cost) * part.quan)}
                    {!hasUnitPrice && '*'}
                  </td>
                  <td>{!!Number(part.core) && currency(Number(part.core))}</td>
                  <td>{!!Number(part.core) && currency(Number(part.core) * part.quan)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {!hasUnitPrice && <div className="muted">* Price per item may not reflect discounts.</div>}
      </>
    );
  };

  const renderInvoiceDate = () => {
    if (!order.invoiceDate) {
      return '-';
    }

    return dayjs(new Date(order.invoiceDate)).format('MMM D, YYYY h:mm a ZZ') || '-';
  };

  const getFulfillTime = () => {
    if (!order.invoiceDate) {
      return '-';
    }

    const timeMs = new Date(order.invoiceDate).getTime() - extractTimestamp(order.id);
    return dayjs.duration(timeMs).humanize();
  };

  return (
    <div className="order-detail">
      <h4>
        Order Details <small>({order.parts.length} items)</small>
      </h4>

      <div className="part-card summary shadow">
        <div className="order-status-bar">
          <DisplayValue label="Account">{order.customer.name}</DisplayValue>
          <DisplayValue label="Email">{order.customer.email || order.user.email}</DisplayValue>
          <DisplayValue label="Account Number">{order.customer.num}</DisplayValue>
          <DisplayValue label="Status">Ordered</DisplayValue>
        </div>

        <div className="order-detail-body">
          <div className="left-side">
            <DisplayValue label="Order Date">
              {dayjs(new Date(extractTimestamp(order.id))).format('MMM D, YYYY h:mm a ZZ')}
            </DisplayValue>
            <DisplayValue label="Order Number">{order.id}</DisplayValue>
            <DisplayValue label="Purchase Order">{order.poNum || '-'}</DisplayValue>
            <DisplayValue label="Invoice Number">
              {!!order.invoiceNum && (
                <a role="button" onClick={() => viewInvoice()}>
                  {order.invoiceNum}
                </a>
              )}
              {!order.invoiceNum && '-'}
            </DisplayValue>
            <DisplayValue label="Invoice Date">{renderInvoiceDate()}</DisplayValue>
            {!!order.invoiceDate && <DisplayValue label="Fulfill Time">{getFulfillTime()}</DisplayValue>}
            <DisplayValue label="Delivery Method">{order.deliveryMethodDescription}</DisplayValue>
            <DisplayValue label="Shipping Account">{order.shippingAccount || '-'}</DisplayValue>
            {order.comment && <DisplayValue label="Comment">{order.comment}</DisplayValue>}
            <hr />
            {!!order.shippingAmount && (
              <DisplayValue label="Shipping">{currency(order.shippingAmount / 100)}</DisplayValue>
            )}
            {!!order.handlingAmount && (
              <DisplayValue label="Handling">{currency(order.handlingAmount / 100)}</DisplayValue>
            )}
            <DisplayValue label="Order Total" className="lg">
              {currency(order.total / 100)}
            </DisplayValue>
          </div>
          <div className="right-side">{renderItemTable()}</div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;

import { useState } from 'react';
import config from '../config';
import Api from '../services/ApiService';
import FileSaver from 'file-saver';
import { toast } from 'react-toastify';

interface Props {
  type: 'ro' | 'invoce';
  docId: string;
}

const InvoiceDownloadButton = ({ type, docId }: Props) => {
  const [downloading, setDownloading] = useState(false);

  const handleDownload = () => {
    setDownloading(true);
    Api.get(`${config.apiUrl}/invoices/${docId}?type=${type}&download=1`, {
      responseType: 'blob',
      timeout: 30000,
    })
      .then((response) => {
        setDownloading(false);
        FileSaver.saveAs(response.data, docId);
      })
      .catch((err) => {
        console.error(err);
        setDownloading(false);
        toast.error(`Error Downloading ${docId}`);
      });
  };

  return (
    <>
      {!downloading && <i className="fas fa-download link" onClick={handleDownload} />}
      {downloading && <i className="fas fa-spinner-third fa-spin"></i>}
    </>
  );
};

export default InvoiceDownloadButton;

import { useAtom } from 'jotai';
import WorldNetTokenContainer from './WorldNetTokenContainer';
import userAtom from '../../stores/user.store';
import companyAtom from '../../stores/company.store';
import WorldNetService from './WorldNetService';
import { WorldNetTokenQueryParams } from './payment.types';

interface Props {
  action?: 'register' | 'update';
}

const WorldNetAddCard = ({ action = 'register' }: Props) => {
  const [user] = useAtom(userAtom);
  const [company] = useAtom(companyAtom);
  const customerNum = user.customer?.num || '';

  const handleResponse = ({
    terminalId,
    merchantRef,
    response,
  }: {
    terminalId: string;
    merchantRef: string;
    response: WorldNetTokenQueryParams;
  }) => {
    WorldNetService.addCard({
      accountId: company.config.payment.accountId,
      clientNum: company.num,
      customerNum,
      merchantRef,
      response,
      terminalId,
    })
      .then(() => {
        console.log('Card Added');
      })
      .catch((err) => {
        console.log('Error Adding Card', err);
      });
  };

  return (
    <WorldNetTokenContainer
      action={action}
      customerNum={customerNum}
      clientNum={company.num}
      accountId={company.config.payment.accountId}
      onResponse={handleResponse}
    />
  );
};

export default WorldNetAddCard;

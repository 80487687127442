import { Col, Nav, Tab } from 'react-bootstrap';
import ChangePassword from './ChangePassword';
import MFAView from './MFAView';
import { useNavigate, useParams } from 'react-router-dom';

const Account = () => {
  const navigate = useNavigate();
  const params = useParams();

  const view = params.view || 'password';

  const handleTabChange = (eventKey: string | null) => {
    if (eventKey) {
      navigate(`/account/${eventKey}`, { replace: true });
    }
  };

  return (
    <Tab.Container id="form-tabs" defaultActiveKey={view} onSelect={handleTabChange}>
      <div className="clearfix">
        <div className="tab-labels">
          <Nav variant="tabs">
            <Nav.Item key="password">
              <Nav.Link eventKey="password">Change Password</Nav.Link>
            </Nav.Item>
            <Nav.Item key="mfa">
              <Nav.Link eventKey="mfa">Multi-Factor Authentication</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <Tab.Content>
          <Tab.Pane key="password" eventKey="password">
            <Col lg={4} md={6} sm={6}>
              <ChangePassword />
            </Col>
          </Tab.Pane>
          <Tab.Pane key="mfa" eventKey="mfa">
            <Col>
              <MFAView />
            </Col>
          </Tab.Pane>
        </Tab.Content>
      </div>
    </Tab.Container>
  );
};

export default Account;

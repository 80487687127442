import { useEffect, useState } from 'react';
import Form from '../Form';
import Api from '../../services/ApiService';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { User } from '../../stores/user.store';

interface Props {
  userId: number;
}

const CustomerResetPassword = ({ userId }: Props) => {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    Api.get(`users/${userId}`).then(({ data }) => {
      setUser(data);
    });
  }, [userId]);

  if (!user) {
    return null;
  }

  const handleSubmit = (data: { password: string; email: string }) => {
    if (!data.password) {
      data.password = Math.random().toString(36).substring(4).toUpperCase();
    }
    Api.put(`reset-password/${userId}`, data).then(() => {
      toast('New password has been set');
    });
  };

  const layout = [
    {
      label: 'Send new password to',
      name: 'emails',
      control: 'ReactSelect',
      collection: true,
      data: [user.email],
      props: { creatable: true },
      options: [{ label: user.email, value: user.email }],
      inputProps: {
        isMulti: true,
      },
      type: 'email',
      required: true,
    },
    { label: 'Password', name: 'password', helpText: 'Leave blank for random password' },
  ];

  return (
    <>
      <h5>
        Resetting password for{' '}
        <strong>
          {user.firstName} {user.lastName}
        </strong>
      </h5>
      <Form layout={layout} hideToolbar onSubmit={handleSubmit}>
        <div className="pad-sides">
          <Button data-blaze-submit variant="warning" className="pull-right">
            Reset Password
          </Button>
        </div>
      </Form>
    </>
  );
};

export default CustomerResetPassword;

import config from '../../config';
import { modal } from '../modal/ModalEmitter';
import FileService from '../../services/FileService';
import ImageGallery from 'react-image-gallery';
import { CSSProperties } from 'react';
import { PartImage } from './part.types';

interface Props {
  style?: CSSProperties;
  images: PartImage[];
  partNumber: string;
  description: string;
  openGallery?: boolean;
}

const getUrlFromKey = (key: string) => {
  return `${config.apiUrl}/parts/attachments/${key}?size=60,fit`;
};

const PartItemIcon = ({ images, partNumber, description, openGallery = true, ...props }: Props) => {
  const iconUrl = getUrlFromKey(images[0].key);

  const handleClick = (image: PartImage) => {
    if (image.mimeType === 'application/pdf') {
      FileService.downloadDataUri(FileService.downloadPath(image.key, 'attachments'), `${image.key}.pdf`);
    }
  };

  const imageGalleryImages = images.map((image) => {
    return {
      original: getUrlFromKey(image.key),
      thumbnail: getUrlFromKey(image.key),
      renderItem: () => {
        const canDownload = image.mimeType === 'application/pdf';
        return (
          <div className="image-gallery-image">
            <img
              src={getUrlFromKey(image.key)}
              onClick={() => handleClick(image)}
              title={canDownload ? 'Click To Download' : ''}
              style={canDownload ? { cursor: 'pointer' } : {}}
              alt="Preview"
            />
          </div>
        );
      },
    };
  });

  return (
    <img
      alt="Preview"
      onClick={() => {
        if (!openGallery) {
          return;
        }

        modal.open({
          title: `${partNumber} - ${description}`,
          body: (
            <ImageGallery
              items={imageGalleryImages}
              showPlayButton={false}
              showFullscreenButton={false}
              showThumbnails={imageGalleryImages.length > 1}
            />
          ),
        });
      }}
      src={iconUrl}
      {...props}
    />
  );
};

export default PartItemIcon;

import MdEditor from 'react-markdown-editor-lite';
// import style manually
import 'react-markdown-editor-lite/lib/index.css';
import MarkdownIt from 'markdown-it';
import { modal } from './modal/ModalEmitter';
import { useEffect, useRef } from 'react';

const mdParser = new MarkdownIt({ html: true, linkify: true });

interface Props {
  md: string;
  onSave: (md: string) => void;
  onCancel: () => void;
}
const MarkdownEditor = ({ md: mdProp = '', onSave, onCancel }: Props) => {
  const md = useRef(mdProp);

  const handleSave = () => {
    onSave(md.current);
  };

  useEffect(() => {
    modal.open({
      title: 'Edit Dashboard',
      body: (
        <MdEditor
          defaultValue={mdProp}
          style={{ height: '100%' }}
          renderHTML={text => mdParser.render(text)}
          onChange={({ text }) => (md.current = text)}
        />
      ),
      modalProps: {
        size: 'lg',
      },
      confirm: true,
      naked: true,
      confirmLabels: ['Save', 'Cancel'],
      onOk: handleSave,
      onCancel,
    });
  }, []);

  return <div />;
};

export default MarkdownEditor;

import _ from 'lodash';
import { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ButtonGroup, Button, Card } from 'react-bootstrap';
import LazyLoad, { forceCheck } from 'react-lazyload';
import Api from '../../services/ApiService';
import ListView from '../list/ListView';
import { modal } from '../modal/ModalEmitter';
import PartCardModal from './PartCardModal';
import { companyAtom, userAtom } from '../../stores/root.store';
import PartItemIcon from './PartItemIcon';
import ReindexPartsButton from './ReindexPartsButton';
import Chip from '../Chip';
import ErrorPage from '../ErrorPage';
import { useAtom } from 'jotai';
import { ICellRendererParams, RowNode } from '@ag-grid-community/core';
import { PartCategory, PartSuggestionShort } from './part.types';

const Parts = () => {
  const [user] = useAtom(userAtom);
  const [company] = useAtom(companyAtom);
  const [categories, setCategories] = useState<PartCategory[]>([]);
  const [parts, setParts] = useState<PartSuggestionShort[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const searchText = searchParams.get('q') || '';
  const category = searchParams.get('category') || '';
  const num = searchParams.get('num');

  const searchValue = useRef(searchText);

  if (!company.id) {
    return <ErrorPage>Company not selected</ErrorPage>;
  }

  const columnDefs = [
    {
      pinned: 'left',
      headerName: '',
      field: 'action',
      suppressSizeToFit: true,
      suppressMenu: true,
      sortable: false,
      cellClass: 'ag-col-center',
      width: 90,
      cellRenderer: (cellProps: any) => {
        return (
          <ButtonGroup className="list-row-toolbar">
            <Button
              className="btn-xs"
              onClick={(event: any) => {
                // row click handler does this
                // handleRowClicked(cellProps);
                return;
              }}
              title="Add To Cart"
            >
              <i className="fas fa-cart-plus" />
            </Button>
          </ButtonGroup>
        );
      },
    },
    {
      headerName: 'Part Description',
      field: 'n',
      cellRenderer: (cellProps: ICellRendererParams) => {
        const { n, d, upc, images } = cellProps.data;

        const partText = (
          <div className="part-text">
            <div>{d}</div>
            {!!upc && <div className="upc">UPC: {upc}</div>}
          </div>
        );

        if (images) {
          return (
            <>
              <div className="part-image-wrapper">
                <LazyLoad scrollContainer=".ag-body-viewport" once debounce>
                  <PartItemIcon
                    style={{ maxWidth: '70px', maxHeight: '70px' }}
                    images={images}
                    partNumber={n}
                    description={d}
                    openGallery={false}
                  />
                </LazyLoad>
              </div>

              <div style={{ marginLeft: '75px' }}>{partText}</div>
            </>
          );
        }

        return partText;
      },
    },
    { headerName: 'Part Number', field: 'n' },
    { headerName: 'Category', field: 'c' },
    { headerName: 'Division', field: 'dn', hide: _.get(company.config, 'parts.list.hideDivision') },
    { headerName: 'Vendor', field: 'ld', hide: _.get(company.config, 'parts.list.hideVendor') },
    {
      headerName: 'Replaces',
      field: 'sp',
      cellRenderer: (cellProps: any) => {
        const { sp } = cellProps.data;
        if (!sp || !sp.length || !Array.isArray(sp)) {
          return null;
        }

        return sp.map((entry) => entry.num).join(', ');
      },
    },
  ];

  const searchParts = (newSearchText: string) =>
    Api.get('search/parts', {
      params: { limit: 10000, q: newSearchText, category, num },
    }).then(({ data }) => {
      // ignore old requests
      if (searchValue.current === newSearchText) {
        setParts(data);
        if (num && data.length && data[0].n.toLowerCase() === num.toLowerCase()) {
          // pop modal if num is set and matches first row
          handleRowClicked({ data: data[0] } as RowNode);
        }
      }
    });

  const showList = () => category || searchText || num;

  useEffect(() => {
    if (!company || !company.id) {
      return;
    }

    Api.get('parts/categories').then(({ data }) => {
      if (data.categories) {
        setCategories(
          (data.categories as PartCategory[])
            .filter((cat) => !['LABOR', 'INTERNAL'].includes(cat.name))
            .sort((a, b) => (a.name > b.name ? 1 : -1))
        );
      }
    });
  }, []);

  useEffect(() => {
    searchValue.current = searchText;
    if (showList()) {
      searchParts(searchText);
    }
  }, [category, searchText, num]);

  const handleRowClicked = (rowNode: RowNode) => {
    const { n, lc, images } = rowNode.data;

    const handleAddToCart = () => {
      modal.close();
      toast('Part Added To Cart');
    };

    modal.open({
      title: `Part Detail (${n})`,
      headerClass: 'bg-primary',
      hideFooter: true,
      modalProps: {
        size: 'xl',
      },
      body: <PartCardModal partNumber={n} lineCode={lc} images={images} onAddToCart={handleAddToCart} resetOnUnmount />,
      onClose: () => {
        if (searchParams.has('num')) {
          // remove num on close so it doesn't keep popping up
          searchParams.delete('num');
          setSearchParams({ ...Object.fromEntries(searchParams) });
        }
        modal.close();
      },
    });
  };

  if (!company || !company.id) {
    return <div>Use on company site</div>;
  }

  const renderCategories = () => {
    if (!categories.length) {
      return <div>No categories defined</div>;
    }

    return (
      <div className="list">
        {categories.map((cat) => (
          <div
            className="list-item"
            onClick={() => {
              setSearchParams({ ...Object.fromEntries(searchParams), category: cat.name });
              modal.close();
            }}
            key={cat.slug}
            style={{ float: 'left', width: '200px' }}
          >
            {cat.name}
          </div>
        ))}
      </div>
    );
  };

  const renderCategoryCard = () => {
    return (
      <Card
        as="h5"
        style={{ width: '75%', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px', backgroundColor: '#eee' }}
      >
        <Card.Header className="bg-primary">Filter by Category</Card.Header>
        <Card.Body>{renderCategories()}</Card.Body>
      </Card>
    );
  };

  const handleSelectCategory = () => {
    modal.open({
      title: 'Filter by Category',
      headerClass: 'bg-primary',
      hideFooter: true,
      body: renderCategories(),
    });
  };

  const renderCategoryChips = () => {
    if (!showList()) {
      // category page should render anyway
      return;
    }

    return (
      <>
        <div className="chip-set p-3">
          <Button onClick={handleSelectCategory} variant="default" size="sm" className="mr-3">
            Select Category
          </Button>
          {!!category && (
            <Chip
              name={category}
              label={category}
              onRemove={() => {
                searchParams.delete('category');
                setSearchParams({ ...Object.fromEntries(searchParams) });
              }}
            />
          )}
        </div>
      </>
    );
  };

  const renderList = () => {
    if (!showList()) {
      return null;
    }

    return (
      <>
        <div className="pl-3 pt-3">
          {parts.length} results for <b>{searchText || num ? `"${searchText || num}"` : 'anything'}</b>{' '}
          {category ? (
            <span>
              in category <b>{category}</b>
            </span>
          ) : (
            ''
          )}
        </div>
        <ListView
          canAdd={false}
          canDelete={false}
          canEdit={false}
          onUpdated={() => setTimeout(forceCheck, 100)}
          gridOptions={{ rowHeight: 75 }}
          columnDefs={columnDefs}
          rowData={parts}
          onRowClicked={handleRowClicked}
        />
      </>
    );
  };

  const renderAdminControls = () => {
    if (!user.isAdmin && !user.dealer) {
      return;
    }

    return <ReindexPartsButton />;
  };

  return (
    <div id="part-search-list">
      {!showList() && renderCategoryCard()}
      {renderCategoryChips()}
      {renderList()}
      {renderAdminControls()}
    </div>
  );
};

export default Parts;

import _ from 'lodash';
import { Button, ButtonGroup, Dropdown, DropdownButton, Navbar } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CartLoadForm from './CartLoadForm';
import CartSaveForm from './CartSaveForm';
import CartSummary from './CartSummary';
import CartUploadForm from './CartUploadForm';
import PartCartItem from './PartCartItem';
import { modal } from '../modal/ModalEmitter';
import Api from '../../services/ApiService';
import { usePartsStore } from './part.store';
import { CartItem, PartStockCheck } from './part.types';
import Form from '../Form';

interface Cart {
  id: number;
  name: string;
  data: CartItem[];
}

const partEquals = (partA: PartStockCheck, partB: PartStockCheck) => {
  return partA.partno === partB.partno && partA.linecode === partB.linecode;
};

const PartCart = () => {
  const { activeCart, cart, hasCartLoaded, resetCart, setCart, loadSavedCart, unloadSavedCart } = usePartsStore();

  const cartEmpty = () => !cart.length;

  const handleClearCart = () => {
    const handleSubmit = (data: any = {}) => {
      if (!activeCart) {
        return;
      }

      if (data.deleteCart === 'Y') {
        // TODO api call
        Api.delete(`carts/${activeCart.id}`);
      }
      if (hasCartLoaded()) {
        unloadSavedCart();
        modal.close();
      }
      resetCart();
    };

    modal.confirm({
      title: 'Clear Cart?',
      headerClass: 'primary',
      hideFooter: hasCartLoaded(),
      body: hasCartLoaded() ? (
        <Form
          onSubmit={handleSubmit}
          layout={[{ name: 'deleteCart', control: 'checkbox', label: 'Delete Saved Cart' }]}
        />
      ) : undefined,
      onOk: handleSubmit,
    });
  };

  const handleNewCart = () => {
    if (hasCartLoaded()) {
      unloadSavedCart();
      modal.close();
    }
    resetCart();
  };

  const handleLoadCart = () => {
    const handleSubmit = (cart: Cart) => {
      loadSavedCart({ id: cart.id, name: cart.name });

      // TODO put in function for reuse
      Api.post('parts/stockcheck', { parts: cart.data }).then(({ data }) => {
        const parts: PartStockCheck[] = (Array.isArray(data) ? data : [data])
          .map((part: CartItem) => {
            for (const p of cart.data) {
              if (p.partno === part.partno) {
                part.quan = p.quan;
              }
            }
            return part;
          })
          .filter((part) => part.partno); // ensure parts are valid

        setCart(parts as CartItem[]);
      });
      modal.close();
    };

    modal.open({
      title: 'Load Saved Cart',
      headerClass: 'primary',
      body: <CartLoadForm onSubmit={handleSubmit} onCancel={modal.close} />,
      hideFooter: true,
      modalProps: {
        size: 'sm',
        className: 'center',
      },
    });
  };

  const handleUpload = () => {
    const handleSubmit = ({ items, failed }: { items: CartItem[]; failed: CartItem[] }) => {
      modal.close();

      if (failed) {
        modal.open({
          title: 'Some parts were invalid',
          headerClass: 'primary',
          body: (
            <>
              <h5>The following parts were skipped</h5>
              <ul>
                {failed.map((item, i: number) => (
                  <li key={i}>
                    {item.partno} {item.desc}
                  </li>
                ))}
              </ul>
            </>
          ),
        });
      }

      setCart(items);
    };

    modal.open({
      title: 'Upload Parts File',
      headerClass: 'bg-primary',
      body: <CartUploadForm onSubmit={handleSubmit} onCancel={modal.close} />,
      hideFooter: true,
    });
  };

  const handleSaveCart = () => {
    const cartSavedStr = 'Cart Saved';

    if (hasCartLoaded() && activeCart) {
      Api.put(`carts/${activeCart.id}`, cart).then(() => {
        toast(cartSavedStr);
      });
      return;
    }

    const handleSubmit = ({ name }: Record<string, any>) => {
      Api.post('carts', { name, data: cart }).then(({ data }) => {
        loadSavedCart({ id: data.id, name });
        modal.close();
        toast(cartSavedStr);
      });
    };

    modal.open({
      title: 'Save Cart',
      body: <CartSaveForm onSubmit={handleSubmit} onCancel={modal.close} />,
      hideFooter: true,
      headerClass: 'primary',
      modalProps: {
        size: 'sm',
        className: 'center',
      },
    });
  };

  const updateItemQuan = (itemToUpdate: CartItem, quan: number) => {
    if (quan < 1) {
      return;
    }

    for (const item of cart) {
      if (partEquals(item, itemToUpdate)) {
        item.quan = quan;
        setCart(cart);
        return;
      }
    }
  };

  const updateItemComment = (itemToUpdate: CartItem, comment: string) => {
    for (const item of cart) {
      if (partEquals(item, itemToUpdate)) {
        item.comment = comment;
        setCart(cart);
        return;
      }
    }
  };

  const removeItem = (item: CartItem) => {
    modal.confirm({
      title: `Remove ${item.partno}?`,
      body: `Remove ${item.partno} - ${item.desc} from cart?`,
      onOk: () => {
        for (let i = 0; i < cart.length; i++) {
          if (partEquals(cart[i], item)) {
            cart.splice(i, 1);
            setCart(cart);
            return;
          }
        }
      },
    });
  };

  const renderPartRows = () => {
    return cart.map((item: CartItem, i) => {
      return (
        <PartCartItem
          item={item}
          key={i}
          onRemove={removeItem}
          onQuanUpdate={updateItemQuan}
          onCommentUpdate={updateItemComment}
        />
      );
    });
  };

  const renderPartItems = () => {
    if (!cart.length) {
      return (
        <div className="empty-items">
          <div className="empty-msg">
            <i className="fas fa-shopping-cart mr-2" />
            Empty
            <p className="help">Begin by searching the part catalog or start from a previously saved cart</p>
          </div>
        </div>
      );
    }

    return (
      <div className="cart-items">
        <div className="part-items">
          <div className="part-items-wrapper">{renderPartRows()}</div>
        </div>
        {cart.length !== 0 && <CartSummary />}
      </div>
    );
  };

  return (
    <div className="part-cart">
      <div className="cart-list">
        <Navbar className="cart-toolbar">
          <Navbar.Brand id="cart-title" className="title">
            Shopping Cart
            {hasCartLoaded() && !!activeCart && ` (${activeCart.name})`}
          </Navbar.Brand>
          <div>
            <Dropdown as={ButtonGroup} id="cart-actions" alignRight>
              <Dropdown.Toggle>
                <i className="fas fa-cog" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {hasCartLoaded() && (
                  <Dropdown.Item id="new-cart-btn" title="New Cart" onClick={handleNewCart}>
                    <i className="fa-regular fa-cart-plus" />
                    New Cart
                  </Dropdown.Item>
                )}
                <Dropdown.Item id="load-cart-btn" title="Load Cart" onClick={handleLoadCart}>
                  <i className="fas fa-box-open" />
                  Load Cart
                </Dropdown.Item>
                {!cartEmpty() && (
                  <>
                    <Dropdown.Item
                      id="save-cart-btn"
                      title="Save Cart"
                      disabled={!cart.length}
                      onClick={handleSaveCart}
                    >
                      <i className="fas fa-save" /> Save Cart
                    </Dropdown.Item>
                    <Dropdown.Item
                      id="clear-cart-btn"
                      title="Clear Cart"
                      onClick={handleClearCart}
                      className="text-danger"
                    >
                      <i className="fas fa-trash-alt" /> Clear Cart
                    </Dropdown.Item>
                  </>
                )}
                <Dropdown.Divider />
                <Dropdown.Item title="Upload CSV" onClick={handleUpload} id="upload-csv-btn">
                  <i className="fas fa-cloud-upload-alt" /> Upload CSV
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Navbar>
        {renderPartItems()}
      </div>
    </div>
  );
};

export default PartCart;

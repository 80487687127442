import BlazeForm from 'blaze-form';

interface Props {
  onSubmit: (data: Record<string, any>) => void;
  onCancel: () => void;
}

const CartSaveForm = ({ onSubmit, onCancel }: Props) => (
  <>
    <p>Give your cart a name.</p>
    <BlazeForm
      layout={[{ label: 'Cart Name', name: 'name', required: true }]}
      onSubmit={onSubmit}
      onCancel={onCancel}
      submitAll
    />
  </>
);

export default CartSaveForm;

import React from 'react';
import PartCart from './PartCart';

import '../../styles/parts.scss';

const PartOrder = () => (
  <div id="parts">
    <PartCart />
  </div>
);

export default PartOrder;

import { useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import Form from './Form';
import Api from '../services/ApiService';
import { toast } from 'react-toastify';
import { companyAtom } from '../stores/root.store';
import PageNavbar from './PageNavbar';
import { useAtom } from 'jotai';
import BlazeForm from 'blaze-form';

const sortByLabel = (a: { label: string }, b: { label: string }) =>
  a.label > b.label ? 1 : a.label < b.label ? -1 : 0;

const getLayout = (branches: any[]) => [
  { name: 'email', label: 'Email Address', type: 'email', required: true },
  { name: 'num', label: 'Customer Number', type: 'text', required: true },
  {
    control: 'select',
    label: 'Branch',
    name: 'BranchId',
    options: branches.map((branch) => {
      return { label: branch.name, value: branch.id };
    }),
    required: true,
    type: 'integer',
  },
  {
    label: 'Alternate Branches',
    name: 'altBranches',
    control: 'ReactSelect',
    props: {
      allowSelectAll: true,
    },
    options: branches
      .map((c) => {
        return { label: c.name, value: c.num };
      })
      .sort(sortByLabel),
    inputProps: {
      isMulti: true,
    },
    type: 'integer',
    data: [],
  },
];

const InviteCustomer = () => {
  const form = useRef<BlazeForm>();
  const [company] = useAtom(companyAtom);

  const handleSubmit = (data: any) => {
    Api.post('customers/invite', data)
      .then(() => {
        if (form && form.current) {
          /* @ts-ignore */
          form.current.updateForm({ email: '', num: '' });
          form.current.clearField('email');
          form.current.clearField('num');
          toast(`Email invitation sent to ${data.email}`);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.error);
        } else {
          toast.error(err.message);
        }
      });
  };

  return (
    <>
      <PageNavbar header="Invite Customer" />
      <div className="invite-customer pad-top pad-sides">
        <Row>
          <Col md={6} lg={5}>
            <div className="form-wrap shadow">
              <Form ref={form} layout={getLayout(company.branches)} onSubmit={handleSubmit} hideCancel submitAll />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default InviteCustomer;

import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Api from '../../services/ApiService';
import { usePartsStore } from '../parts/part.store';
import ReactSelect from 'react-select';
import companyAtom from '../../stores/company.store';
import QuickOrderFormLineItem from './QuickOrderFormLineItem';
import { OrderFormData } from './order.types';
import classNames from 'classnames';
import { useAtom } from 'jotai';

interface QuickOrderFormProps {
  lineItemCount?: number;
}

const QuickOrderForm = ({ lineItemCount = 4 }: QuickOrderFormProps) => {
  const { getUnitPrice } = usePartsStore();
  const [company, setCompany] = useAtom(companyAtom);

  useEffect(() => {
    setCompany({ hideMainSearch: true });

    return () => {
      setCompany({ hideMainSearch: false });
    };
  }, []);

  const getBlankLineItem = () => {
    return {
      quantity: '',
      partNum: '',
      image: '',
    };
  };

  const initData = () => {
    return {
      branchId: '',
      customerNum: '',
      comments: '',
      poNum: '',
      email: '',
      lineItems: [...Array(lineItemCount)].map(getBlankLineItem),
    };
  };

  const [formData, setFormData] = useState<OrderFormData>(initData());
  const [errors, setErrors] = useState<{ [x: string]: any }>({});

  const validate = () => {
    // HACKY validation
    const newErrors = {} as any;

    if (errors.customerNum) {
      newErrors.customerNum = errors.customerNum;
    }

    if (!formData.customerNum) {
      newErrors.customerNum = 'Customer Num is required';
    }

    if (!formData.branchId) {
      newErrors.branchId = 'Please select a branch';
    }

    const lineItems = formData.lineItems?.filter((x) => x.exists);

    if (!lineItems || !lineItems.length) {
      newErrors.lineItems = 'Please enter at least one part to order';
    } else {
      for (const item of formData.lineItems || []) {
        if (item.exists === false) {
          newErrors.lineItems = 'At least one part not valid';
          break;
        }

        if (!(item.quantity || Number(item.quantity) < 0) && item.exists) {
          newErrors.lineItems = 'Invalid quantity value(s)';
          break;
        }
      }
    }

    setErrors({ errors: newErrors });
    return newErrors;
  };

  const handleSubmit = () => {
    const currentErrors = validate();

    if (Object.keys(currentErrors).length) {
      // Toast all errs for now
      for (const value of Object.values(currentErrors)) {
        toast.error(value as string);
      }
      return;
    }

    const body = {
      accountNum: formData.customerNum,
      branchId: formData.branchId,
      parts: (formData.lineItems || [])
        .map((item) => {
          if (!item.partData) {
            return;
          }
          const part = item.partData;
          return {
            quan: item.quantity,
            unitPrice: getUnitPrice(part, Number(item.quantity)),
            ...part,
          };
        })
        .filter((x) => x),
      comment: formData.comments,
      ponumber: formData.poNum,
      additionalEmails: formData.email ? [formData.email] : [],
    };

    Api.post('parts/order', body)
      .then(() => {
        toast.success('Order submitted!');
        setFormData(initData());
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  let branchName = '';
  company.branches.forEach((branch) => {
    if (String(branch.id) === formData.branchId) {
      branchName = branch.name;
    }
  });

  const handleCustomerBlur = () => {
    // check if customer num exists
    Api.get('customers/status', { params: { customerNum: formData.customerNum } }).then((response) => {
      setErrors({ ...errors, customerNum: _.get(response.data, 'status') === 'active' ? '' : 'Invalid customer num' });
    });
  };

  const handleCustomerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, customerNum: e.target.value });
    setErrors({ ...errors, customerNum: undefined });
  };

  const customerNumClasses = classNames({
    'text-danger': !!errors.customerNum,
    'text-success': errors.customerNum !== undefined,
  });

  const getBranchSelect = () => {
    return (
      <ReactSelect
        placeholder="Select Branch"
        onChange={(newValue) => setFormData({ ...formData, branchId: newValue?.value })}
        value={{ value: formData.branchId || '', label: branchName }}
        options={[
          ...company.branches.map((branch) => {
            return {
              label: (
                <div>
                  <div style={{ fontWeight: 'bold' }}>{branch.name}</div>
                </div>
              ) as unknown as string, // ReactSelect only accepts string for label, which is BS because this works
              value: String(branch.id),
            };
          }),
        ]}
      />
    );
  };

  return (
    <Container>
      <Row className="mt-1">
        <Col xs={6}>
          <Form.Group>
            <Form.Control
              className={customerNumClasses}
              placeholder="Customer Number"
              value={formData.customerNum}
              onChange={handleCustomerChange}
              onBlur={handleCustomerBlur}
              required
            />
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group>
            <Form.Control
              placeholder="Customer PO #"
              value={formData.poNum}
              onChange={(e) => setFormData({ ...formData, poNum: e.target.value })}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>{getBranchSelect()}</Form.Group>
        </Col>
      </Row>
      {(formData.lineItems || []).map((item, i) => {
        return (
          <QuickOrderFormLineItem
            key={i}
            canRemove={i !== 0}
            {...item}
            customerNum={formData.customerNum}
            branchId={formData.branchId}
            onChange={(data) => {
              const newLineItems = [...(formData.lineItems || [])];
              newLineItems[i] = { ...newLineItems[i], ...data };
              setFormData({ ...formData, lineItems: newLineItems });
            }}
            onRemove={() => {
              const newLineItems = [...(formData.lineItems || [])];
              newLineItems.splice(i, 1);
              setFormData({ ...formData, lineItems: newLineItems });
            }}
          />
        );
      })}
      <Row>
        <Col style={{ textAlign: 'center' }}>
          <Button
            className="m-2"
            variant="info"
            onClick={() => {
              const newLineItems = [...(formData.lineItems || [])];
              newLineItems.push(getBlankLineItem());
              setFormData({ ...formData, lineItems: newLineItems });
            }}
          >
            <i className="fa-solid fa-plus" /> Add Row
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Comments / Notes</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={formData.comments}
              onChange={(e) => setFormData({ ...formData, comments: e.target.value })}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Send Copy To</Form.Label>
            <Form.Control
              placeholder="Email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <Button variant="success" style={{ width: '100% ' }} onClick={handleSubmit}>
            Submit Order
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default QuickOrderForm;

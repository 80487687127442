import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import BlazeForm from 'blaze-form';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import LoginPanel from './LoginPanel';
import { AxiosError } from 'axios';
import Api from '../../services/ApiService';
import { LayoutItem } from 'blaze-validator';

const layout: LayoutItem[] = [{ label: 'Email', name: 'email', type: 'email', required: true }];

const ForgotPassword = () => {
  const navigate = useNavigate();
  const handleSubmit = (formData: object) => {
    Api.post('forgot-password', formData)
      .then(() => {
        navigate('/login');
        toast('Check your email for instructions to reset your password');
      })
      .catch((err: AxiosError) => {
        const errorMsg = _.get(err, 'response.data.error');
        toast(errorMsg || 'Forgot Password Failed');
      });
  };

  const handleCancel = () => {
    navigate('/login');
  };

  return (
    <LoginPanel>
      <h3>Reset your password</h3>
      <BlazeForm layout={layout} onSubmit={handleSubmit} hideCancel hideSubmit submitAll>
        <div className="login-controls">
          <Button variant="primary" type="submit" block id="login-btn" data-blaze-submit>
            Send Reset Link
          </Button>
          <Button variant="default" block onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </BlazeForm>
    </LoginPanel>
  );
};

export default ForgotPassword;

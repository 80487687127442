import _ from 'lodash';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import Api from '../../services/ApiService';
import PageNavbar from '../PageNavbar';
import LoadingPage from '../LoadingPage';
import companyAtom from '../../stores/company.store';
import { useCustomerPermissions } from '../customers/CustomerHooks';
import WorldNetPayContainer from '../payment/WorldNetPayContainer';
import ErrorPage from '../ErrorPage';
import { currency } from '../../util';
import AccountBalanceList from './AccountBalanceList';
import { modal } from '../modal/ModalEmitter';
import userAtom from '../../stores/user.store';
import { Invoice } from '../payment/payment.types';

const AccountBalance = () => {
  const customerHasPermission = useCustomerPermissions();
  const [company] = useAtom(companyAtom);
  const queryClient = useQueryClient();
  const [user] = useAtom(userAtom);
  const { customer } = user;
  const canAcceptPayment = company.canAcceptPayments() && customerHasPermission('payment.account');

  const { isLoading, error, data } = useQuery({
    queryKey: ['accountBalance'],
    queryFn: async () => {
      let invoices: Invoice[] = (await Api.get('customers/outstanding')).data;

      // removed the credits by request, adding them back
      // invoices = invoices.filter((x) => Number(x.amount) > 0);

      let balance = 0;
      if (invoices.length) {
        balance = _.round(
          invoices.map((x) => Number(x.amount)).reduce((total: number, curr: number) => total + curr),
          2
        );
      }

      return {
        balance,
        invoices,
      };
    },
  });

  const { balance = 0, invoices = [] } = data || {};

  if (isLoading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage />;
  }

  if (!customer) {
    return <ErrorPage>Customer not found</ErrorPage>;
  }

  const handlePay = async ({
    totalAmount,
    totalAmountStr,
    invoices,
  }: {
    totalAmount: number;
    totalAmountStr: string;
    invoices: Invoice[];
  }) => {
    Api.get('/payment/session/new').then((response) => {
      const sessionId = response.data.id;

      modal.open({
        title: 'Payment',
        body: (
          <div style={{ height: '600px' }}>
            <WorldNetPayContainer
              accountId={company.config.payment.accountId}
              amount={totalAmount}
              clientNum={company.num}
              customerNum={customer.num}
              email={user.email}
              invoices={invoices}
              sessionId={sessionId}
              onReceipt={() => {
                queryClient.invalidateQueries({ queryKey: ['accountBalance'] });
              }}
            />
          </div>
        ),
        modalProps: {
          size: 'lg',
        },
        hideFooter: true,
        confirm: true,
      });
    });
  };

  const getHeader = () => (
    <>
      <span>
        {customer.name} ({customer.num})
      </span>
      <div style={{ textAlign: 'right', float: 'right' }}>
        Account Balance: <b>{currency(balance)}</b>
      </div>
    </>
  );

  const renderBody = () => {
    if (!isLoading && !invoices.length) {
      return (
        <ErrorPage>
          <div className="text-success">
            <i className="far fa-thumbs-up" /> No Balance
          </div>
        </ErrorPage>
      );
    }

    return (
      <AccountBalanceList
        invoices={invoices}
        canAcceptPayment={canAcceptPayment}
        currencyCode={company.locale.currencyCode}
        onPay={handlePay}
      />
    );
  };

  return (
    <>
      <PageNavbar header={getHeader()} />
      {renderBody()}
    </>
  );
};

export default AccountBalance;

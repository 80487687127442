import _ from 'lodash';
import { User } from '../stores/user.store';
import { Company } from '../stores/company.store';

interface Page {
  label: string;
  path: string;
  icon: string;
  name: string;
}

const partOrderPermission = 'part.order';

export const orderPages = ['orders', 'cart'];

export const pages: {
  [name: string]: Page;
} = {
  dashboard: { label: 'Dashboard', path: '/', icon: 'fas fa-tachometer-alt', name: 'dashboard' },
  orders: { label: 'Orders', path: '/db/order', icon: 'fas fa-list', name: 'orders' },
  contact: { label: 'Contact', path: '/contact', icon: 'fas fa-phone', name: 'contact' },
  branches: { label: 'Branches', path: '/db/branch', icon: 'fas fa-building', name: 'branches' },
  companies: { label: 'Companies', path: '/db/company', icon: 'fas fa-building', name: 'companies' },
  customers: { label: 'Customers', path: '/db/customer', icon: 'fas fa-users', name: 'customers' },
  dealers: { label: 'Dealers', path: '/db/dealer', icon: 'fas fa-users', name: 'dealers' },
  users: { label: 'Users', path: '/db/user', icon: 'fas fa-users', name: 'users' },
  inviteDealer: { label: 'Invite Dealer', path: '/invite/dealer', icon: 'fas fa-envelope', name: 'inviteDealer' },
  inviteCustomer: {
    label: 'Invite Customer',
    path: '/invite/customer',
    icon: 'fas fa-envelope',
    name: 'inviteCustomer',
  },
  addCustomer: { label: 'Add Customer', path: '/db/customer/_new', icon: 'fas fa-user-plus', name: 'addCustomer' },
  companyInfo: { label: 'Company Info', path: '/company', icon: 'fas fa-building', name: 'companyInfo' },
  branchInfo: { label: 'Branch Info', path: '/db/branch', icon: 'fas fa-building', name: 'branchInfo' },
  accountBalance: {
    label: 'Account Balance',
    path: '/account-balance',
    icon: 'fas fa-file-invoice-dollar',
    name: 'accountBalance',
  },
  history: { label: 'History', path: '/history', icon: 'fas fa-history', name: 'history' },
  cart: { label: 'Cart', path: '/cart', icon: 'fas fa-shopping-cart', name: 'cart' },
  vehicles: { label: 'Vehicles', path: '/vehicles', icon: 'fas fa-shipping-fast', name: 'vehicles' },
  parts: { label: 'Part Search', path: '/parts', icon: 'fas fa-search', name: 'partSearch' },
  promotions: { label: 'Promotions', path: '/promotions', icon: 'fas fa-tags', name: 'promotions' },
  backOrders: { label: 'Back Orders', path: '/back-orders', icon: 'fas fa-shipping-fast', name: 'backOrders' },
  shippingTiers: {
    label: 'Shipping Tiers',
    path: '/db/shippingtier',
    icon: 'fas fa-shipping-fast',
    name: 'shippingTiers',
  },
  paymentIntents: {
    label: 'Payment Intents',
    path: '/db/paymentIntent',
    icon: 'fas fa-money-bill',
    name: 'paymentIntents',
  },
  quickOrder: {
    label: 'Quick Order',
    path: '/order-lite',
    icon: 'fas fa-store',
    name: 'quickOrder',
  },
  statements: {
    label: 'Statements',
    path: '/statements',
    icon: 'fas fa-file-invoice-dollar',
    name: 'statements',
  },
};

export const customerPages: Page[] = [
  pages.dashboard,
  pages.accountBalance,
  pages.statements,
  pages.history,
  pages.backOrders,
  pages.parts,
  pages.cart,
  pages.vehicles,
  pages.orders,
  pages.promotions,
  pages.contact,
];

export const customerTiles: Page[] = [
  pages.accountBalance,
  pages.history,
  pages.statements,
  pages.backOrders,
  pages.parts,
  pages.cart,
  pages.vehicles,
  pages.orders,
  pages.promotions,
  pages.contact,
];

export const dealerPages: Page[] = [
  pages.dashboard,
  pages.companyInfo,
  pages.branchInfo,
  pages.customers,
  pages.inviteCustomer,
  pages.inviteDealer,
  pages.addCustomer,
  pages.parts,
  pages.orders,
  pages.contact,
  pages.quickOrder,
];

export const dealerTiles: Page[] = [
  pages.companyInfo,
  pages.branchInfo,
  pages.customers,
  pages.dealers,
  pages.inviteCustomer,
  pages.inviteDealer,
  pages.addCustomer,
  pages.parts,
  pages.orders,
  pages.contact,
  pages.quickOrder,
];

export const adminPages: Page[] = [
  pages.dashboard,
  pages.inviteDealer,
  pages.orders,
  pages.contact,
  pages.users,
  pages.dealers,
  pages.customers,
  pages.companies,
  pages.branches,
  pages.parts,
  pages.quickOrder,
];

export const adminTiles: Page[] = [
  pages.inviteDealer,
  pages.orders,
  pages.contact,
  pages.users,
  pages.dealers,
  pages.customers,
  pages.companies,
  pages.branches,
  pages.parts,
  pages.quickOrder,
];

const getFilteredPages = (user: User, company: Company) => {
  let filterPages: string[] = [];
  if (
    !_.get(company.permissions.customer, partOrderPermission) ||
    !_.get(user.customer?.permissions, partOrderPermission)
  ) {
    filterPages = filterPages.concat(orderPages);
  }

  if (!company.permissions.customer.backOrders) {
    filterPages.push('backOrders');
  }

  if (!company.permissions.customer.promotions) {
    filterPages.push('promotions');
  }

  if (!company.permissions.customer.vehicles) {
    filterPages.push('vehicles');
  }

  if (!company.permissions.customer.part.search) {
    filterPages.push('partSearch');
  }

  return filterPages;
};

export const getUserPages = (user: User, company: Company) => {
  if (user.isAdmin) {
    if (_.get(company.config, 'orders.shippingTiers')) {
      return [...adminPages, pages.shippingTiers];
    }

    return adminPages;
  }

  if (user.isDealer) {
    if (_.get(company.config, 'orders.shippingTiers')) {
      return [...dealerPages, pages.shippingTiers];
    }

    return dealerPages;
  }

  if (user.isCustomer && user.customer) {
    const filterPages = getFilteredPages(user, company);
    if (filterPages.length) {
      return customerPages.filter((page) => !filterPages.includes(page.name));
    }

    return customerPages;
  }

  return [];
};

export const getUserTiles = (user: User, company: Company) => {
  if (user.isAdmin) {
    if (_.get(company.config, 'orders.shippingTiers')) {
      return [...adminTiles, pages.shippingTiers];
    }

    return adminTiles;
  }

  if (user.isDealer) {
    if (_.get(company.config, 'orders.shippingTiers')) {
      return [...dealerTiles, pages.shippingTiers];
    }

    return dealerTiles;
  }

  if (user.isCustomer && user.customer) {
    const filterPages = getFilteredPages(user, company);
    if (filterPages.length) {
      return customerTiles.filter((tile) => !filterPages.includes(tile.name));
    }
    return customerTiles;
  }

  return [];
};

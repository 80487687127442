import { useState } from 'react';
import BarcodeScanner from '@dsisolutions/react-barcode-scanner';
import '@dsisolutions/react-barcode-scanner/dist/index.css';
import { Portal } from 'react-portal';
import DetectRTC from 'detectrtc';

interface Props {
  onScan: (value: string) => void;
}

const BarcodeButton = ({ onScan = () => null }: Props) => {
  const [barcodeScannerActive, setBarcodeScannerActive] = useState(false);

  // assume mobile device has camera, bug in hasWebcam for iOS/safari
  if (!DetectRTC || (!DetectRTC.hasWebcam && !DetectRTC.isMobileDevice)) {
    return null;
  }

  const renderBarcodeScanner = () => {
    if (!barcodeScannerActive) {
      return null;
    }

    const onSubmit = (value: string) => {
      onScan(value);
      setBarcodeScannerActive(false);
    };

    return (
      <Portal>
        <BarcodeScanner
          fullscreen
          useScanWindow
          decoder={['upc', 'code_128', 'code_39_vin', 'code_39', 'upc_e']}
          onCancel={() => setBarcodeScannerActive(false)}
          onSubmit={onSubmit}
          consecutiveMatchCount={2}
          errorThreshold={0.75}
          scansPerSecond={15}
        />
      </Portal>
    );
  };

  return (
    <>
      <button onClick={() => setBarcodeScannerActive(true)} type="button" className="barcode-btn" title="Scan Barcode">
        <i className="fad fa-barcode-read" />
      </button>
      {renderBarcodeScanner()}
    </>
  );
};

export default BarcodeButton;

import ApiService from '../services/ApiService';
import { Customer } from '../stores/user.store';

interface Props {
  customers: Customer[];
  active: number; // active customer id
}

const ChangeCustomer = ({ customers, active }: Props) => {
  const switchCustomer = (customerId: number) => {
    ApiService.put(`customers/${customerId}/active`).then(() => {
      window.location.reload();
    });
  };

  const renderCustomers = () => {
    const items = [];

    for (const customer of customers) {
      items.push(
        <div
          key={customer.id}
          data-customer-id={customer.id}
          onClick={() => switchCustomer(customer.id)}
          className={`customer ${customer.id === active && 'active'}`}
        >
          {customer.name} ({customer.num})
          {customer.id === active && (
            <div className="current">
              <i className="fas fa-check" />
            </div>
          )}
        </div>
      );
    }

    return items;
  };

  return <div className="change-customer">{renderCustomers()}</div>;
};

export default ChangeCustomer;

import { useState } from 'react';
import { configAtom } from '../../stores/root.store';
import Recaptcha from 'react-recaptcha';
import { useAtom } from 'jotai';

export const useRecaptcha = () => {
  const [config] = useAtom(configAtom);
  const [captcha, setCaptcha] = useState('');

  const onVerify = (response: string) => {
    setCaptcha(response);
  };

  const Component = (
    <Recaptcha sitekey={config.recaptcha.publicKey} onloadCallback={() => setCaptcha('')} verifyCallback={onVerify} />
  );

  return [captcha, Component];
};

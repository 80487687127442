import { Button } from 'react-bootstrap';
import { Invoice } from './payment.types';

interface Props {
  onConfirm: () => void;
  invoices: Invoice[];
  totalAmount: string | number;
}

const PaymentConfirmation = ({ onConfirm, invoices, totalAmount }: Props) => {
  return (
    <div style={{ flexGrow: 1 }} className="mt-5">
      <h4 style={{ textAlign: 'center' }}>Payment Confirmation</h4>
      <table className="table table-bordered table-striped table-sm" style={{ maxWidth: '500px', margin: 'auto' }}>
        <thead style={{ backgroundColor: '#333', color: '#fff' }}>
          <tr>
            <th>Invoice</th>
            <th className="text-right">Amount</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice, i) => (
            <tr key={i}>
              <td>{invoice.invoice}</td>
              <td className="text-right">
                {Number(invoice.amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
              </td>
            </tr>
          ))}
          <tr className="text-bold" key="total">
            <td>Total Amount</td>
            <td className="text-right">
              {Number(totalAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="text-center" style={{ fontSize: '10px' }}>
        Additional Fees May Apply
      </div>
      <div className="mt-3 text-center">
        <Button variant="success" type="button" size="lg" onClick={onConfirm}>
          Start Payment &nbsp;
          <i className="far fa-arrow-right" />
        </Button>
      </div>
    </div>
  );
};

export default PaymentConfirmation;
